import { ErrorMessage, Field, Form, Formik, FormikProps, FormikValues } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getFormFieldValidationStyles } from "../../../helper/formikHelper";
import { ResetPasswordRequestDTO } from "../../../models/shared/ResetPasswordRequestDTO";
import { resetPassword } from "../../../redux-store/api/UserApi";

interface Props {
    token: string;
}

function NewPassword({ token }: Props) {
    const { t } = useTranslation();
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const validationSchema = yup.object().shape({
        newPassword: yup
            .string()
            .min(8, t("FORMS.VALIDATIONS.MIN_PASSWORD_LENGTH"))
            .matches(/(?=.*[0-9])/, { message: t("FORMS.VALIDATIONS.NO_VALID_PASSWORD"), excludeEmptyString: true })
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        confirmNewPassword: yup
            .string()
            .oneOf([yup.ref("newPassword"), ""], t("FORMS.VALIDATIONS.PASSWORDS_MUST_MATCH"))
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
    });

    function submitForm(values: FormikValues) {
        const newPasswordRequest: ResetPasswordRequestDTO = { newPassword: values.newPassword, token };
        resetPassword(newPasswordRequest).then((res) => {
            setPasswordSuccess(true);
        }, () => {
            setPasswordError(true)
        });
    }

    return (
        <div>
            <div className="password-reset-wrapper">
                <div className="password-reset">
                    {passwordSuccess && <div>{t("PASSWORD_RESET.PASSWORD_RESET_SUCCESS")}</div>}
                    {passwordError && <div>{t("PASSWORD_RESET.PASSWORD_RESET_ERROR")}</div>}
                    {!passwordSuccess && !passwordError && (
                        <>
                            <h2 className="text-dark">
                                <i>{t("PASSWORD_RESET.TITLE")}</i>
                            </h2>
                            <Formik
                                initialValues={{
                                    newPassword: "",
                                    confirmNewPassword: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={submitForm}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 my-2">
                                                    <Field
                                                        autoComplete="off"
                                                        className={getFormFieldValidationStyles(
                                                            props.errors,
                                                            props.touched,
                                                            "newPassword"
                                                        )}
                                                        type="password"
                                                        name="newPassword"
                                                        placeholder={t("PASSWORD_RESET.NEW_PASSWORD")}
                                                    />
                                                    <ErrorMessage
                                                        name="newPassword"
                                                        render={(msg) => <div className="invalid-feedback">{msg}</div>}
                                                    />
                                                </div>
                                                <div className="col-12 my-2">
                                                    <Field
                                                        autoComplete="off"
                                                        className={getFormFieldValidationStyles(
                                                            props.errors,
                                                            props.touched,
                                                            "confirmNewPassword"
                                                        )}
                                                        type="password"
                                                        placeholder={t("PASSWORD_RESET.NEW_PASSWORD_CONFIRM")}
                                                        name="confirmNewPassword"
                                                    />
                                                    <ErrorMessage
                                                        name="confirmNewPassword"
                                                        render={(msg) => <div className="invalid-feedback">{msg}</div>}
                                                    />
                                                </div>
                                                <div className="col-12 my-3">
                                                    <button type="submit" className="btn btn-primary w-100">
                                                        {t("PASSWORD_RESET.SUBMIT")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NewPassword;
