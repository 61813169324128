import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';

const ShoppingCartEmpty = () => {
    const [t] = useTranslation();

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h3>
                    {t("CART.EMPTY.MAIN")}
                    <br/>
                    <small>
                        {t("CART.EMPTY.SUB")}
                    </small>
                </h3>
                <div className="m-3">
                    <Link className="btn btn-primary" to={"/"}>
                        {t("CART.EMPTY.TO_SEARCH")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartEmpty;
