import React from "react";
import { FilterInterface } from "../../models/shared/FilterInterface";
import { InputChanged } from "./Input";
import { InputCheckbox } from "./InputCheckbox";

export function InputCheckboxGroup({ filter, onChange }: { filter: FilterInterface; onChange: InputChanged }) {
    return (
        <div className="checkbox-group">
            {filter.values.map((v) => (
                <InputCheckbox name={filter.key} key={filter.key+'_'+v} option={v} value={filter.selectedValues} onChange={onChange} />
            ))}
        </div>
    );
}
