import React from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import tpmsIcon from "../../assets/icons/svg/tpms-icon.svg";
import tpmsClose from "../../assets/icons/svg/tpms-close.svg";
import tpmsBlack from "../../assets/tpms/tpms-black.png"
import tpmsSilver from "../../assets/tpms/tpms-silver.png"
import tpmsTitan from "../../assets/tpms/tpms-titan.png"
import {TpmsItem} from "../../models/car/TpmsCheckDTO";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    close: Function;
    handleSelection: Function;
    items: TpmsItem[];
    classes?: string;
}

function TpmsModal(props: Props) {
    const {t} = useTranslation();
    const noTpmsId = "NO_TPMS";

    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    const handleClose = () => {
        props.close();
    };

    let tpmsTypes = props.items;
    //NO TPMS
    if (tpmsTypes && !tpmsTypes.some(x => x.description == "TPMS.DO_NOT_ADD_TPMS_OPTION")) {
        tpmsTypes.push(
            {
                description: "TPMS.DO_NOT_ADD_TPMS_OPTION",
                label: "TPMS.DO_NOT_ADD_TPMS_OPTION",
                price: 0,
                productId: -1,
                productEan: "-1",
                productExternalId: "-1",
                itemType: noTpmsId,
                imageLink: tpmsClose,
                manufacturer: ""
            }
        );
    }

    tpmsTypes.map(tpms => {
        switch (tpms.label.toLowerCase()){
            case "titan":
                tpms.imageLink = tpmsTitan;
                tpms.flipImage = true;
                break;

            case "schwarz":
                tpms.imageLink = tpmsBlack;
                tpms.flipImage = true;
                break;

            case "silber":
                tpms.imageLink = tpmsSilver;
                tpms.flipImage = true;
                break;
        }
    })

    function onClickHandler(id: number, tpmsItem: TpmsItem) {
        props.handleSelection(tpmsItem.itemType !== noTpmsId, tpmsItem);
        props.close();
    }

    function getTpmsTypes() {

        if(!tpmsTypes){
            console.error("No data from server retrieved");
            return <></>
        }

        if (isSmallScreen) {
            return (<>
                {tpmsTypes.map(type => {
                    return <div
                        className={`tpms-modal__card-mobile col-5 m-1 p-3 text-center mobile-bg`}
                        key={"tpms-option-" + type.productId} onClick={() => onClickHandler(type.productId, type)}>
                        <div
                            className={"row align-middle align-items-center align-content-center justify-content-center h-100 p-0"}>
                            <div className={"col-2 m-3 d-flex justify-content-center"}>
                                <img className={`tpms-modal__icon-mobile ${type.flipImage ? 'tpms-modal__flip-image' : ''}`} src={type.imageLink ?? tpmsIcon}/>
                            </div>
                            <div className={"col"}>
                                <div className={"row"}>
                                    <span
                                        className={`col-lg font-weight-bold`}>{t(type.label)}</span>
                                    <span
                                        className={`col-lg align-middle`}>
                                        <span>+</span>
                                        <NumericFormat value={type.price} displayType="text" decimalSeparator=","
                                                       decimalScale={2} fixedDecimalScale={true} suffix="€"/>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                })}
            </>);
        }

        return (
            <>
                {tpmsTypes.map(type => {
                    return <div
                        className={`tpms-modal__card col-2 mx-3 my-3 py-3 text-center`}
                        key={"tpms-option-" + type.productId} onClick={() => onClickHandler(type.productId, type)}>
                        <div
                            className={"d-flex justify-content-around align-items-between align-content-between flex-column h-100"}>
                            <h6>{t(type.description)}</h6>
                            <div className={`d-flex justify-content-center`}>
                                <img className={`my-2 tpms-modal__icon ${type.flipImage ? 'tpms-modal__flip-image' : ''}`} src={type.imageLink ?? tpmsIcon}/>
                            </div>
                            <h4><NumericFormat value={type.price} displayType="text" decimalSeparator=","
                                               decimalScale={2} fixedDecimalScale={true} suffix="€"/></h4>
                        </div>
                    </div>
                })}
            </>
        )
    }


    return (
        <div className="custom-modal tpms-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="custom-modal-title">{t("TPMS.ALMOST_DONE")}</h5>
                    <button type="button" className="btn-close" onClick={handleClose} aria-label="Close">
                    </button>
                </div>
                <div className="modal-body">
                    <h4>{t("TPMS.QUESTION_TPMS")}</h4>
                    <div className="mb-3">{t("TPMS.TPMS_INFO")}</div>
                    <div
                        className={`row ${!isSmallScreen ? "justify-content-center" : "justify-content-between p-3"} `}>
                        {getTpmsTypes()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpmsModal;
