import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import CartPosition from "../../../../components/CartPosition/CartPosition";
import {CartDTO, CartPositionDTO} from "../../../../models/cart/CartDTO";
import {NumericFormat} from "react-number-format";
import {useMediaQuery} from "../../../../helper/useMediaQuery";
import CartPositionMobile from "../../../../components/CartPositionMobile/CartPositionMobile";
import {getRimTyreAssignment} from "../../../../redux-store/api/RimApi";
import {useNavigate} from "react-router-dom";
import {ShoppingCartContext} from "../../ShoppingCart";

const ShoppingCartPositions = (props: {
    shoppingCart: CartDTO;
    removeButtonHandler: (position: CartPositionDTO) => void;
    updateCart: (cart: CartDTO) => void;
}) => {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const navigate = useNavigate();
    const {cartChanged} = useContext(ShoppingCartContext);

    function getAdditionalTyreForRim(rimId: string, certificateId: number, certificateBlockId: number, carId: string, quantity: number, positionId: number, boltCircle: string) {
        getRimTyreAssignment({
            carId: carId,
            rimFrontId: rimId,
            rimRearId: rimId,
            certificateId: certificateId,
            certificateBlockId: certificateBlockId
        }).then(assignments => {
            const search = new URLSearchParams({
                certificateId: certificateId.toString(),
                certificateBlockId: certificateBlockId.toString(),
                selectedAmount: quantity.toString(),
                positionId: positionId.toString()
            });
            if (assignments.length > 0) {
                const tyre = assignments[0];
                navigate({
                    pathname: `/reifen/${tyre.front.matchcode}/${tyre.front.matchcode}/${encodeURIComponent(carId) + '_' + encodeURIComponent(boltCircle)}/${
                        rimId}/${rimId}`,
                    search: search.toString()
                });

            }
        }).catch((err) => {
            console.error(err);
        });
    }

    return <div>
        {!isSmallScreen && <>
            <div className="heading">{t("CART.TITLE")} <span
                className="fw-normal">({props.shoppingCart?.positions?.length} {t("CART.POSITION_TYPE.ARTICLE")})</span>
            </div>
            {props.shoppingCart?.positions.map((position, index, arr) => (
                <div
                    className={`py-3 ${(arr.length - 1 === index) && !props.shoppingCart.voucherCode ? "" : "position-row-border"}`}
                    key={position.id}>
                    <div className="row align-items-center">
                        <div className="col-2 col-lg-2 col-xl-1">
                            <div className="position-number">
                                {index + 1}
                            </div>
                        </div>
                        <div className="col-10 col-lg-10 col-xl-11 position-heading">
                            <strong>{t(`CART.POSITION_TYPE.${position.positionType}`)} </strong>
                            {position.carDescription && (
                                <>
                                    {t("CART.POSITION_FOR_CAR")}{" "}
                                    {position.carDescription}
                                </>
                            )}
                        </div>
                    </div>
                    <CartPosition position={position}
                                  updateCart={(cart) => props.updateCart(cart)}
                                  cartChanged={() => cartChanged()}
                                  showInputs={true}
                                  removeButtonHandler={props.removeButtonHandler}
                                  getAdditionalTyreForRimInCart={getAdditionalTyreForRim}
                    />
                </div>
            ))}
            {props.shoppingCart?.voucherCode && !props.shoppingCart?.voucherWarning &&
                <div className="py-3">
                    <div className="row m-0 p-2 align-items-center">
                        <div className="col-1">
                        </div>
                        <div className="col-11 position-heading">
                            <strong>{t('CART.ITEM_TYPE.VOUCHER')}</strong>
                        </div>
                    </div>
                    <div className="px-4">
                        <div className="row py-2">
                            <div className="col-3"></div>
                            <div className="col-7">
                                {t('CART.ITEM_TYPE.VOUCHER')} {`"${props.shoppingCart.voucherCode}"`}
                            </div>
                    <div className="col-2 text-end fw-bold">
                                <NumericFormat
                                    value={props.shoppingCart.discount}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix="€"
                                    prefix="- "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
        }
        {isSmallScreen &&
            <div className="cart-mobile mt-4">
                {props.shoppingCart?.positions.map((position, index, arr) => (
                    <div key={position.id}>
                        <div className="panel-mobile">
                            <div className="heading">
                                <div className="title">
                                    <strong>{t("CART.POS")} {index + 1}</strong> {position.carDescription &&
                                    <span><strong>:</strong> {position.carDescription}</span>}
                                </div>
                            </div>
                            <div className="p-3">
                                <CartPositionMobile position={position}
                                                    updateCart={(cart) => props.updateCart(cart)}
                                                    cartChanged={() => cartChanged()}
                                                    showInputs={true}
                                                    removeButtonHandler={props.removeButtonHandler}
                                                    getAdditionalTyreForRimInCart={getAdditionalTyreForRim}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        }
    </div>
};

export default ShoppingCartPositions;
