import { NavigateFunction } from "react-router-dom";
import type {Location} from "react-router-dom";
import { RimSearchState } from "../redux-store/reducers/RimSearchReducer";
import {isSummerSeason} from "./Helper";

export class UrlSearchParamsHelper {
    static pushSearchToHistory(location: Location, navigate: NavigateFunction, searchParams: any) {
        if (location && navigate) {
            const historySearchParams = new URLSearchParams(location.search);
            for (const [key, value] of Object.entries(searchParams)) {
                if (value && value != "") {
                    historySearchParams.set(key, value.toString());
                } else {
                    historySearchParams.delete(key);
                }
            }
            navigate({ search: historySearchParams.toString() });
        }
    }

    static getPageFromLocation(location: Location){
        return Number(new URLSearchParams(location.search).get("page")) ?? 0;
    }

    static getRimSearchState(defaultSearchState: RimSearchState, location: Location) {
        const historySearchParams = new URLSearchParams(location.search);
        historySearchParams.forEach((searchParamsValues, searchParamsKey) => {
            const searchParam = Object.getOwnPropertyDescriptor(defaultSearchState, searchParamsKey);
            if (searchParam) {
                defaultSearchState[searchParamsKey] = searchParamsValues;
            }
        });

        if (historySearchParams.has("page")) {
            defaultSearchState.selectedPage = +historySearchParams.get("page");
            defaultSearchState.offset = defaultSearchState.limit * defaultSearchState.selectedPage;
        }
        return defaultSearchState;
    }

    static getTyreSearchState(location: Location, pathParams) {
        let search: any;
        let isMixedTyreSearch = false;
        let isSearchWithoutCar = false;
        let preSelectedSeasons = isSummerSeason() ? ['ALL_SEASON', 'SUMMER'] : ['ALL_SEASON', 'WINTER'];
        if (!pathParams.matchcodeFront && !pathParams.matchcodeRear) {
            search = {
                width: "",
                height: "",
                diameter: "",
                seasons: preSelectedSeasons
            };
            isSearchWithoutCar = true;
        } else if (pathParams.matchcodeFront === pathParams.matchcodeRear) {
            search = {
                matchcodeTerm: pathParams.matchcodeFront,
                seasons: preSelectedSeasons
            };
        } else {
            search = {
                frontMatchcode: pathParams.matchcodeFront,
                rearMatchcode: pathParams.matchcodeRear,
                seasons: preSelectedSeasons
            };
            isMixedTyreSearch = true;
        }

        const pagination = {
            limit: 20,
            offset: 0,
            selectedPage: 0
        };
        let loadedSearchFromUrl = false;
        const searchParams = new URLSearchParams(location.search);
        searchParams.forEach((searchParamsValues, searchParamsKey) => {
            if (searchParamsValues !== "") {
                const searchParam = Object.getOwnPropertyDescriptor(search, searchParamsKey);
                if (searchParam) {
                    let selectedValues;
                    if (typeof search[searchParamsKey] === "object") {
                        selectedValues = searchParamsValues.split(",");
                    } else {
                        selectedValues = searchParamsValues;
                    }
                    search[searchParamsKey] = selectedValues;
                    loadedSearchFromUrl = true;
                }
            }
        });

        if (searchParams.has("page")) {
            pagination.selectedPage = +searchParams.get("page");
            pagination.offset = pagination.limit * pagination.selectedPage;
        }

        if (isSearchWithoutCar && !loadedSearchFromUrl) {
            //Set default search params here, to avoid having defaults mixed with user selection
            search = {
                width: "205",
                height: "55",
                diameter: "16",
                seasons: preSelectedSeasons
            };
        }

        return {
            search: search,
            isMixedTyreSearch,
            ...pagination
        };
    }
}
