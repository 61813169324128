import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

function trackPageView(path: string) {
    if (!window.gtag) return;

    window.gtag("event", "page_view", {
        page_path: path
    });
}

export const useTracking = () => {
    const location = useLocation()


    useEffect(() => {
        trackPageView(location.pathname)
    }, [location])
}
