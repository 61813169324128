import { useLocation } from "react-router-dom";
import * as sanitizeHtml from 'sanitize-html';
import * as showdown from 'showdown';

export function Markdown({content}:{content: string}) {
    const converter = new showdown.Converter({ noHeaderId: false, ghCompatibleHeaderId: true });
    const location = useLocation()

    if (content) {
        let unsanitizedHtml = converter.makeHtml(content);
        let sanitizedHtml = sanitizeHtml(unsanitizedHtml, {
            allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                '*': ['id'],
            },
            allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
            transformTags: {
                img: (tagName, attribs) => {
                    let src = attribs['src'];
                    if(src) {
                        const srcUrl = new URL(src);
                        src = '/cms'+srcUrl.pathname;
                    }
                    return {
                        tagName,
                        attribs: {
                            ...attribs,
                            src: src ? src : '',
                        },
                    };
                },
                a: (tagName, attribs) => {
                    let href = attribs['href'] || '';
                    if (href.startsWith('#')) {
                        href = location.pathname + href;
                    } else if (!(href.startsWith('/') || href.startsWith('http') || href.startsWith('mailto:'))) {
                        href = location.pathname + '/' + href;
                    }
                    return {
                        tagName,
                        attribs: {
                            ...attribs,
                            href,
                        },
                    };
                },
            },
        });
        return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
    }
    return <></>
}
