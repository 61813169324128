import {Field, Form, Formik, FormikProps} from "formik";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import UserContext from "../../../../context/UserContext";
import {UserContextType} from "../../../../context/UserContextType";
import {updateCartPreview} from "../../../../redux-store/actions/cartPreviewAction";
import {login} from "../../../../redux-store/api";
import {GoogleSignIn} from "../../../../components/GoogleSignIn";
import {AmazonSignIn} from "../../../../components/AmazonSignIn";
import {AppleSignIn} from "../../../../components/AppleSignIn";
import {UserDTO} from "../../../../models/shared/UserDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";

interface Props {
    updateCustomerData: Function;
    updateCartPreview: () => void;
}

function LoginCustomer(props: Props) {
    const [t] = useTranslation();
    const {loginUser} = useContext(UserContext) as UserContextType;

    const loginCustomer = (values, actions) => {
        login(values.email, values.password)
            .then((resp) => {
                actions.setSubmitting(false);
                console.log(resp);
                loginUser(resp);
                props.updateCustomerData(resp);
            })
            .catch((error) => {
                actions.setSubmitting(false);
                console.error("!", error);
            })
            .finally(() => {
                props.updateCartPreview();
            });
    };

    function updateLoggedIn(user: UserDTO) {
        loginUser(user);
        props.updateCartPreview();
    }

    return (
        <>
            <div className="heading">
                {t("CHECKOUT.CUSTOMER_DATA.EXISTING_USER_ACCOUNT")}
            </div>
            <div className="checkout-form mb-3">
                <Formik
                    initialValues={{
                        email: "",
                        password: ""
                    }}
                    onSubmit={loginCustomer}
                >
                    {(props: FormikProps<any>) => (
                        <Form>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group pb-2">
                                        <label className="form-label-small-bold"
                                               htmlFor="email">{t("FORMS.CUSTOMER_DATA.MAIL")} *</label>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder={t("FORMS.CUSTOMER_DATA.MAIL")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group pb-2">
                                        <label className="form-label-small-bold"
                                               htmlFor="password">{t("FORMS.CUSTOMER_DATA.PASSWORD")} *</label>
                                        <Field
                                            className="form-control"
                                            name="password"
                                            placeholder={t("FORMS.CUSTOMER_DATA.PASSWORD")}
                                            type="password"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <button type="submit" className="btn btn-green w-100">
                                        {t("CHECKOUT.CUSTOMER_DATA.LOGIN")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="checkout-form ">
                <div className="row">
                    <div className="col-12 my-1">
                        <GoogleSignIn updateLoggedIn={user => updateLoggedIn(user)}/>
                    </div>
                    <div className="col-12 my-1">
                        <AmazonSignIn updateLoggedIn={user => updateLoggedIn(user)}/>
                    </div>
                    <div className="col-12 my-1">
                        <AppleSignIn updateLoggedIn={user => updateLoggedIn(user)}/>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = {
    updateCartPreview,
};

export default connect(null, mapDispatchToProps)(LoginCustomer);
