import React from "react";

interface Props {
    title: string;
    text1: string;
    text2?: string;
    error: boolean;
}
export default function ConfirmationPage({title, text1, text2, error}: Props) {
    return (
        <div className="confirmation-page-wrapper">
            <div className="container my-3">
                <div className="confirmation-page">
                    <div className="confirmation-page-box">
                        {!error &&
                        <>
                            <h2 className="mb-3">
                                <i className="fas fa-check me-3"/>{title}
                            </h2>
                            <div className="mb-3">{text1}</div>
                            <div>{text2}</div>
                        </>
                        }
                        {error &&
                        <>
                            <h2 className="mb-3">
                                {title}
                            </h2>
                            <div className="mb-3">{text1}</div>
                            <div>{text2}</div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
