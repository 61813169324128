import { SortName } from "./Enums";
import { SortDirection } from "./SortDirection";

export interface SearchSort {
    direction?: SortDirection;
    name?: SortName;
}

export const initialSort: SearchSort = {
    direction: SortDirection.ASC,
    name: SortName.RECOMMENDED,
};
