import { AnyAction } from "redux";
import { CartPreviewDTO } from "../../models/cart/CartDTO";

export const cartPreviewReducer = (state:CartPreviewDTO = {itemCount: null, sumGross: null}, action: AnyAction) => {
    switch (action.type) {
        case "PREVIEW_UPDATE":
            return { ...action.payload };
        default:
            return state;
    }
};
