import React, {useContext} from "react";
import {connect, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {updateCartPreview} from "../../../redux-store/actions/cartPreviewAction";
import {storeShoppingCart} from "../../../redux-store/actions/shoppingcart";
import {CartDTO, CartPositionDTO} from "../../../models/cart/CartDTO";
import ShoppingCartPositions from "./ShoppingCartPositions/ShoppingCartPositions";
import {deletePositionFromCart} from "../../../redux-store/api/ShoppingCartApi";
import RedeemVoucher from "./RedeemVoucher/RedeemVoucher";
import AcceptedPaymentMethods from "./AcceptedPaymentMethods/AcceptedPaymentMethods";
import {useTranslation} from "react-i18next";
import CartPrice from "./CartPrice/CartPrice";
import {Link} from "react-router-dom";
import {PaypalIntegration} from "../../../components/PayPal/PaypalIntegration";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {selectorAllowedCookies} from "../../../redux-store/selectors/cookieSelectors";
import CookieChangeHint from "../../../components/layout/CookieConsent/CookieChangeHint/CookieChangeHint";
import {ShoppingCartContext} from "../ShoppingCart";

interface ShoppingCartFilledProps {
    cart: CartDTO;
    storeShoppingCart: Dispatch<any>;
    updateCartPreview: Function;
}

function ShoppingCartFilled(props: ShoppingCartFilledProps) {
    const [t] = useTranslation();
    const canOrder = !props.cart?.positions?.map(p => p.items.map(i => !!i.errorMessage).includes(true)).includes(true);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const allowedCookies = useSelector(selectorAllowedCookies);
    const {isLoading, cartChanged} = useContext(ShoppingCartContext);

    function removeButtonHandler(position: CartPositionDTO) {
        deletePositionFromCart(position).then((cart) => {
            updateCartHandler(cart);
        });
    }

    function updateCartHandler(cart?: CartDTO) {
        cartChanged(cart);
        props.updateCartPreview();
    }

    function getDesktopCart() {
        return <div className="row">
            <div className="col-12 col-lg-8">
                <div className="panel">
                    <ShoppingCartPositions
                        shoppingCart={props.cart}
                        removeButtonHandler={(position) => removeButtonHandler(position)}
                        updateCart={(cart) => updateCartHandler(cart)}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="panel">
                    <RedeemVoucher updateCart={(cart) => updateCartHandler(cart)}
                                   cart={props.cart}/>
                </div>
                <div className="panel">
                    <CartPrice sumNet={props.cart.sumNet}
                               sumGross={props.cart.sumGross}
                               vat={props.cart.vat}
                               discount={props.cart.discount > 0 ? props.cart.discount : null}
                               shipping={props.cart.shipping > 0 ? props.cart.shipping : null}
                    />
                    <div>

                        <Link className={canOrder && !isLoading ? '' : 'disabled-link'} to="/checkout">
                            <button className="btn w-100 btn-green mb-3 mt-4" disabled={!canOrder || isLoading}>
                                {t("CART.PROCEED_TO_CHECKOUT")}
                            </button>
                        </Link>
                        {(!canOrder) && (<p className="text-danger mb-3">{t("CART.CAN_NOT_ORDER")}</p>)}
                        <div className="text-center font-italic mb-3">{t('CART.ALTERNATIVE_PAY_PAL')}</div>
                        <PaypalIntegration canSubmit={canOrder && !isLoading} cart={props.cart}/>
                        {/*{!allowedCookies.includes("PAYPAL")*/}
                        {/*    ? <CookieChangeHint cookieName={"PayPal"}></CookieChangeHint>*/}
                        {/*    : <PaypalIntegration canSubmit={canOrder} cart={props.cart}/>*/}
                        {/*}*/}
                    </div>
                </div>
                <div className="panel">
                    <AcceptedPaymentMethods/>
                </div>
            </div>
        </div>
    }


    function getMobileCart() {
        return <div className="row">
            <div className="col-12">
                <Link className={'btn w-100 btn-grey mb-2 mt-2 ' + (canOrder && !isLoading ? '' : 'disabled-link')}
                      to="/">
                    {t("CART.PROCEED_SHOPPING")}
                </Link>
            </div>
            <div className="col-12">
                <Link className={'btn w-100 btn-green mb-2 mt-2 ' + (canOrder && !isLoading ? '' : 'disabled-link')}
                      to="/checkout">
                    {t("CART.PROCEED_TO_CHECKOUT")}
                </Link>
                {(!canOrder) && (<p className="text-danger mb-3">{t("CART.CAN_NOT_ORDER")}</p>)}
            </div>
            <div className="col-12">
                <PaypalIntegration canSubmit={canOrder && !isLoading} cart={props.cart}/>
            </div>
            <div className="col-12 mt-3">
                <RedeemVoucher updateCart={(cart) => updateCartHandler(cart)}
                               cart={props.cart} isCartView={true}/>
            </div>
            <div className="col-12">
                <ShoppingCartPositions
                    shoppingCart={props.cart}
                    removeButtonHandler={(position) => removeButtonHandler(position)}
                    updateCart={(cart) => updateCartHandler(cart)}
                />
            </div>
            <div className="col-12 mb-4">
                <CartPrice sumNet={props.cart.sumNet}
                           sumGross={props.cart.sumGross}
                           vat={props.cart.vat}
                           discount={props.cart.discount > 0 ? props.cart.discount : null}
                           shipping={props.cart.shipping > 0 ? props.cart.shipping : null}
                           isCartView={true}
                />
                <div>
                    <Link className={canOrder && !isLoading ? '' : 'disabled-link'} to="/checkout">
                        <button className="btn w-100 btn-green mb-3 mt-4" disabled={!canOrder || isLoading}>
                            {t("CART.PROCEED_TO_CHECKOUT")}
                        </button>
                    </Link>
                    {(!canOrder) && (<p className="text-danger mb-3">{t("CART.CAN_NOT_ORDER")}</p>)}
                    <div className="text-center font-italic mb-3">{t('CART.ALTERNATIVE_PAY_PAL')}</div>
                    <PaypalIntegration canSubmit={canOrder && !isLoading} cart={props.cart}/>
                    {/*{!allowedCookies.includes("PAYPAL")*/}
                    {/*    ? <CookieChangeHint cookieName={"PayPal"}></CookieChangeHint>*/}
                    {/*    : <PaypalIntegration canSubmit={canOrder} cart={props.cart}/>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    }

    return (
        <div className="wrapper-sc-filled">
            {!isSmallScreen
                ? getDesktopCart()
                : getMobileCart()
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.shoppingcart
});

const mapDispatchToProps = {
    updateCartPreview,
    storeShoppingCart
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartFilled);
