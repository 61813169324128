import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

interface Props {
    isCompleteWheelSearch: boolean;
    carId?: string;
}

function NoTyresInStock(props: Props) {
    const [t] = useTranslation();
    const location = useLocation();

    return (
        <div className="no-tyres-in-stock my-3">
            {props.isCompleteWheelSearch &&
            <div className="d-flex flex-column align-items-center justify-content-center p-4">
                <div className="mb-3">{t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.NO_TYRES_IN_STOCK')}</div>
                <div className="mb-3">{t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.CALL_FOR_HELP')}</div>
                <div className="phone-number mb-3">
                    {/*TODO: WCS-716 <a href="tel:+498002583354"><i className="fas fa-phone me-3"/>0800 258 33 54</a>*/}
                    <a href="tel:+494264776930"><i className="fas fa-phone me-3"/>04264 77693 0</a>
                </div>
                <div className="mb-2">{t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.FIND_OTHER_RIMS_HERE')}</div>
                <a className="btn btn-primary mt-2"
                   href={`/felgenkonfigurator/${encodeURIComponent(props.carId)}?carColor=${new URLSearchParams(location.search).get("carColor")}`}
                >
                    {t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.FIND_OTHER_RIMS_HERE')}
                </a>
            </div>
            }
            {!props.isCompleteWheelSearch &&
            <div className="d-flex flex-column align-items-center justify-content-center p-4">
                <div className="mb-3">{t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.NO_TYRES_FOUND_FOR_SEARCH')}</div>
                <div className="mb-3">{t('TYRES.SEARCH.NO_STOCK_NOTIFICATION.CALL_FOR_HELP')}</div>
                <div className="phone-number mb-3">
                    <a href="tel:+498002583354"><i className="fas fa-phone me-3"/>0800 258 33 54</a>
                </div>
            </div>
            }
        </div>

    );
}

export default NoTyresInStock;
