import React from "react";
import { Layer } from "../interfaces/rim-config.interface";
import { BmfImage } from "./bmf-image";
import { RimImageLoading, RimImageLoadingProps } from "./rim-image-loading.component";

interface BmfCarAngleState {
    allLayersLoaded: boolean;
}

interface BmfCarAngleProps {
    layers: Array<Layer>;
    angleIndex: number;
    currentImage: number;
    carColor: string;
    scale: number;
    lowering?:number;
    angleLoaded?: (index:number) => void;
    angleLoading?: (index:number) => void;
    smallImage?: boolean;
    rimLoadingProps?: RimImageLoadingProps
}

export class BmfCarAngle extends React.Component<BmfCarAngleProps, BmfCarAngleState> {
    private layerSet = new Set();

    constructor(props) {
        super(props);
        this.state = {
            allLayersLoaded: false
        };
    }
    componentWillUnmount() {
        this.props.angleLoaded(this.props.angleIndex);
    }

    render() {
        const { layers, angleIndex, carColor, currentImage, scale } = this.props;
        let classNames: Array<string> = [];
        if(angleIndex === currentImage){
            classNames.push('rim-image-layer')
            if (!this.state.allLayersLoaded) {
                classNames.push("invisible");
            }
        } else {
            classNames.push('d-none')
        }
        return <>
            {
                !this.state.allLayersLoaded && angleIndex === currentImage
                && <RimImageLoading {...this.props.rimLoadingProps}/>
            }
            <div className={classNames.join(' ')} style={{transform: `scale(${scale})`}}>
            {this.props.layers?.map((layer, layerIndex) => {
                return <BmfImage
                    key={"layer_" + layerIndex + "_image_" + angleIndex}
                    layer={layer}
                    index={angleIndex}
                    image={layer.images[angleIndex]}
                    currentImage={currentImage}
                    carColor={carColor}
                    lowering={this.props.lowering}
                    imageLoaded={(img) => this.updateLayersLoaded(img)}
                    imageLoading={(img) => this.addLayerLoading(img)}
                    defaultLayerPrefix={process.env.REACT_APP_IMAGE_API}
                    rimLayerPrefix={process.env.REACT_APP_IMAGE_API}
                    smallImage={this.props.smallImage}
                />;
            })}
        </div>
            </>;
    }

    updateLayersLoaded(layerImg: string) {
        this.layerSet.delete(layerImg)
        if(this.layerSet.size === 0){
            this.props?.angleLoaded(this.props.angleIndex)
            this.setState({allLayersLoaded: true})
        }
    }
    addLayerLoading(img: string) {
        this.layerSet.add(img);
        if(this.layerSet.size === 1) {
            this.props?.angleLoading(this.props.angleIndex);
        }
    }
}
