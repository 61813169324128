import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortDropdown } from "../../../../components/Input/SortDropdown";
import { RimDetailDTO } from "../../../../models/rims/RimDetailDTO";
import { SearchSort } from "../../../../models/shared/SearchSort";
import { MountedWheelCostsDTO } from "../../../../models/tyres/MountedWheelDetailsDTO";
import { TyreDTO } from "../../../../models/tyres/TyreDTO";
import { WishListDTO } from "../../../../models/wishlist/WishlistDTO";
import NoTyresInStock from "../NoTyresInStock/NoTyresInStock";
import TyreCard from "./TyreCard/TyreCard";
interface TyreResultsProps {
    tyres: TyreDTO[];
    isLoading: boolean;
    isCompleteWheelSearch: boolean;
    carId?: string;
    rimFrontId?: string;
    rimRearId?: string;
    onSortChange: (value: string) => void;
    count: number;
    sort: SearchSort;
    mountedWheelCosts?: MountedWheelCostsDTO;
    rimDetails?: RimDetailDTO;

    showListView?: boolean;
    onViewChange?: Function;
    wishlistEntries: WishListDTO[];
    getWishlistCallback?: Function;
    pageSize: number;
    firstSearch: boolean;
}

function TyreResults(props: TyreResultsProps) {
    const [t] = useTranslation();
    const [showlistView, setListView] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 992) {
            setListView(true);
        }
    }, []);

    return (
        <>
            {props.tyres?.length > 0 && (
                <>
                    <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
                        <div className={"col-lg-6 col-12 my-2"}>
                            <div className={"row justify-content-between"}>
                                <div className={"col-9 d-flex align-items-center"}>
                                    <span className="text-highlight">{props.count}</span>
                                    <span>&nbsp;{t("TYRES.SEARCH.FITTING_TYRES")}</span>
                                </div>
                                <div className={"col-3 me-lg-0 d-flex justify-content-end align-items-end "}>
                                    <button
                                        className={`icon-button ${showlistView ? "active-view" : ""}`}
                                        onClick={() => {
                                            setListView(true);
                                        }}
                                    >
                                        <i
                                            className={"fas fa-list icon-image m-1  align-middle"}
                                            data-tooltip-id="list"
                                        />
                                    </button>
                                    <button
                                        className={`icon-button ${!showlistView ? "active-view" : ""}`}
                                        onClick={() => {
                                            setListView(false);
                                        }}
                                    >
                                        <i
                                            className={"fas fa-th-large icon-image m-1 align-middle"}
                                            data-tooltip-id="grid view"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={"col-lg d-none d-lg-flex justify-content-end"}>
                            <div style={{ width: "250px" }}>
                                <SortDropdown
                                    name="SORT"
                                    placeholder="FILTER.PLACEHOLDER_SORT"
                                    value={props.sort}
                                    onChange={(e) => props.onSortChange(e.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-stretch mt-2">
                        {props.tyres.map((tyre, index) => {
                            let cardWidth = "col-6 col-sm-6 col-md-4 col-xl-3 mb-3 px-lg-2";
                            if (tyre?.rearTyre) {
                                cardWidth = "col-12 col-sm-6 col-md-6 col-lg-10p col-xl-4 mb-3 px-lg-2";
                            }
                            if (showlistView) {
                                cardWidth = "container";
                            }
                            return (
                                <div className={cardWidth} key={"tyre-" + tyre.productId + Math.random()}>
                                    <TyreCard
                                        tyre={tyre}
                                        isCompleteWheelSearch={props.isCompleteWheelSearch}
                                        carId={props.carId ? props.carId : null}
                                        rimFrontId={props.rimFrontId ? props.rimFrontId : null}
                                        rimRearId={props.rimRearId ? props.rimRearId : null}
                                        rimDetails={props.rimDetails}
                                        mountedWheelCosts={props.mountedWheelCosts}
                                        showListView={showlistView}
                                        getWishlistCallback={props.getWishlistCallback}
                                        wishlistEntries={props.wishlistEntries}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
            {props.tyres?.length === 0 && !props.firstSearch && (
                <NoTyresInStock
                    isCompleteWheelSearch={props.isCompleteWheelSearch}
                    carId={props.carId ? props.carId : null}
                />
            )}
        </>
    );
}

export default TyreResults;
