import SeasonIcon from "../../../../../components/seasonIcon/SeasonIcon";
import Image from "../../../../../components/Image/Image";
import React, {useState} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import {SubTyreCardProps} from "./TyreCard";

export default function GridViewTyreCard(props: SubTyreCardProps) {

    const {t} = useTranslation();

    return (
        <div className="tyre-card tyre-card-wrapper grid-view-tyre-card">
            <div className={"row"}>
                <div className="col ">
                    <div className={"d-flex justify-content-start"}>
                        <SeasonIcon season={props.tyre.season} size="32px" margin={"0"} />
                    </div>
                </div>
                <div className={"col fav-icon fav-icon-tyre "}>
                    <div className={"d-flex justify-content-end"}>
                        <i
                            onClick={(evt) => props.changeFavorite(evt)}
                            className={props.isFavorite ? "fas fa-heart" : "far fa-heart"}
                            data-tooltip-id={"favorite-" + props.tyre.productId}
                        />
                        <ReactTooltip id={"favorite-" + props.tyre.productId} place="bottom">
                            {props.isFavorite ? (
                                <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                            ) : (
                                <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                            )}
                        </ReactTooltip>
                    </div>
                </div>
            </div>

            <div className={"d-flex justify-content-center align-items-center"}>
                <Image className="tyre-image" src={props.tyre.imageDTO.medium} fallback={props.noImageUrl}/>
            </div>
            <div className="tyre-results-description">
                <div className="description-title">
                    <h3 className={"mb-0 mt-2"}>
                        {props.tyre.brandName}
                    </h3>
                    <span className={"small"}>
                        {props.tyre.tread}
                    </span>
                </div>


                <div className="description-details">
                    <div className="row">
                        <div className={props.tyre.rearTyre ? "col-6" : "col-12"}>
                            {props.showTyreInfos(props.tyre)}
                        </div>
                        {props.tyre.rearTyre &&
                            <div className="col-6">
                                {props.showTyreInfos(props.tyre.rearTyre, false, true)}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {props.showButton()}
        </div>
    );

}
