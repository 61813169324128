import React, { useContext, useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import UserContext from "../../../context/UserContext";
import { UserContextType } from "../../../context/UserContextType";
import { login } from "../../../redux-store/api";
import {activateAccount} from "../../../redux-store/api/UserApi";

function ActivateAccount() {
    const [t] = useTranslation();
    const {userId, token} = useParams<{ userId: string, token: string }>();
    const [accountActivated, setAccountActivated] = useState(false);
    const [loading, setLoading] = useState(true);
    const {loginUser} = useContext(UserContext) as UserContextType;

    useEffect(() => {
        activateAccount(+userId, token).then((res) => {
            setLoading(false);
            setAccountActivated(true);
            login()
                .then((response) => {
                    loginUser(response);
                })
        }, ()=>{
            setLoading(false);
        });
    }, []);

    return (
        <div className="activate-account-wrapper">
            {loading ?
                <div className="loading"/>
                :
                <div className="success-message">
                {accountActivated ? <>
                    <i className="fas fa-check me-3"/>{t("REGISTER.ACTIVATE_ACCOUNT.SUCCESS_MESSAGE")}
                    </>
                    : <>
                        {t("REGISTER.ACTIVATE_ACCOUNT.ERROR_MESSAGE")}
                    </>
                }
                </div>
            }
        </div>
    );
}

export default ActivateAccount;
