import classNames from "classnames";
import React from "react";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    noMarginBottom?: boolean;
    children: any;
    classes?: string;
}
export function TradeName(props: Props) {
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');

    return (
        <div className={`trade-name-container ${props.noMarginBottom? 'mb-0' : ''} ${props.classes}`}>
            <h1 id="trade-name" className={`${isSmallScreen ? 'mobile' : ''} `}>
                {props.children}
            </h1>
        </div>
    );
}
