import React from "react";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../helper/useMediaQuery";
import {InputChanged} from "./Input";
import {InputHelper} from "./InputHelper";
import {Tooltip as ReactTooltip} from "react-tooltip";

interface Props {
    value: string[];
    onChange: InputChanged;
    name: string;
    option: string;
}

export function InputCheckbox({ option, value, onChange, name }: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)")
    let defaultChecked = !!value && value?.length !== 0;
    if (Array.isArray(value) && value.length > 0) {
        defaultChecked = value.includes(option);
    }
    return (
        <label className="custom-checkbox filter my-1" key={option}>
            <input
                type="checkbox"
                name={name}
                id={option}
                key={option}
                defaultChecked={defaultChecked}
                onChange={(evt) => {
                    if (value?.includes(option)) {
                        onChange(value.filter((v) => v !== option));
                    } else {
                        onChange([option].concat(value || []));
                    }
                }}
            />
            <span className="checkmark" />
            {InputHelper.filterLabel(option, name, t)}
            {InputHelper.additionalInformationAvailable[name] && (
                <>
                    <i className="ms-1 fa fa-info-circle" onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                    }} data-tooltip-id={name+'-'+option} />
                    <ReactTooltip id={name+'-'+option} place={isSmallScreen ? 'bottom' : 'right'}>
                        <span className={"tooltip-wrap"}>{t("FILTER.ADDITIONAL_INFORMATION." + name)}</span>
                    </ReactTooltip>
                </>
            )}
        </label>
    );
}
