import { StrapiCategoryPage } from "../../models/strapi/StrapiCategoryPage";
import { StrapiSearchResult } from "../../models/strapi/StrapiSearchResult";
import * as qs from 'qs';
import { StrapiStaticPage } from "../../models/strapi/StrapiStaticPage";
import axiosInstance from "./AxiosInstance";

export function getStaticPage(key: string) {
    let queryString = qs.stringify({
        filters: {
            Key: {
                $eq: key,
            }
        },
        populate: 'deep',
        pagination: {
            pageSize: 1,
        },
        locale: 'de'
    });
    return axiosInstance.get<StrapiSearchResult<StrapiStaticPage>>(`/cms/static-pages?${queryString}`)
        .then((res) => res.data?.data?.[0]);
}

export function getManufacturerPages(length: number) {
    let queryString = qs.stringify({
        filters: {},
        pagination: {
            pageSize: length
        },
        locale: "de-DE"
    });
    return axiosInstance
        .get<StrapiSearchResult<StrapiCategoryPage>>(`/cms/category-pages?${queryString}`)
        .then((res) => res.data?.data);
}

export function getManufacturerPage(pageKey: string) {
    let queryString = qs.stringify({
        filters: {
            nodeId: {
                $eq: pageKey
            }
        },
        populate: "deep",
        pagination: {
            pageSize: 1
        },
        locale: "de-DE"
    });
    return axiosInstance
        .get<StrapiSearchResult<StrapiCategoryPage>>(`/cms/category-pages?${queryString}`)
        .then((res) => res.data?.data?.[0]);
}
