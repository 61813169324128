import React from "react";
import ReactDom from 'react-dom';

interface CustomModalProps {
    open: boolean,
    backdropClick?: Function,
    children: any;
    dialogClasses?: string;
}

function CustomModal({open, backdropClick, children, dialogClasses}: CustomModalProps) {
    if (!open) {
        return null;
    }
    return ReactDom.createPortal(
        <div className={'modal'}>
        <div className={'modal-backdrop'} onClick={()=> backdropClick ? backdropClick() : ''}/>
            <div className={`modal-dialog ${dialogClasses}`}>
                {children}
            </div>
        </div>,
        document.getElementById("portal")
    );
}

export default CustomModal;
