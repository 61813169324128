import React, { useContext } from "react";
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import HttpContext from "../../../helper/httpContext";

function NotFoundPage() {
    const [t] = useTranslation();
    const httpContext = useContext(HttpContext)
    httpContext.statusCode = 404

    return (
        <div className="error-page text-center">
            <div>
                <Link to="/">
                    <div className="image-pos">
                        <img src="/errorpage.png" className="img-fluid img-size errorimg" alt="error page"/>
                    </div>
                </Link>
            </div>
            <h2>
                <b>{t('PAGE_NOT_FOUND.TITLE')}</b>
                <br/>
                {t('PAGE_NOT_FOUND.BACK_TO_CAR_SELECTION')}
            </h2>
            <div className="button-pos">
                <Link className="btn btn-primary" to="/">
                    {t('PAGE_NOT_FOUND.TO_SEARCH')}
                </Link>
            </div>
        </div>
    );
}

export default NotFoundPage;
