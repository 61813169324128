import React, {useEffect, useState} from "react";
import {ManufacturerDTO} from "../../models/car/ManufacturerDTO";
import {getManufacturers} from "../../redux-store/api/CarApi";
import {useTranslation} from "react-i18next";

interface Props {
    manufacturerSelected: Function;
    isLandingPage: boolean;
}

function ManufacturerSelection(props: Props) {
    const [search, setSearch] = useState();
    const [manufacturers, setManufacturers] = useState<Array<ManufacturerDTO>>([]);
    const [results, setResults] = useState<Array<ManufacturerDTO>>([]);
    const [t] = useTranslation();
    const topManufacturers = ['Audi', 'BMW', 'Mercedes', 'Tesla', 'VW'];

    useEffect(() => {
        fetchManufacturers();
    }, []);

    function fetchManufacturers() {
        getManufacturers().then((res) => {
                setManufacturers(res);
                setResults(res);
            }
        ).catch(error => {
            console.error('fetchManufacturers', error);
        });
    }

    function filter(e) {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = manufacturers.filter((entry) => {
                return entry.name.toLowerCase().includes(keyword.toLowerCase());
            });
            setResults(results);
        } else {
            setResults(manufacturers);
        }
        setSearch(keyword);
    }

    return (
        <>
            <div className="manufacturer-selection row">
                {!props.isLandingPage &&
                    <div className="col-12 mb-3 mt-2">
                        <h1>{t('CAR_SELECTION.BY_MANUAL.CHOOSE_MANUFACTURER')}</h1>
                    </div>
                }
                <div className="col-12">
                    <h3 className="mb-2">{t('CAR_SELECTION.BY_MANUAL.TOP_MANUFACTURERS')}</h3>
                    <div className="top-manufacturers">
                        {topManufacturers.map((entry, index, array) => (
                            <div key={'top-' + entry} onClick={() => props.manufacturerSelected(entry)}
                                 className={`${index !== array.length - 1 ? 'me-2' : ''}`}>
                                {entry}
                            </div>
                        ))
                        }
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <h3 className="mb-2">{t('CAR_SELECTION.BY_MANUAL.ALL_MANUFACTURERS')}</h3>
                </div>
                <div className="filter-list">
                    <input
                        type="search"
                        value={search}
                        onChange={filter}
                        className="form-control mb-3"
                        placeholder={t('CAR_SELECTION.BY_MANUAL.FILTER_BY_MANUFACTURER')}
                    />
                    <ul className="list-group">
                        {results && results.length > 0 ? (
                            results.map((entry) => (
                                <li key={entry.key}
                                    className={`list-group-item`}
                                    onClick={() => props.manufacturerSelected(entry.key)}>
                                    {entry.name}
                                </li>
                            ))
                        ) : (
                            <h1 className="my-3 text-center">{t('CAR_SELECTION.CAR_LIST.NO_RESULTS')}</h1>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ManufacturerSelection;
