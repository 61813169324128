import React, {useEffect, useState} from 'react';
import {getColorPlate} from "../api/configurator.api";
import {IAvailableColors, IColorPlate} from "../interfaces/rim-config.interface";

interface IColorPlateProps {
    availableColors: IAvailableColors[]
    setColor: (color:string) => void
}

function ColorPaletteComponent(props: IColorPlateProps) {
    const {availableColors} = props;
    const [colorPlate, setColorplate] = useState<IColorPlate[]>([])

    const getColorPalette = async () => {
        setColorplate([]);
        availableColors.map(async (color) => {
            const plateColor = await getColorPlate(color.colorName)
            return setColorplate(colorPlate => [...colorPlate, {colorId: color.colorName, baseUrl: plateColor}]);
        })
    }

    useEffect(() => {
        getColorPalette()
    }, [availableColors]);

    return (
        <div style={{zIndex: 1000, position: 'relative'}}>
            {colorPlate.map((color, index) => {
                return <img key={"index.color" + index} src={color.baseUrl} alt=""
                            style={{cursor: "pointer"}}
                            onClick={() => props.setColor(color.colorId)}/>
            })}
        </div>
    );
}

export default ColorPaletteComponent;
