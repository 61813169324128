import { NumericFormat } from "react-number-format";
import {useTranslation} from "react-i18next";
import React from "react";

interface RecommendedRetailPriceProps {
    price: number
    recommendedRetailPrice?: number;
    className?: string;
    hideIfEmpty?: boolean;
}

function RecommendedRetailPrice(props: RecommendedRetailPriceProps) {
    const [t] = useTranslation();

    let className = `recommended-retail-price line-through ${props.className}`;
    if(props.recommendedRetailPrice && props.recommendedRetailPrice > props.price) {
        return <div className={className}>
            <div className="me-1">{t("GLOBAL.RECOMMENDED_RETAIL_PRICE")}</div>
            <NumericFormat value={props.recommendedRetailPrice}
                           displayType="text"
                           decimalSeparator=","
                           decimalScale={2}
                           fixedDecimalScale={true}
                           suffix="€"
            />
        </div>
    } else if(!props.hideIfEmpty) {
        return <div className={"recommended-retail-price"}>&nbsp;</div>
    } else {
        return null;
    }
}

export default RecommendedRetailPrice;
