import React from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {FormGroup, FormLabel} from "react-bootstrap";

interface Props {
    onChange?: (newValue: any) => void;
    options: any[];
    selectedValue?: any;
    name: string;
    label?: string;
    mode: "RIM" | "TYRE";
    disabled?: boolean;
}

function MultilineDropdown(props: Props) {
    const [t] = useTranslation();

    const reactSelectStyles = {
        control: (styles, {isFocused}) => ({
            ...styles,
            boxShadow: "none",
            "&:hover": {
                borderColor: "var(--primary)"
            },
            borderColor: isFocused ? "var(--primary)" : "#CBCBCB",
            height: 50
        }),
        option: (styles, {
            isDisabled, isSelected, isFocused
        }) => ({
            ...styles,
            height: 50,
            cursor: isDisabled ? "not-allowed" : "default",
            color: "var(--dark)",
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "var(--primary)"
                    : isFocused
                        ? "var(--light)"
                        : null
        }),
        valueContainer: (styles) => ({
            ...styles,
            height: 40,
            display: 'flex',
            alignItems: 'center'
        })
    };

    const formatRimOptionLabel = ({front, rear}) => (
        <div>
            {front && rear &&
            <>
                <div>{t("RIMS.DETAILS.VA")}:  {front?.rimSizeDisplay}</div>
                <div>{t("RIMS.DETAILS.HA")}: {rear?.rimSizeDisplay}</div>
            </>
            }
            {!rear &&
            <>
                <div>{t("RIMS.DETAILS.VA_HA")}: {front?.rimSizeDisplay}</div>
            </>

            }
        </div>
    );

    const formatTyreOptionLabel = ({frontMatchcode, rearMatchcode}) => (
        <div>
            {frontMatchcode && rearMatchcode &&
            <>
                <div>{t("RIMS.DETAILS.VA")}: {frontMatchcode}</div>
                <div>{t("RIMS.DETAILS.HA")}: {rearMatchcode}</div>
            </>
            }
        </div>
    );

    return (
        <>
            <FormGroup controlId={props.name}>
                {props.label &&
                <FormLabel>{props.label}</FormLabel>
                }
                <Select name={props.name}
                        options={props.options}
                        value={props.selectedValue}
                        onChange={props.onChange}
                        formatOptionLabel={props.mode === "RIM" ? formatRimOptionLabel : formatTyreOptionLabel}
                        styles={reactSelectStyles}
                        isSearchable={false}
                        isDisabled={props.disabled}
                        isOptionSelected={option => !props.options.includes(option)}
                />
            </FormGroup>
        </>
    );
}

export default MultilineDropdown;
