import React, {JSX, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CarViewBackgrounds from "./CarViewBackgrounds";
import CarViewColors from "./CarViewColors";
import CarViewLowering from "./CarViewLowering";
import {ChangedCarViewOption} from "../CarView";
import {RimDetailDTO} from "../../../models/rims/RimDetailDTO";
import CarViewRimPage from "./CarViewRimPage";
import {RimSizeAssignmentDTO} from "../../../models/rims/RimSizeAssignmentDTO";
import {Tooltip} from "react-tooltip";
import icoMoonShare from "../../../assets/icons/svg/icomoon-share.svg";
import icoMoonRim from "../../../assets/icons/svg/icomoon-spinner.svg";
import CarViewShareButtons from "./CarViewShareButtons";

interface Props {
    carBackgrounds: Array<string>;
    carColors: Array<{ colorId: string, colorPlateImageUrl: string }>;
    loweringMin: number;
    loweringMax: number;
    selectedColorId: string;
    selectedBackground: string;
    selectedLowering: number;
    openCarDetails: Function;
    editCar: Function;
    isSmallScreen?: boolean;
    buttonBarHandler?: (boolean) => void;
    onOptionChangeHandler?: (ChangedCarViewOption, any) => void;
    isExpandedCallback?: (boolean) => void;
    isExpanded?: boolean;
    rimDetails?: RimDetailDTO;
    rimId?: string;
    rimSizes?: any[];
    rimSizeAssignments?: RimSizeAssignmentDTO[];

    selectedRimSize?: RimSizeAssignmentDTO;
    toggleFullScreen?: Function;
    carImages?: any[];
}

export default function CarViewConfigPanel(props: Props) {
    const [t] = useTranslation();
    const [showCarConfigurationId, setShowCarConfigurationId] = useState<string>();

    useEffect(() => {
        if(!props.isExpanded){
            setShowCarConfigurationId(null);
        }

    }, [props.isExpanded]);


    function showCarConfiguration(id) {
        if (showCarConfigurationId != null && showCarConfigurationId != id) {
            if (props.isExpandedCallback) {
                props.isExpandedCallback(true);
            }
            setShowCarConfigurationId(id);
        } else {
            if (props.isExpandedCallback) {
                props.isExpandedCallback(showCarConfigurationId !== id);
            }

            setShowCarConfigurationId(showCarConfigurationId === id ? null : id);
        }
    }

    function onObjectChanged(option: ChangedCarViewOption, obj: any, showPopup: boolean = false) {
        props.onOptionChangeHandler(option, obj);

        if(!showPopup){
            setShowCarConfigurationId(null);
        }


        if (props.isExpandedCallback) {
            props.isExpandedCallback(showPopup);
        }
    }

    async function copyUrlToClipboard() {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(window.location.href);
        } else {
            return document.execCommand('copy', true, window.location.href);
        }
    }

    function getTargetClass(myClass: string, isDisplayClass: boolean ) {
        return isDisplayClass
            ? showCarConfigurationId === myClass ? 'd-block' : 'd-none'
            : showCarConfigurationId === myClass ? 'active' : '';
    }

    function getInnerContent(optionId: string) {
        switch (optionId) {
            case "car-color":
                return <CarViewColors colors={props.carColors}
                                      selectedColorId={props.selectedColorId}
                                      colorChanged={(colorId, showPopup) => onObjectChanged(ChangedCarViewOption.COLOR, colorId, showPopup)}
                                      isSmallScreen={props.isSmallScreen}/>

            case "car-lowering":
                return <CarViewLowering loweringMin={props.loweringMin}
                                        loweringMax={props.loweringMax}
                                        selectedLowering={props.selectedLowering}
                                        loweringChanged={(lowering, showPopup) => onObjectChanged(ChangedCarViewOption.LOWERING, lowering, showPopup)}
                                        isSmallScreen={props.isSmallScreen} />

            case "car-background":
                return <CarViewBackgrounds backgrounds={props.carBackgrounds}
                                           selectedBackground={props.selectedBackground}
                                           backgroundChanged={(bg, showPopup) => onObjectChanged(ChangedCarViewOption.BACKGROUND, bg, showPopup)}
                                           isSmallScreen={props.isSmallScreen}/>

            case "car-rim":
                return <CarViewRimPage rimId={props.rimId} rimDetails={props.rimDetails}
                                       rimSizes={props.rimSizes} rimSizeAssignments={props.rimSizeAssignments}
                                       toggleFullScreenMode={props.toggleFullScreen} carImages={props.carImages}
                                       selectedRimSize={props.selectedRimSize}/>

            case "car-share-buttons":
                return <CarViewShareButtons close={() => showCarConfiguration(null)} isSmallScreen={props.isSmallScreen} />
            default:
                return <></>;
        }
    }

    function getOptionDivider() {
        return (
            <div className={"vertical-divider"}>
                <div>|</div>
            </div>
        );
    }

    const configOptions: {
        id: string,
        faIcon?: string,
        svgPath?: string,
        translationKey: string,
        getFullOption: boolean,
        onClickCallback?: (params?) => void,
        verticalOnly?: boolean
    }[] = [
        {
            id: "car-fullscreen", faIcon: "fa-arrow-left", translationKey: "FULLSCREEN", getFullOption: false,
            onClickCallback: () => props.toggleFullScreen(), verticalOnly: true
        },
        {
            id: "car-rim", svgPath: icoMoonRim, translationKey: "RIM_DETAILS", getFullOption: true,
            onClickCallback: () => showCarConfiguration("car-rim"), verticalOnly: true
        },
        {
            id: "car-color", faIcon: "fa-fill-drip", translationKey: "COLOR", getFullOption: true,
            onClickCallback: () => showCarConfiguration("car-color")
        },
        {
            id: "car-lowering", faIcon: "fa-arrows-alt-v", translationKey: "LOWER", getFullOption: true,
            onClickCallback: () => showCarConfiguration("car-lowering")
        },
        {
            id: "car-background", faIcon: "fa-image", translationKey: "BACKGROUND", getFullOption: true,
            onClickCallback: () => showCarConfiguration("car-background")
        },
        {
            id: "car-car-details-modal", faIcon: "fa-info-circle", translationKey: "CAR_INFO", getFullOption: false,
            onClickCallback: () => props.openCarDetails()
        },
        {
            id: "car-share-buttons", svgPath: icoMoonShare, translationKey: "SHARE", getFullOption: true,
            onClickCallback: () => showCarConfiguration("car-share-buttons")
        },
    ];

    /*${props.isSmallScreen ? 'fullscreen-landscape h-100 mb-0' : ''} */
    function getHorizontalPanel() {
        return (
            <div className={`panel car-view-config-panel `}>
                {configOptions.map((configOption, index) => {

                    if (configOption.verticalOnly) {
                        return <></>
                    }
                    return (
                        <>
                            <div id={configOption.id} key={"car-view-config-option-" + index + "-" + Math.random()}
                                 className={`car-view-config-option ${configOption.getFullOption ? getTargetClass(configOption.id, false) : ''}`}>
                                {configOption.getFullOption
                                    ? <div className={`collapse-wrapper`}>
                                        <div id={`${configOption.id}-collapse`}
                                             className={"collapse " + getTargetClass(configOption.id, true)}>
                                            {getInnerContent(configOption.id)}
                                        </div>
                                    </div>
                                    : <></>
                                }
                                <div className={"d-flex justify-content-center"} onClick={configOption.onClickCallback}>
                                    {configOption.svgPath
                                        ? <img src={configOption.svgPath} className={"me-2"}></img>
                                        : <i className={`fas ${configOption.faIcon} me-2`}/>
                                    }
                                    {!props.isSmallScreen &&
                                        <span>{t('CAR_VIEW.' + configOption.translationKey)}</span>}
                                </div>
                            </div>
                            {index + 1 < configOptions.length && getOptionDivider()}
                        </>
                    );
                })}
            </div>
        );
    }

    function getVerticalPanel() {
        return (
            <>
                <div className={"car-view-config-panel "}>
                    {configOptions.map((configOption, index) => {
                        return <>
                            {configOption.getFullOption
                                ? <div key={"collapse-wrapper-" + index} className={`collapse-wrapper`}>
                                    <div id={`${configOption.id}-collapse`}
                                         className={"collapse h-100 " + getTargetClass(configOption.id, true)}>
                                        {getInnerContent(configOption.id)}
                                    </div>
                                </div>
                                : <></>
                            }
                        </>
                    })
                    }
                </div>
                <div className={`panel car-view-config-panel fullscreen-landscape mb-0`}>
                    {configOptions.map((configOption, index) => {
                        return (
                            <>
                                <div id={configOption.id} key={"car-view-config-option-" + index}
                                     className={`car-view-config-option ${configOption.getFullOption ? getTargetClass(configOption.id, false) : ''}`}>
                                    <div className={"d-flex car-view-config-icon justify-content-center"}
                                         onClick={configOption.onClickCallback}>
                                        {configOption.svgPath
                                            ? <img src={configOption.svgPath} className={"me-2"}></img>
                                            : <i className={`fas ${configOption.faIcon} me-2`}/>
                                        }
                                        {!props.isSmallScreen &&
                                            <span>{t('CAR_VIEW.' + configOption.translationKey)}</span>}
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </>
        );
    }

    return props.isSmallScreen ? getVerticalPanel() : getHorizontalPanel();


}
