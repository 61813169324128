import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { urlParamsToObject } from "../../../helper/Helper";
import { withRouter, WithRouterProps } from "../../../helper/withRouter";
import { OrderResultDTO } from "../../../models/checkout/OrderResultDTO";
import { cancelPayPal } from "../../../redux-store/api/PaymentApi";
import { ChangeOrderPayment } from "./ChangeOrderPayment";

class PayPalCancel extends Component<WithRouterProps<WithTranslation>,
    { loading: boolean; orderResponse?: OrderResultDTO }> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        cancelPayPal(urlParamsToObject(urlParams)).then(
            (orderResponse) => this.setState({ loading: false, orderResponse: orderResponse }),
            () => {
                this.props.navigate("/checkout?paymentCanceled");
                this.setState({ loading: false });
            }
        );
    }

    render() {
        return (
            <>
                <ChangeOrderPayment orderId={this.state.orderResponse?.orderId} />
            </>
        );
    }
}

export default withTranslation()(withRouter(PayPalCancel));
