import {AnalyticsHelper} from "../../analytics/AnalyticsHelper";
import {
    generateMountingKitPosition,
    generateRimPosition,
    generateTpmsPosition,
    generateTyrePosition
} from "../../helper/CartItemHelper";
import {AdditionalCostsDTO, ReducedCartItemDTO} from "../../models/cart/AdditionalCostsDTO";
import {
    AddressDTO,
    CartDTO,
    CartItemDTO,
    CartPositionDTO,
    CartPreviewDTO,
    PaymentMethod
} from "../../models/cart/CartDTO";
import {CustomerDataDTO} from "../../models/cart/CustomerDataDTO";
import {KitInformationDTO} from "../../models/rims/KitInformationDTO";
import {RimInformationDTO} from "../../models/rims/RimDetailDTO";
import {RimSizeDTO} from "../../models/rims/RimSizeDTO";
import {Axle, ItemType} from "../../models/shared/Enums";
import {TyreDTO} from "../../models/tyres/TyreDTO";
import axiosInstance from "./AxiosInstance";
import {TpmsItem} from "../../models/car/TpmsCheckDTO";

export function getShoppingCart() {
    return axiosInstance.get("/api/cart").then((res) => res.data);
}

export function getCartPreview() {
    return axiosInstance.get<CartPreviewDTO>("/api/cart/preview")
        .then(res => res.data);
}

export function updatePositionQuantity(position: CartPositionDTO, quantity: number) {
    if (position.quantity > quantity) {
        AnalyticsHelper.trackRemoveItemsFromCart(position.items, position.quantity - quantity)
    } else {
        AnalyticsHelper.trackAddItemsToCart(position.items, quantity - position.quantity)
    }


    return axiosInstance.put<CartDTO>(`/api/cart/position/${position.id}/quantity/${quantity}`).then((res) => res.data);
}

export function deletePositionFromCart(position: CartPositionDTO) {
    AnalyticsHelper.trackRemoveItemsFromCart(position.items, position.quantity)

    return deletePositionFromCartById(position.id);
}

export function deletePositionFromCartById(positionId: number) {
    return axiosInstance.delete<CartDTO>(`/api/cart/position/${positionId}`)
        .then((res) => res.data);
}

export function clearCart() {
    return axiosInstance.post('/api/cart/clear').then(res => res.data);
}

export function updateCartCustomerData(customerData: CustomerDataDTO) {
    return axiosInstance.put("/api/cart/customerdata", customerData)
        .then(res => res.data);
}

export function changeDeliveryAddress(deliveryAddress: AddressDTO) {
    return axiosInstance.put("/api/cart/delivery", deliveryAddress)
        .then(res => res.data);
}

export function changeInvoiceAddress(invoiceAddress: AddressDTO) {
    return axiosInstance.put("/api/cart/invoice", invoiceAddress)
        .then(res => res.data);
}

export function changePaymentMethod(paymentMethod: PaymentMethod) {
    return axiosInstance.put<CartDTO>(`/api/cart/payment?method=${paymentMethod}`, {})
        .then(res => res.data);
}

export function updateRemark(remark: string) {
    return axiosInstance.put<CartDTO>(`/api/cart/remark?remark=${remark}`, {})
        .then(res => res.data);
}

export function redeemVoucher(code: string) {
    return axiosInstance.put<CartDTO>(`/api/cart/voucher/${code}`, {})
        .then(res => res.data);
}

export function deleteVoucher() {
    return axiosInstance.delete<CartDTO>("/api/cart/voucher")
        .then(res => res.data);
}

export function saveCarBuildDate(itemId: string, carBuildDate: string) {
    return axiosInstance.put<void>(`/api/cart/item/${itemId}/carbuilddate`, {carBuildDate})
        .then(res => res.data);
}

export function updateCarDescription(positionId: number, carDescription: string) {
    return axiosInstance.put<CartDTO>(`/api/cart/position/${positionId}/cardescription`, {carDescription})
        .then(res => res.data);
}

export function addRimToShoppingCart(
    carIdFull: string | null,
    rim: RimInformationDTO,
    rimSize: RimSizeDTO,
    amount: number,
    certificateId: number,
    certificateBlockId: number,
    certificatePdfLink: string,
    axle: Axle = Axle.BOTH,
) {
    const [carId, boltCircle] = carIdFull?.split("_") || []
    const request = {
        axle,
        carId,
        boltCircle,
        items: [
            generateRimPosition(rim, rimSize, certificateId, certificateBlockId)
        ],
        positionType: "RIM",
        quantity: amount,
        certificatePdfLink: certificatePdfLink
    };
    AnalyticsHelper.trackAddItemsToCart(request.items, amount)
    return axiosInstance.post(`/api/cart/position`, request)
        .catch((error) => {
            console.error("ERROR in addRimToShoppingCart, ", error);
            throw error;
        });
}

export function addMixedRimToShoppingCart(positions: Array<{
    carId: string,
    rim: RimInformationDTO,
    rimSize: RimSizeDTO,
    amount: number,
    certificateId: number,
    certificateBlockId: number,
    axle: Axle,
    certificatePdfLink: string,
}>) {
    const requestPositions = positions.map(pos => {
        let items = [generateRimPosition(pos.rim, pos.rimSize, pos.certificateId, pos.certificateBlockId)]
        AnalyticsHelper.trackAddItemsToCart(items, pos.amount)
        const [carId, boltCircle] = pos.carId?.split("_") || []
        return {
            axle: pos.axle,
            carId,
            boltCircle,
            items: items,
            positionType: "RIM",
            quantity: pos.amount,
            certificatePdfLink: pos.certificatePdfLink
        };
    });
    return axiosInstance.post(`/api/cart/position/multi`, requestPositions);
}

export function addTyreToShoppingCart(tyre: TyreDTO, amount: number) {
    const request = {
        items: [generateTyrePosition(tyre)],
        positionType: "TYRE",
        quantity: amount
    };

    AnalyticsHelper.trackAddItemsToCart(request.items, amount);

    return axiosInstance.post(`/api/cart/position`, request);
}

export function addCompleteWheelToShoppingCart(positions: Array<{
    carId: string,
    rim: RimInformationDTO,
    rimSize: RimSizeDTO,
    certificateId: number,
    certificateBlockId: number,
    tyre: TyreDTO,
    amount: number,
    tpmsAdded: boolean,
    tpmsItem?: TpmsItem
    axle: Axle,
    kit: KitInformationDTO,
    mountingCosts: ReducedCartItemDTO
}>) {
    const requestPositions = positions.map(pos => {
        let items: Array<CartItemDTO> = [
            generateRimPosition(pos.rim, pos.rimSize, pos.certificateId, pos.certificateBlockId),
            generateTyrePosition(pos.tyre)
        ];
        if (pos.tpmsAdded) {
            items.push(generateTpmsPosition(pos.tpmsItem));
        }
        if (pos.kit?.id) {
            items.push(generateMountingKitPosition(pos.kit));
        }
        if (pos.mountingCosts) {
            items.push({...pos.mountingCosts, tyreEULabel: undefined});
        }
        AnalyticsHelper.trackAddItemsToCart(items, pos.amount)


        const [carId, boltCircle] = pos.carId?.split("_") || []
        return {
            axle: pos.axle,
            carId,
            boltCircle,
            items: items,
            positionType: "MOUNTED_WHEEL",
            quantity: pos.amount
        };
    });
    return axiosInstance.post(`/api/cart/position/multi`, requestPositions);
}
