import React from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import Image from "../../../../components/Image/Image";
import {getFallbackImage} from "../../../../helper/Helper";
import {OrderPositionDTO} from "../../../../models/order/OrderPositionDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";

interface Props {
    position: OrderPositionDTO;
}

function OrderPosition({position}: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');

    return (
        <div>
            <div className="order-position mb-3">
                {isSmallScreen && <>
                    <div className="row position-row-mobile">
                        <div className="col-2 position-quantity mobile">
                            {position.quantity}x
                        </div>
                        <div className="col-10">
                            {position.items.map((item, index, arr) => (
                                <div className="row py-2 mx-0" key={item.id}>
                                    <div
                                        className={`col-3 px-0 pb-2 ${arr.length - 1 === index ? "" : "item-row-border"}`}>
                                        {item.itemType === "MOUNTINGKIT" ? (
                                            <i className="fa-5x fas fa-wrench"/>
                                        ) : (
                                            <Image
                                                className="img-fluid"
                                                fallback={getFallbackImage(item)}
                                                src={item.imageLink}
                                                alt={item.description}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={`col-9 pb-2 pr-0 ${arr.length - 1 === index ? "" : "item-row-border"}`}>
                                        <div>{t(`CART.ITEM_TYPE.${item.itemType}`)}</div>
                                        <div>{item.description}</div>
                                        {item.itemType === 'TPMS' && item.carBuildDate &&
                                            <div className="mt-2">
                                                <div><b>{t('CART.CAR_BUILD_DATE_PLACEHOLDER')}:</b></div>
                                                <div>{item.carBuildDate}</div>
                                            </div>
                                        }
                                        {/*{(position.axle === "FRONT" ||
                                            position.axle === "REAR") && (
                                            <div>{t("CART.AXLE." + position.axle)}</div>
                                        )}*/}

                                        <div className="d-flex justify-content-between mt-3">
                                            {item.priceGross && item?.priceGross > 0 && <>
                                                <div>{t("CART.PIECE_PRICE")}:</div>
                                                <div className="fw-bold">
                                                    <NumericFormat
                                                        value={item.priceGross}
                                                        displayType="text"
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix="€"
                                                    />
                                                </div>
                                            </>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            {item.priceGross && item?.priceGross > 0 && <>
                                                <div>{t("CART.SUB_TOTAL")}:</div>
                                                <div className=" fw-bold">
                                                    <NumericFormat
                                                        value={item.priceGross * position.quantity}
                                                        displayType="text"
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix="€"
                                                    />
                                                </div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
                {!isSmallScreen && <>
                    <div className="row position-header-row">
                        <div className="col-1"/>
                        <div className="col-11 position-details">
                            <div className="row pt-3 pb-2">
                                <div className="col-2"/>
                                <div className="col-2 fw-bold">
                                    {t("CART.PRODUCT_TYPE")}
                                </div>
                                <div className="col-3 col-lg-4 fw-bold">
                                    {t("CART.PRODUCT")}
                                </div>
                                <div className="col-2 fw-bold text-end">
                                    {t("CART.PIECE_PRICE")}
                                </div>
                                <div className="col-3 col-lg-2 fw-bold text-end">
                                    {t("CART.SUB_TOTAL")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-1 position-quantity">
                            {position.quantity}x
                        </div>
                        <div className="col-11 position-details">
                            {position.items.map((item, index, arr) => (
                                <div className="row position-item-row py-2" key={item.id}>
                                    <div className="col-2">
                                        <Image
                                            className="img-fluid"
                                            fallback={getFallbackImage(item)}
                                            src={item.imageLink}
                                            alt={item.description}
                                        />
                                    </div>
                                    <div className={`col-2 ${arr.length - 1 === index ? ""
                                        : "item-row-border"}`}>
                                        {t(`CART.ITEM_TYPE.${item.itemType}`)}
                                    </div>
                                    <div className={`col-3 col-lg-4 ${arr.length - 1 === index ? ""
                                        : "item-row-border"}`}>
                                        <div>{item.description}</div>
                                        {item.itemType === 'TPMS' && item.carBuildDate &&
                                            <div className="mt-2">
                                                <div><b>{t('CART.CAR_BUILD_DATE_PLACEHOLDER')}:</b></div>
                                                <div>{item.carBuildDate}</div>
                                            </div>
                                        }
                                        {/* {(position.axle === "FRONT" || position.axle === "REAR") &&
                                    <div>{t("CART.AXLE." + position.axle)}</div>
                                    }*/}
                                    </div>

                                    <div className={`col-2 text-end ${arr.length - 1 === index ? ""
                                        : "item-row-border"}`}>
                                        {item.priceGross && item.priceGross > 0 &&
                                            <NumericFormat
                                                value={item.priceGross}
                                                displayType="text"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix="€"
                                            />
                                        }
                                    </div>
                                    <div className={`col-3 col-lg-2 text-end ${arr.length - 1 === index ? ""
                                        : "item-row-border"}`}>
                                        {item.priceGross && item.priceGross > 0  &&
                                        <NumericFormat
                                            value={item.priceGross * position.quantity}
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            suffix="€"
                                        />
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
                <div className="row position-sum pt-3 pb-1">
                    <div className="col-8 text-end">
                        {t("CART.POSITION_SUM")}:
                    </div>
                    <div className="col-4 text-end">
                        <NumericFormat
                            value={position.sumGross}
                            displayType="text"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix="€"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderPosition;
