import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        scrollToTopFn()
    }, []);

    return null;
}

export function scrollToTopFn(targetName = 'trade-name-container') {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    let target = document?.getElementsByClassName(targetName);

    const iOS = ['iPad', 'iPhone', 'iPod', 'MacIntel'].indexOf(navigator.platform) >= 0;

    if (iOS || !target) {
        target = document?.getElementsByClassName('body-wrapper');
        target?.[0]?.scrollTo(0, 0);
    } else {
        target?.[0]?.scrollIntoView({block: 'start', behavior: 'auto', inline: 'start'});
    }
}
