import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TradeName } from "../../components/TradeName/TradeName";
import useFullPageLoader from "../../helper/useFullPageLoader";
import { CartDTO } from "../../models/cart/CartDTO";
import { getShoppingCart } from "../../redux-store/api/ShoppingCartApi";
import { ShoppingCartActionType } from "../../redux-store/reducers/shoppingCartReducer";
import Info from "../Home/Info/Info";
import ShoppingCartWrapper from "./components/ShoppingCartWrapper";

export const ShoppingCartContext = createContext({
    isFirstCheck: false,
    isLoading: false,
    cartChanged: (cart?: CartDTO) => {},
    changeLoadingCounter: (increment: boolean) => {},
});

function ShoppingCart() {
    const [isLoadingCount, setIsLoadingCount] = useState(0);
    const [isFirstCheck, setIsFirstCheck] = useState(true);
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { loader, showLoader, hideLoader } = useFullPageLoader();
    const isLoading = isLoadingCount > 0;

    useEffect(() => {
        fetchShoppingCart();
    }, []);

    useEffect(() => {
        if (isLoading) {
            showLoader();
        } else {
            hideLoader();
        }
    }, [isLoading]);

    function changeLoadingCounter(increment: boolean) {
        setIsLoadingCount((cnt) => Math.max(0, cnt + (increment ? 1 : -1)));
    }

    function storeShoppingCart(res: CartDTO) {
        dispatch({
            type: ShoppingCartActionType.STORE_SHOPPING_CART,
            payload: res,
        });
    }

    function fetchShoppingCart(cart?: CartDTO) {
        if (!cart) {
            changeLoadingCounter(true);

            getShoppingCart()
                .then((res: CartDTO) => {
                    storeShoppingCart(res);
                })
                .finally(() => {
                    changeLoadingCounter(false);
                    setIsFirstCheck(false);
                });
        } else {
            storeShoppingCart(cart);
        }
    }

    return (
        <ShoppingCartContext.Provider
            value={{
                isFirstCheck: isFirstCheck,
                isLoading: isLoading,
                cartChanged: fetchShoppingCart,
                changeLoadingCounter: (increment) => changeLoadingCounter(increment),
            }}
        >
            <TradeName>{t("CART.TITLE")}</TradeName>
            <div className="container-fluid">
                <div className="shopping-cart-wrapper">
                    <Helmet>
                        <title>{t("CART.TITLE")}</title>
                        <meta name="description" content="Warenkorb" />
                    </Helmet>
                    {loader}
                    <ShoppingCartWrapper />
                </div>
            </div>
            <Info />
        </ShoppingCartContext.Provider>
    );
}

export default ShoppingCart;
