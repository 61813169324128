import React from "react";
import { NumericFormat } from "react-number-format";
import {useTranslation} from "react-i18next";

interface Props {
    sumNet: number;
    sumGross: number;
    vat: number;
    shipping: number;
    noMarginBottom?: boolean;
    discount?: number;
}

function CheckoutPrice({sumNet, sumGross, vat, shipping, noMarginBottom, discount}: Props) {
    const [t] = useTranslation();

    return (
        <div className="checkout-price">
            <div className="container">
                <div className={"row price-row mx-0 mt-4 " + (noMarginBottom ? "":"mb-3")}>
                    <div className="col-12 col-md-6 px-4 offset-md-6 col-lg-4 offset-lg-8">
                        <div className="d-flex justify-content-between">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.PRICE_NET")}:</div>
                            <NumericFormat
                                value={sumNet}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.INCLUDED_VAT")}:</div>
                            <NumericFormat
                                value={vat}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                        {shipping !== null && (
                            <div className="d-flex justify-content-between">
                                <div>
                                    {t("CHECKOUT.ORDER_SUMMARY.SHIPPING_COSTS")}:
                                </div>
                                <NumericFormat
                                    value={shipping}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />
                            </div>
                        )}
                        {discount !== null && (
                            <div className="d-flex justify-content-between">
                                <div>
                                    {t("CHECKOUT.ORDER_SUMMARY.DISCOUNT")}:
                                </div>
                                <NumericFormat
                                    value={discount * -1}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />
                            </div>
                        )}
                        <div className="d-flex justify-content-between fw-bold">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.TOTAL_PRICE")}:</div>
                            <NumericFormat
                                value={sumGross}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutPrice;
