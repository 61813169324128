import React from "react";

interface TyreLabelProps {
    rollingResistance: string;
    wetGrip: string;
    noiseEmission: string;
}

function TyreLabel(props: TyreLabelProps) {

    function getLabel(src: string, infos: string, index: number){
        return <div className="col d-flex align-items-center mb-2" key={"list-view-eu-label-" + index + Math.random()}>
            <img src={`/${src}`} className="eu-tyre-label-icon"
                 alt={src}/>
            <span className="text-pos ms-2"> {infos} </span>
        </div>
    }

    const labels: {src: string, infos: string}[]= [
        {src: "eu_tyre_label_rolling_resistance.svg", infos: props.rollingResistance},
        {src: "eu_tyre_label_wet_grip.svg", infos: props.wetGrip},
        {src: "eu_tyre_label_noise_emission.svg", infos: props.noiseEmission + " dB"},
    ];

    return (
        <div className="tyre-label-wrapper row justify-content-between px-1">
            {labels.map((label, index) => getLabel(label.src, label.infos, index))}
        </div>
    );
}

export default TyreLabel;