import { useEffect, useRef } from "react";
import { loadScript } from "../helper/loadScript";
import {loginAppleCode, loginAppleToken, loginGoogle} from "../redux-store/api";
import { SignInProps } from "../types/SignInProps";

export const AppleSignIn = ({ updateLoggedIn }: SignInProps) => {

    useEffect(() => {
        const src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/de_DE/appleid.auth.js";


        loadScript(src)
            .then(() => {
                AppleID.auth.init({
                    scope : 'name email',
                    state : 'initial',
                    usePopup : true,
                    ...window.appleAuth
                });
            })
            .catch(console.error);

        return () => {
            const scriptTag = document.body.querySelector(`script[src="${src}"]`);
            if (scriptTag) document.body.removeChild(scriptTag);
        };
    }, []);

    function appleIDSignInOnSuccess(event:any) {
        if(event?.detail?.authorization?.code) {
            loginAppleCode(event.detail.authorization.code).then(updateLoggedIn)
        } else if(event?.detail?.authorization?.id_token) {
            loginAppleToken(event.detail.authorization.id_token).then(updateLoggedIn)
        }
    }
    useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess',  appleIDSignInOnSuccess);
        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess',  appleIDSignInOnSuccess);
        };
    }, []);

    return <div id="appleid-signin" data-color="black" data-width="100%" data-border="true" data-type="sign in"></div>;
};
