import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {scrollToTopFn} from "../../../components/ScrollToTop";

export default function FAQ() {
   const { t } = useTranslation();
   const [selected, setSelected] = useState<number>(-1);

   useEffect(() => {
      scrollToTopFn();
   }, []);

   const faqContent = [
      "PW_FORGOT", "CART_EMPTY", "NOT_ALL_3D", "REPLY_TO_ORDER", "DELIVERY_FEE", "APPRAISE", "WHAT_COMPLETE_WHEEL",
      "WHICH_DELIVERY_SERVICE", "HAVE_TMPS", "HOW_LONG_DELIVERY"
   ];

   return (
       <div className="static-wrapper faq-wrapper">
          <Helmet>
             <title>{t('FAQ.TITLE')}</title>
             <meta name="description" content={t('FAQ.TITLE')} />
          </Helmet>
          <div className="container">
             <div className="headline-big">{t('FAQ.TITLE')}</div>
             {faqContent.map((faq, index) => (
                 <React.Fragment key={index}>
                    <div
                        className="question-wrapper"
                        onClick={() => setSelected(selected !== index ? index : -1)}
                    >
                       <i className={`fas fa-${selected === index ? "minus" : "plus"}`} />
                       {t(`FAQ.${faq}_Q`)}
                    </div>
                    <div className={`answer ${selected === index ? " active" : ""}`}>
                       <p>
                          {t(`FAQ.${faq}_A`)}
                       </p>
                    </div>
                 </React.Fragment>
             ))}
          </div>
       </div>
   );
}