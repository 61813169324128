import React, {useState, useTransition} from "react";
import {useDispatch} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {CookieConsentReducerActionType} from "../../../../redux-store/reducers/cookieConsentReducer";
import {withCookies} from "react-cookie";
import CustomModal from "../../../CustomModal/CustomModal";
import {defaultCookieList} from "../DefaultCookieList";
import {AnalyticsApi} from "../../../../analytics/AnalyticsApi";


function CookieChangeModal({cookies, showModalHandler, modalOpen}) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const consentCookieName = "wcs-cookie-consent";
    const [currentCookieList, setCookies] = useState(defaultCookieList);


    function handleCheckboxChange(index) {
        const updatedCookies = [...currentCookieList];
        updatedCookies[index].accepted = !updatedCookies[index].accepted;
        setCookies(updatedCookies);
    }

    function declineAllCookies() {
        // currentCookieList.map(cookie => {
        //         return cookie.alwaysAccept
        //             ? cookie
        //             : {...cookie, accepted: false}
        //     }
        // );
        // acceptAllCookies();
        dispatchCookies();
    }

    function dispatchCookies() {
        showModalHandler(false);
        cookies.set(consentCookieName, "true", {path: "/"});
        localStorage.setItem(consentCookieName, "true");
        acceptAllCookies();
        // const allowedCookies = [];
        // currentCookieList.map(cookie => {
        //     if (cookie.accepted) {
        //         allowedCookies.push(cookie.name)
        //     }
        //     localStorage.setItem(cookie.name, cookie.accepted.toString());
        // });
        // AnalyticsApi.updateGAConsent(allowedCookies.includes("GOOGLE_ANALYTICS"));
        // dispatch({type: CookieConsentReducerActionType.SET, payload: {allowedCookies: allowedCookies}});
    }

    function acceptAllCookies() {
        cookies.set(consentCookieName, "true", {path: "/"});
        const allowedCookies: string[] = [];
        defaultCookieList.map(cookie => {
            allowedCookies.push(cookie.name)
            localStorage.setItem(cookie.name, "true");
        });
        dispatch({type: CookieConsentReducerActionType.SET, payload: {allowedCookies: allowedCookies}});
    }

    return <CustomModal open={modalOpen} backdropClick={showModalHandler} dialogClasses={"cookie-modal-content"} >
        <div className={"modal-content"}>
            <table className={"table"}>
                <thead>
                <tr>
                    <td>{t("COOKIE_CONSENT.PROVIDER")}</td>
                    <td>{t("COOKIE_CONSENT.COOKIE_DESCRIPTION")}</td>
                    <td>{t("COOKIE_CONSENT.ACCEPT")}</td>
                </tr>
                </thead>
                <tbody>
                {currentCookieList.map((cookie, index) => {
                    return <tr key={index}>
                        <td>
                            {t("COOKIE_CONSENT.COOKIES." + cookie.name)}
                        </td>
                        <td>
                            {t(cookie.description)}
                        </td>
                        <td>
                            <div className={"d-flex justify-content-center"}>
                                <input className={"form-check-input"} type={"checkbox"}
                                       checked={cookie.accepted}
                                       onChange={() => handleCheckboxChange(index)}
                                       disabled={cookie.alwaysAccept}/>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            <div className={"modal-button-bar"}>
                <button className={"btn btn-outline-secondary me-3"}
                        onClick={() => declineAllCookies()}>{t("COOKIE_CONSENT.DECLINE_ALL")}</button>
                <button className={"btn btn-primary"}
                        onClick={() => dispatchCookies()}>{t("COOKIE_CONSENT.ACCEPT_SELECTION")}</button>
            </div>
        </div>
    </CustomModal>

}

export default withTranslation()(withCookies(CookieChangeModal));