import React from "react";
import {useTranslation} from "react-i18next";

export default function ProductErrorMessage() {
    const [t] = useTranslation();
    return (
        <div className="product-error-message">
            <div className="box">
                <div className="mb-3"><b>{t("PRODUCT_ERROR_MESSAGE.TEXT_1")}</b></div>
                <div>{t("PRODUCT_ERROR_MESSAGE.TEXT_2")}</div>
                <div className="phone-number my-3">
                    {/*TODO: WCS-716 <a href="tel:+498002583354"><i className="fas fa-phone me-3"/>0800 258 33 54</a>*/}
                    <a href="tel:+494264776930"><i className="fas fa-phone me-3"/>04264 77693 0</a>
                </div>
            </div>
        </div>
    )
}
