import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, Form, Formik, FormikProps, FormikValues} from "formik";
import * as yup from "yup";
import {sendPasswordToken} from "../../../redux-store/api/UserApi";
import {getFormFieldValidationStyles} from "../../../helper/formikHelper";

function RequestToken() {
    const {t} = useTranslation();
    const [passwordTokenRequested, setPasswordTokenRequested] = useState(false);

    const validationSchema = yup.object().shape({
        username: yup.string()
            .required(t("FORMS.VALIDATIONS.REQUIRED"))
    });

    function submitForm(values: FormikValues) {
        sendPasswordToken(values.username)
            .then(() => {
                setPasswordTokenRequested(true);
            });
    }
    return (
        <div>
            <div className="password-reset-wrapper">
                <div className="password-reset">
                    {passwordTokenRequested &&
                    <div>{t("PASSWORD_RESET.EMAIL_SENT")}</div>}
                    {!passwordTokenRequested &&
                    <>
                        <h2 className="text-dark"><i>{t("PASSWORD_RESET.TITLE")}</i></h2>
                        <Formik
                            initialValues={{
                                username: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={submitForm}
                        >
                            {(props: FormikProps<any>) => (
                                <Form>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 my-2">
                                                <Field autoComplete="off"
                                                       className={getFormFieldValidationStyles(props.errors, props.touched, "username")}
                                                       type="text"
                                                       name="username"
                                                       placeholder={t("PASSWORD_RESET.MAIL")}
                                                       required
                                                />
                                                <ErrorMessage name="username"
                                                              render={msg =>
                                                                  <div className="invalid-feedback">{msg}</div>}
                                                />
                                            </div>
                                            <div className="col-12 my-3">
                                                <button type="submit"
                                                        className="btn btn-primary w-100"
                                                >
                                                    {t("PASSWORD_RESET.SUBMIT")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

export default RequestToken;
