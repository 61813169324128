import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import UserContext from "../../../context/UserContext";
import {UserContextType} from "../../../context/UserContextType";
import { CountryCodes, countryZipRegex, getCountryList } from "../../../models/checkout/CountryCodes";
import {CountryOptionType} from "../../../models/shared/Types";
import {TradeName} from "../../../components/TradeName/TradeName";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import { getFormFieldValidationStyles, transformEmptyToNull } from "../../../helper/formikHelper";
import * as yup from "yup";
import {ContactRequestDTO} from "../../../models/shared/ContactRequestDTO";
import {Typeahead} from "react-bootstrap-typeahead";
import {sendContactRequest} from "../../../redux-store/api";
import ConfirmationPage from "../../../components/ConfirmationPage/ConfirmationPage";
import {Helmet} from "react-helmet";
import {scrollToTopFn} from "../../../components/ScrollToTop";

function Contact() {
    const [t] = useTranslation();
    const {loggedIn, user} = useContext(UserContext) as UserContextType;
    const [showConfirmationPage, setShowConfirmationPage] = useState(false);
    const [error, setError] = useState(false);
    const countryOptions: CountryOptionType[] = getCountryList(t);
    const validationSchema = () => yup.object().shape({
        firstName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        lastName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        zipCode: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")).when(['country'], (country:any, schema) => {
            return country ? schema.matches(countryZipRegex[country], t("FORMS.VALIDATIONS.ZIP_FORMAT")) : schema;
        }),
        city: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        email: yup.string()
            .email(t("FORMS.VALIDATIONS.MAIL_PATTERN"))
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        subject: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        message: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        customerId: yup.string().nullable().default(null).transform(transformEmptyToNull),
        company: yup.string().nullable().default(null).transform(transformEmptyToNull),
        phone: yup.string().nullable().default(null).transform(transformEmptyToNull),
        street: yup.string().nullable().default(null).transform(transformEmptyToNull),
        country: yup.string().nullable().default(null).transform(transformEmptyToNull),
    });
    const initialValues = {
        customerId: "",
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        zipCode: "",
        city: "",
        country: CountryCodes.DE,
        subject: "",
        message: ""
    };
    const [formValues, setFormValues] = useState<ContactRequestDTO>(initialValues);

    useEffect(() => {
        if (loggedIn && user) {
            setFormValues({
                customerId: user.customerId || "",
                company: "",
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                email: user.email || "",
                phone: "",
                street: user.street || "",
                zipCode: user.zipCode || "",
                city: user.city || "",
                country: user.country || "",
                subject: "",
                message: ""
            })
        }
        scrollToTopFn();

    }, [])

    function sendRequest(values) {
        sendContactRequest(validationSchema().cast(values))
            .then(() => {
                setShowConfirmationPage(true);
                setError(false);
            })
            .catch(err => {
                setShowConfirmationPage(true);
                setError(true);
            });
    }

    return (
        <>
            <Helmet>
                <title>{t("FORMS.CONTACT.HTML_TITLE")}</title>
                <meta name="description" content={t("FORMS.CONTACT.HTML_TITLE")}/>
            </Helmet>
            {showConfirmationPage &&
            <ConfirmationPage
                title={error ? t("FORMS.CONFIRMATION_PAGE.ERROR_TITLE") : t("FORMS.CONTACT.CONFIRMATION_PAGE.TITLE")}
                text1={error ? t("FORMS.CONFIRMATION_PAGE.ERROR_TEXT") : t("FORMS.CONTACT.CONFIRMATION_PAGE.INFO_1")}
                error={error}/>
            }
            {!showConfirmationPage &&
            <>
                <TradeName> {t("FORMS.CONTACT.TITLE")}</TradeName>
                <div className="container contact-form">
                    <Formik
                        initialValues={formValues}
                        onSubmit={sendRequest}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="row mb-2 mt-3">
                                    <div className="contact-header col-12">
                                        {t("FORMS.CONTACT.CONTACT_DATA")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 my-2">
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="customerId"
                                            placeholder={t("FORMS.CUSTOMER_DATA.CUSTOMER_NUMBER")}
                                        />
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="company"
                                            placeholder={t("FORMS.CUSTOMER_DATA.COMPANY")}
                                        />
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "firstName")}
                                            type="text"
                                            name="firstName"
                                            placeholder={t("FORMS.CUSTOMER_DATA.FIRST_NAME") + "*"}
                                        />
                                        <ErrorMessage name="firstName"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "lastName")}
                                            type="text"
                                            name="lastName"
                                            placeholder={t("FORMS.CUSTOMER_DATA.LAST_NAME") + "*"}
                                        />
                                        <ErrorMessage name="lastName"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="street"
                                            placeholder={t("FORMS.CUSTOMER_DATA.STREET")}
                                        />
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "zipCode")}
                                            type="text"
                                            name="zipCode"
                                            placeholder={t("FORMS.CUSTOMER_DATA.ZIP_CODE") + "*"}
                                        />
                                        <ErrorMessage name="zipCode"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "city")}
                                            type="text"
                                            name="city"
                                            placeholder={t("FORMS.CUSTOMER_DATA.CITY") + "*"}
                                        />
                                        <ErrorMessage name="city"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            name="country"
                                        >
                                            {({
                                                  form: {values}
                                              }) => (
                                                <Typeahead options={countryOptions}
                                                           id="country-autocomplete"
                                                           inputProps={{
                                                               autoComplete: "nope",
                                                               name: "country",
                                                               className: "form-control"
                                                           }}
                                                           paginate={false}
                                                           placeholder={t("FORMS.CUSTOMER_DATA.COUNTRY")}
                                                           emptyLabel={t("FORMS.CUSTOMER_DATA.NO_MATCHED_COUNTRIES")}
                                                           onChange={(e: CountryOptionType[]) => {
                                                               props.setFieldValue("country", e[0]?.code);
                                                           }}
                                                           onBlur={props.handleBlur}
                                                           selected={values.country ? [{
                                                               code: values.country,
                                                               label: t("ENUMS.COUNTRY_CODES." + values.country)
                                                           }] : []}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "email")}
                                            type="email"
                                            name="email"
                                            placeholder={t("FORMS.CUSTOMER_DATA.MAIL") + "*"}
                                        />
                                        <ErrorMessage name="email"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="phone"
                                            placeholder={t("FORMS.CUSTOMER_DATA.PHONE")}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2 mt-3">
                                    <div className="contact-header col-12">
                                        {t("FORMS.CONTACT.REQUEST")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "subject")}
                                            type="text"
                                            name="subject"
                                            placeholder={t("FORMS.CONTACT.SUBJECT") + "*"}
                                        />
                                        <ErrorMessage name="subject"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                    <div className="col-12 my-2">
                                        <Field
                                            className={getFormFieldValidationStyles(props.errors, props.touched, "message")}
                                            name="message"
                                            component="textarea"
                                            placeholder={t("FORMS.CONTACT.MESSAGE") + "*"}
                                        />
                                        <ErrorMessage name="message"
                                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-2 mb-3 text-end">
                                        <button type="submit" className="btn btn-primary">
                                            {t("FORMS.CONTACT.SEND_REQUEST")}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
            }
        </>
    );
}

export default Contact;
