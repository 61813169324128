import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    close: Function;
}

export function CompleteProfileModal(props: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 575.98px)');

    const handleClose = () => {
        props.close();
    };

    const goToProfile = () => {
        navigate("/einstellungen/nutzerdaten");
    };

    return (
        <div className="custom-modal complete-profile-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="custom-modal-title d-none d-sm-inline-block">
                        {t("COMPLETE_PROFILE_MODAL.TITLE")}
                    </h5>
                    <button type="button" className="btn-close" onClick={handleClose} aria-label="Close">
                    </button>
                </div>
                <div className="modal-body">
                    <div className="message">{t("COMPLETE_PROFILE_MODAL.MESSAGE")}</div>
                    <div className="message pt-2">{t("COMPLETE_PROFILE_MODAL.IMPORTANT_NOTICE")}</div>
                </div>
                <div className="modal-footer">
                    <button type="button" className={`btn btn-green ${isSmallScreen ? 'w-100' : ''}`} onClick={goToProfile}>
                        {t("COMPLETE_PROFILE_MODAL.COMPLETE")}
                    </button>
                </div>
            </div>
        </div>
    );
}
