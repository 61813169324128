import React from "react";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../helper/useMediaQuery";

function ArrowBannerMountedWheel() {
    const [t] = useTranslation();
    const isLargeScreen = useMediaQuery("(min-width: 1199.98px)");
    const isSmartphone = useMediaQuery("(max-width: 767.98px)");

    return (
        <>
            {isSmartphone &&
                <div className="gradient-arrow-banner-box arrow-banner-mounted-wheel banner-mobile">
                    <div className="text-center">
                        <div className="text">
                            <i>{t('RIMS.DETAILS.BANNER.FEW_STEPS_TO')} {" "}
                                <span className="text-highlight">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEEL')}</span>
                            </i>
                        </div>
                    </div>
                    <div className="text-center-side">
                        <div className="info-box">
                            <div>
                                <img className="img-fluid mb-2" src="/home/icon-komplettraeder.png"
                                     alt="icon-komplettraeder.png"/>
                            </div>
                            <h3 className="no-wrap">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS')}</h3>
                            <b>{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS_SUB_TEXT')}</b>
                        </div>
                    </div>
                </div>
            }
            {!isSmartphone &&
                <div className="gradient-arrow-banner-box arrow-banner-mounted-wheel">
                    <div className="gradient-arrow-banner small-height">
                        <div className="pointer p0"/>
                        <div className="pointer p1"/>
                        <div className="pointer p2"/>
                        <div className="pointer p3"/>
                        <div className="pointer p4"/>
                    </div>
                    {isLargeScreen &&
                        <>
                            <div className="text-overlay">
                                <div className="text-start-side">
                                    <div className="text">
                                        <i>{t('RIMS.DETAILS.BANNER.FEW_STEPS_TO')} {" "}
                                            <span
                                                className="text-highlight">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEEL')}</span>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="text-overlay large-center">
                                <div className="text-center-side d-flex align-items-center">
                                    <div>
                                        <img className="img-fluid" src="/home/icon-komplettraeder.png"
                                             alt="icon-komplettraeder.png"/>
                                    </div>
                                    <div className="info-box">
                                        <div>
                                            <h3 className="no-wrap">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS')}</h3>
                                            <b>{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS_SUB_TEXT')}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {!isLargeScreen &&
                        <div className="text-overlay">
                            <div className="text-start-side">
                                <div className="text">
                                    <i>{t('RIMS.DETAILS.BANNER.FEW_STEPS_TO')} {" "}
                                        <span className="text-highlight">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEEL')}</span></i>
                                </div>
                            </div>
                            <div className="text-center-side d-flex align-items-center">
                                <div>
                                    <img className="img-fluid" src="/home/icon-komplettraeder.png"
                                         alt="icon-komplettraeder.png"/>
                                </div>
                                <div className="info-box">
                                    <h3 className="no-wrap">{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS')}</h3>
                                    <b>{t('RIMS.DETAILS.BANNER.MOUNTED_WHEELS_SUB_TEXT')}</b>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            }
        </>
    );
}

export default ArrowBannerMountedWheel;
