import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CookieChangeModal from "../../../components/layout/CookieConsent/CookieChangeModal/CookieChangeModal";
import { StrapiStaticPageComponent } from "../../../components/Strapi/StrapiStaticPageComponent";

export default function PrivacyPolicy() {
    const [showCookieModal, setShowCookieModal] = useState<boolean>(false);
    const {t} = useTranslation()

    return <StrapiStaticPageComponent strapiKey={"privacy-policy"}><>
        {/*<div className={"d-flex justify-content-end mx-3"}>*/}
            {/*    <button className={"btn btn-primary"}*/}
                {/*            onClick={() => setShowCookieModal(true)}>{t("COOKIE_CONSENT.COOKIE_EDIT")}</button>*/}
                {/*</div>*/}
            {/*<CookieChangeModal modalOpen={showCookieModal} showModalHandler={setShowCookieModal}/>*/}
    </>
    </StrapiStaticPageComponent>;
};
