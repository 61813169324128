import {Axle, ItemType} from '../shared/Enums';
import {TyreEULabelDTO} from "../tyres/TyreDTO";

export interface AddressDTO {
    title: string;
    city: string;
    country: string; //    Enum: [ AD, AE, AF, AG, AI, AL, AM, AN, AO, AQ, AR, AS, AT, AU, AW, AX, AZ, BA, BB, BD, BE, BF, BG, BH, BI, BJ, BL, BM, BN, BO, BQ, BR, BS, BT, BV, BW, BY, BZ, CA, CC, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CS, CU, CV, CW, CX, CY, CZ, DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, FI, FJ, FK, FM, FO, FR, GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY, HK, HM, HN, HR, HT, HU, ID, IE, IL, IM, IN, IO, IQ, IR, IS, IT, JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ, LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, MK, ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ, NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM, PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY, QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN, SO, SR, SS, ST, SV, SX, SY, SZ, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN, TO, TR, TT, TV, TW, TZ, UA, UG, UM, US, UY, UZ, VA, VC, VE, VG, VI, VN, VU, WF, WS, XK, YE, YT, ZA, ZM, ZW ]
    id: number;
    name1: string;
    name2: string;
    notEmpty: boolean;
    street: string;
    zipCode: string;
}

export interface CartItemDTO {
    description: string;
    id?: number;
    imageLink: string;
    invalid?: boolean;
    itemType: ItemType;
    manufacturer: string;
    notEmpty?: boolean;
    positionId?: number;
    priceGross?: number;
    priceNet?: number;
    productEan: string;
    productExternalId: string;
    productId: string;
    vat?: number;
    errorMessage?: string;
    tyreEULabel?: TyreEULabelDTO;
    carBuildDate?: string;
    productMetadata?: any;
}

export interface CartPositionDTO {
    axle?: Axle;
    boltCircle?: string;
    carDescription: string;
    carHsn: string;
    carTsnVsn: string;
    cartId: number;
    carId?: string;
    id: number;
    invalid: boolean;
    items: CartItemDTO[];
    notEmpty: boolean;
    positionType: string; // Enum: [ ARTICLE, MOUNTED_WHEEL, RIM, TYRE, VOUCHER ]
    pricePerUnitGross: number;
    pricePerUnitNet: number;
    quantity: number;
    sumGross: number;
    sumNet: number;
    vatPerUnit: number;
    vatSum: number;
}

export enum PaymentMethod {
    PAY_PAL = 'PAY_PAL',
    PRE_PAYMENT = 'PRE_PAYMENT',
    PICKUP = 'PICKUP',
    RATEPAY = 'RATEPAY'
}

export interface CartDTO {
    deliveryAddress: AddressDTO;
    discount: number;
    id: number;
    invalid: boolean;
    invoiceAddress: AddressDTO;
    lastChanged: string; // ($date-time)
    paymentMethod?: PaymentMethod; // Enum: [ PRE_PAYMENT, PAY_PAL, PICKUP ]
    positions: CartPositionDTO[];
    shipping: number;
    sumGross: number;
    sumNet: number;
    userId: number;
    email: string;
    vat: number;
    voucherCode: string;
    voucherWarning?: boolean;
    remark?: string;
}

export interface CartPreviewDTO {
    itemCount: number,
    sumGross: number
}
