import React from "react";

function LoadingOverlay() {

    return (
        <div id="loading-overlay">
            <div className="loading-spinner"></div>
        </div>
    )
}

export default LoadingOverlay;
