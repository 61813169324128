import React, { useEffect, useState } from "react";
import { UserDTO } from "../models/shared/UserDTO";
import UserContext from "./UserContext";
import { login } from "../redux-store/api";

const UserProvider = ({ children }) => {
    const storagePrefix = "UserProvider_";
    const [user, setUser] = useState<UserDTO>(null);
    const [loggedIn, setLoggedIn] = useState(false);

    const loginUser = (userdata) => {
        setUser(userdata);
        setLoggedIn(true);
        if (sessionStorage) {
            sessionStorage.setItem(storagePrefix + "loggedIn", JSON.stringify(true));
            sessionStorage.setItem(storagePrefix + "user", JSON.stringify(userdata));
        }
    };

    const logoutUser = () => {
        setUser(null);
        setLoggedIn(false);

        if (sessionStorage) {
            sessionStorage.setItem(storagePrefix + "loggedIn", JSON.stringify(false));
            sessionStorage.setItem(storagePrefix + "user", null);
        }
    };

    useEffect(() => {
        if (sessionStorage) {
            setLoggedIn(JSON.parse(sessionStorage.getItem(storagePrefix + "loggedIn")));
            setUser(JSON.parse(sessionStorage.getItem(storagePrefix + "user")));
        }
        login()
            .then((response) => {
                loginUser(response);
            })
            .catch(() => logoutUser());
    }, []);

    return (
        <UserContext.Provider value={{ user, loggedIn, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
