import React, {useState} from "react";
import {TradeName} from "../../TradeName/TradeName";
import {useTranslation} from "react-i18next";
import Slider from "rc-slider";
import {useMediaQuery} from "../../../helper/useMediaQuery";

interface Props {
    loweringMin: number;
    loweringMax: number;
    selectedLowering: number;
    loweringChanged?: Function;
    isSmallScreen: boolean;
}

export default function CarViewLowering(props: Props) {
    const [t] = useTranslation();
    const [currentLowering, setCurrentLowering] = useState(props.selectedLowering);

    const onChangeComplete = (lowering) => {
        setCurrentLowering(lowering);
        props.loweringChanged(lowering, true);
    }

    function getButtonBar() {
        return (
            <div className={`${!props.isSmallScreen ? 'd-grid mt-4' : 'car-view-rim-page__button'} `}>
                <button className="btn w-100 btn-primary mt-2 fw-normal"
                        onClick={() => props.loweringChanged(currentLowering)}>
                    {t('CAR_VIEW.CLOSE')}
                </button>
            </div>
        );
    }

    return (
        <div className={`${props.isSmallScreen ? 'd-flex flex-column justify-content-between h-100' : ''} `}>
            <div className={`title-container mb-0`}>
                <h1 className={`title ${props.isSmallScreen ? 'mobile' : ''}`}>
                    {t('CAR_VIEW.LOWER')}
                </h1>
            </div>
            <div
                className={`${props.isSmallScreen ? 'py-2 h-100 d-flex justify-content-center' : ''} `}>
                <Slider
                    defaultValue={0}
                    min={props.loweringMin}
                    max={props.loweringMax}
                    value={currentLowering}
                    step={1}
                    onChange={(lowering: number) => {
                        setCurrentLowering(lowering);
                    }}
                    onChangeComplete={onChangeComplete}
                    vertical={true}
                    reverse={true}
                    railStyle={{width: 6}}
                    trackStyle={{width: 6}}
                    handleStyle={{
                        borderColor: '#e19a00',
                        height: 20,
                        width: 20,
                        marginLeft: -7,
                        marginBottom: -10,
                        marginTop: -10,
                        backgroundColor: '#e19a00',
                    }}
                    className={`${props.isSmallScreen ? 'mobile-slider' : ''}`}
                />


                {!props.isSmallScreen && getButtonBar()}
            </div>
            {props.isSmallScreen && getButtonBar()}
        </div>
    );
}
