import { StrapiStaticPage } from "../../models/strapi/StrapiStaticPage";

export enum SeoMarketingPageActionType {
    SET_DATA = 'SeoMarketingPageActionType [SET_DATA]',
}

export class SeoMarketingPageAction {
    constructor(
        public type: SeoMarketingPageActionType,
        public payload: StrapiStaticPage
    ) {}
}

export const seoMarketingPageReducer = (state: StrapiStaticPage = {} as any, {type, payload}: SeoMarketingPageAction) => {
    switch (type) {
        case SeoMarketingPageActionType.SET_DATA:
            return {
                ...(payload || {})
            }
        default:
            return state;
    }
}
