import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import sessionStorage from "redux-persist/lib/storage/session";
import { cartPreviewReducer } from "./reducers/cartPreview";
import { rimBrandReducer } from "./reducers/rimBrandReducer";
import { rimSearchReducer } from "./reducers/RimSearchReducer";
import { seoMarketingPageReducer } from "./reducers/seoMarketingPageReducer";
import { shoppingcartReducer } from "./reducers/shoppingCartReducer";
import { tyreSearchReducer } from "./reducers/TyreSearchReducer";
import wishlistCountReducer from "./reducers/wishlistCountReducer";
import {fullScreenModeReducer} from "./reducers/FullScreenModeReducer";
import {cookieConsentReducer} from "./reducers/cookieConsentReducer";

export const storeEnvMode = (envMode) => ({
   type: "STORE_ENV_MODE",
   envMode,
});

const envReducer = (state = {}, action) => {
   switch (action.type) {
      case "STORE_ENV_MODE":
         return action.envMode;
      default:
         return state;
   }
};

export const setNavigation = (location) => ({
   type: "SERVER_SET_NAVIGATION",
   location,
});

const notFoundNavigationReducer = (state = "", action) => {
   switch (action.type) {
      case "SERVER_SET_NAVIGATION":
         return action.location;
      default:
         return state;
   }
};

const persistConfig = {
   key: "app",
   storage: sessionStorage,
   blacklist: ['tyreSearch']
};

const reducers = combineReducers({
   envMode: envReducer,
   location: notFoundNavigationReducer,
   shoppingcart: shoppingcartReducer,
   cartPreview: cartPreviewReducer,
   wishlistCount: wishlistCountReducer,
   rimSearch: rimSearchReducer,
   rimBrand: rimBrandReducer,
   tyreSearch: tyreSearchReducer,
   seoMarketingPage: seoMarketingPageReducer,
   fullScreenMode: fullScreenModeReducer,
   cookies: cookieConsentReducer
});

const reducer = persistReducer(persistConfig, reducers);
export default (initialState?) => configureStore({
   reducer: reducer,
   preloadedState: initialState,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
   }),
});
