import React, { useEffect, useState } from "react";
import { SharedSeo } from "../../models/strapi/SharedSeo";
import { getStaticPage } from "../../redux-store/api/StrapiApi";
import { Markdown } from "../Markdown/Markdown";
import { StrapiMetadataHelmet } from "./StrapiMetadataHelmet";

export function StrapiStaticPageComponent({strapiKey, children}: {strapiKey:string, children?: JSX.Element}){
    const [content, setContent] = useState("");
    const [metadata, setMetadata] = useState<SharedSeo>();
    useEffect(() => {
        getStaticPage(strapiKey).then((page) => {
            if(page) {
                setContent(page.attributes.Content);
                setMetadata(page.attributes.Metadata);
            } else {
                setContent("");
                setMetadata(undefined)
            }
        });
    }, [strapiKey]);
    return (
        <div className="static-wrapper">
            <StrapiMetadataHelmet metadata={metadata} />
            <div className="container">
                <Markdown content={content} />
                {children ? children : <></>}
            </div>
        </div>
    )
}
