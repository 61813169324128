import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { TradeName } from "../../../components/TradeName/TradeName";
import { PaymentMethod } from "../../../models/cart/CartDTO";
import { updateOrderPayment } from "../../../redux-store/api/OrderApi";
import PaymentAndShipping from "../PaymentAndShipping/PaymentAndShipping";
import { PaymentLinkModal } from "./PaymentLinkModal";

export function ChangeOrderPayment(props: { orderId: any }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentLinkModal, setPaymentLinkModal] = useState({ showModal: false, paymentLink: "" });

    function submitNewPayment(newPaymentMethod: PaymentMethod) {
        setIsSubmitting(true);
        setPaymentMethod(newPaymentMethod);
        updateOrderPayment(props.orderId, newPaymentMethod)
            .then((res) => {
            if (res.paymentCreationModel?.paymentUrl) {
                window.location.href = res.paymentCreationModel?.paymentUrl;
                setPaymentLinkModal({ showModal: true, paymentLink: res.paymentCreationModel?.paymentUrl });
            } else if(res.successful) {
                navigate({
                    pathname: "/order-confirmation/success",
                    search: res.orderId ? "orderId=" + res.orderId : "",
                });
            } else {
                navigate("/order-confirmation/error")
            }
        }, ()=> navigate("/order-confirmation/error"))
            .finally(()=> setIsSubmitting(false));
    }

    return (
        <>
            <TradeName>{t("PAY_PAL.CANCELED.TITLE")}</TradeName>
            <div className="container">
                {t("PAY_PAL.CANCELED.TEXT")}
                <PaymentAndShipping selectedPaymentMethod={undefined} submit={(pm) => submitNewPayment(pm)} />
            </div>
            {isSubmitting && <div className="block-user-interactions"></div>}
            <CustomModal open={paymentLinkModal?.showModal}>
                <PaymentLinkModal
                    close={() => setPaymentLinkModal({ showModal: false, paymentLink: "" })}
                    paymentLink={paymentLinkModal?.paymentLink}
                    paymentMethod={paymentMethod}
                />
            </CustomModal>
        </>
    );
}
