import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../helper/useMediaQuery";

interface Props {
    close: Function;
}

function AddedToCartModal(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery("(max-width: 575.98px)");

    const handleClose = () => {
        props.close();
    };

    const goToCart = () => {
        navigate("/warenkorb");
    };
    useEffect(() => {
        goToCart();
    }, []);

    return null;/* Keep Code for later when Cross/Upselling will be implemented (
        <div className="custom-modal added-to-cart-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="custom-modal-title d-none d-sm-inline-block">
                        {t("ADDED_TO_CART_MODAL.TITLE")}
                    </h5>
                    <button type="button" className="btn-close" onClick={handleClose} aria-label="Close">
                    </button>
                </div>
                <div className="modal-body">
                    <div className="message"><i className="fas fa-check me-2"/>{t("ADDED_TO_CART_MODAL.MESSAGE")}</div>
                </div>
                <div className="modal-footer">
                    <button type="button" className={`btn btn-outline-primary ${isSmallScreen ? 'w-100' : ''}`} onClick={handleClose}>
                        {t("ADDED_TO_CART_MODAL.CONTINUE_SHOPPING")}
                    </button>
                    <button type="button" className={`btn btn-green ${isSmallScreen ? 'w-100' : ''}`} onClick={goToCart}>
                        {t("ADDED_TO_CART_MODAL.GO_TO_CART")}
                    </button>
                </div>
            </div>
        </div>
    )*/
    ;
}

export default AddedToCartModal;
