import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function CompleteWheels() {
    const [t] = useTranslation();
    let links = [
        {
            title: "Felgenkonfigurator",
            link: "/felgenkonfigurator.html",
        },
        {
            title: "Hyundai Kompletträder",
            link: "/hyundai-komplettraeder.html",
        },
        {
            title: "Renault Kompletträder",
            link: "/renault-komplettraeder.html",
        },
        {
            title: "Volvo Kompletträder",
            link: "/volvo-komplettraeder.html",
        },
        {
            title: "Sommerräder",
            link: "/sommerraeder",
        },
        {
            title: "Audi Kompletträder",
            link: "/audi-komplettraeder.html",
        },
        {
            title: "Mazda Kompletträder",
            link: "/mazda-komplettraeder.html",
        },
        {
            title: "Seat Kompletträder",
            link: "/seat-komplettraeder.html",
        },
        {
            title: "VW Kompletträder",
            link: "/vw-komplettraeder.html",
        },
        {
            title: "Winterfelgen",
            link: "/winterfelgen",
        },
        {
            title: "BMW Kompletträder",
            link: "/bmw-komplettraeder.html",
        },
        {
            title: "Mercedes Kompletträder",
            link: "/mercedes-komplettraeder.html",
        },
        {
            title: "Skoda Kompletträder",
            link: "/skoda-komplettraeder.html",
        },
        {
            title: "Komplettradkonfigurator",
            link: "/komplettradkonfigurator",
        },
        {
            title: "Winterkompletträder",
            link: "/winterkomplettraeder",
        },
        {
            title: "Fiat Kompletträder",
            link: "/fiat-komplettraeder.html",
        },
        {
            title: "Nissan Kompletträder",
            link: "/nissan-komplettraeder.html",
        },
        {
            title: "Tesla Kompletträder",
            link: "/tesla-komplettraeder.html",
        },
        {
            title: "Sommerfelgen",
            link: "/sommerfelgen",
        },
        {
            title: "Kompletträder",
            link: "/komplettraeder",
        },
        {
            title: "Ford Kompletträder",
            link: "/ford-komplettraeder.html",
        },
        {
            title: "Opel Kompletträder",
            link: "/opel-komplettraeder.html",
        },
        {
            title: "Toyota Kompletträder",
            link: "/toyota-komplettraeder.html",
        },
        {
            title: "Sommerkompletträder",
            link: "/sommerkomplettraeder",
        },
    ];
    return (
        <div id="complete-wheels-wrapper">
            <div className="container-fluid">
                <h4>{t('HOME.COMPLETE_WHEELS.OUR_COMPLETE_WHEELS')}</h4>
                <ul id="complete-wheels">
                    {links.map((link) => (
                        <li className="complete-wheel" key={link.title}>
                            <Link title={link.title}
                               to={link.link} reloadDocument={true}>
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
