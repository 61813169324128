import { AxiosResponse } from "axios";
import {MountedWheelCostsDTO, MountedWheelDetailsDTO} from "../../models/tyres/MountedWheelDetailsDTO";
import { TyreDTO } from "../../models/tyres/TyreDTO";
import { TyreSearchRequestDTO } from "../../models/tyres/TyreSearchRequestDTO";
import { TyreSearchResultDTO } from "../../models/tyres/TyreSearchResultDTO";
import { TyreSizesDTO } from "../../models/tyres/TyreSizesDTO";
import axiosInstance from "./AxiosInstance";

/*
    selectedTyre Search
    requestBody contains request params
 */
export function getTyres(reqBody: TyreSearchRequestDTO) {
    return axiosInstance
        .post<TyreSearchRequestDTO, AxiosResponse<TyreSearchResultDTO>>(`/api/tyre/search`, reqBody)
        .then((res) => res.data);
}

export function searchMixedTyres(reqBody: TyreSearchRequestDTO) {
    return axiosInstance
        .post<TyreSearchRequestDTO, AxiosResponse<TyreSearchResultDTO>>(`/api/tyre/search/mixed`, reqBody)
        .then((res) => res.data);
}

/*
    Get one selectedTyre by ID
 */
export function getTyreDetails(id: string, shopMode: string) {
    const url = `/api/tyre/details/${id}?mode=${shopMode}`;
    return axiosInstance.get<TyreDTO>(url).then((res) => res.data);
}

export function getTyreSizes(width?: string, height?: string, diameter?: string) {
    try {
        return axiosInstance
            .get<TyreSizesDTO>(`/api/tyre/sizes`, { params: { width, height, diameter } })
            .then((res) => res.data);
    } catch (err) {
        console.log(err);
    }
}

export function getMountedWheelDetails(
    carId: string,
    rimFrontId: string,
    rimRearId: string,
    tyreFrontId: string,
    tyreRearId: string
) {
    carId = carId?.split("_")[0];
    return axiosInstance.get<MountedWheelDetailsDTO>(`/api/tyre/mountedwheeldetails`, {
        params: { carId, rimFrontId, rimRearId, tyreFrontId, tyreRearId },
    }).then(res => res.data);
}

export function getMountedWheelCosts(
    rimFrontSize: number,
    rimRearSize: number
) {
    return axiosInstance.get<MountedWheelCostsDTO>(`/api/tyre/mountedwheelcosts`, {
        params: { rimFrontSize, rimRearSize },
    }).then(res => res.data);
}
