import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    pdfLocation: string;
}

function CertificateInformation(props: Props) {
    const [t] = useTranslation();

    return (
        <div className="expandable-information">
            <button className="btn btn-collapsible" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseCertificate" aria-expanded="false"
                    aria-controls="collapseCertificate">
                <div className="d-flex justify-content-between">
                    <div>{t("RIMS.DETAILS.CERTIFICATE")}</div>
                    <div><i className="fas fa-chevron-down"/></div>
                </div>
            </button>
            <div className="collapse" id="collapseCertificate">
                <div className="content mt-3">
                    <a href={props.pdfLocation} rel="noreferrer noopener" target="_blank"
                       className="certificate">
                        <i className="fas fa-file"/> {t("RIMS.DETAILS.DOWNLOAD_CERTIFICATE")}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CertificateInformation;
