import React, { useContext, useState } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as yup from "yup";
import { AmazonSignIn } from "../../components/AmazonSignIn";
import { AppleSignIn } from "../../components/AppleSignIn";
import { GoogleSignIn } from "../../components/GoogleSignIn";
import { UserDTO } from "../../models/shared/UserDTO";
import { login, loginGoogle } from "../../redux-store/api";
import UserContext from "../../context/UserContext";
import {UserContextType} from '../../context/UserContextType';
import {updateCartPreview} from "../../redux-store/actions/cartPreviewAction";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    updateCartPreview: Function;
}

function Login({updateCartPreview}: Props) {
    const [t] = useTranslation();
    const {loginUser} = useContext(UserContext) as UserContextType;
    const [loginError, setLoginError] = useState(false);

    const isSmallScreen = useMediaQuery('(max-width: 575.98px)');

    const initialValues = {
        email: "",
        password: ""
    };
    const validationSchema = yup.object().shape({
        email: yup.string()
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        password: yup.string()
            .required(t("FORMS.VALIDATIONS.REQUIRED"))
    });

    function submitForm(values: FormikValues, actions: FormikHelpers<any>) {
        login(values.email, values.password)
            .then((resp) => {
                loginUser(resp);
                setLoginError(false)
            }, () => {
                setLoginError(true)
            })
            .finally(() => {
                actions.setSubmitting(false);
                updateCartPreview();
        });
    }
    function updateLoggedIn(user: UserDTO) {
        loginUser(user);
        setLoginError(false)
        updateCartPreview();
    }

    return (
        <div className="container">
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="width-wrapper mb-4">
                        <h2 className="text-dark"><i>{t("LOGIN.TITLE")}</i></h2>
                    </div>
                    <div className="width-wrapper">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={submitForm}
                        >
                            {({
                                  touched,
                                  errors
                              }) => (
                                <Form onChange={()=>setLoginError(false)}>
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            <Field autoComplete="off"
                                                   className={`${
                                                       errors.email &&
                                                       touched.email
                                                           ? "is-invalid"
                                                           : ""
                                                   } form-control`}
                                                   type="text"
                                                   name="email"
                                                   placeholder={t("LOGIN.MAIL")}
                                                   required
                                            />
                                            {errors.email &&
                                            touched.email && (
                                                <div className="invalid-feedback">
                                                    {errors.email.toString()}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 my-2">
                                            <Field autoComplete="off"
                                                   className={`${
                                                       errors.password &&
                                                       touched.password
                                                           ? "is-invalid"
                                                           : ""
                                                   } form-control`}
                                                   type="password"
                                                   name="password"
                                                   placeholder={t("LOGIN.PASSWORD")}
                                                   required
                                            />
                                            {errors.password &&
                                            touched.password && (
                                                <div className="invalid-feedback">
                                                    {errors.password.toString()}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 my-3">
                                            {loginError && <div className="alert alert-danger" role="alert">
                                                {t('LOGIN.WRONG_CREDENTIALS')}
                                            </div>}
                                            <button type="submit"
                                                    className="btn btn-primary w-100"
                                            >
                                                {t("LOGIN.LOG_IN")}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="row">
                            <div className={`col-12 my-1`}>
                                <GoogleSignIn className={"login-margin-bottom"} updateLoggedIn={user => updateLoggedIn(user)} />
                            </div>
                            <div className="col-12 my-1">
                                <AmazonSignIn updateLoggedIn={user => updateLoggedIn(user)}/>
                            </div>
                            <div className="col-12 my-1">
                                <AppleSignIn updateLoggedIn={user => updateLoggedIn(user)}/>
                            </div>
                        </div>
                    </div>
                    <div className="width-wrapper mt-4">
                        <Link to="/password-reset"> {t("LOGIN.FORGOT_PASSWORD")}</Link>
                    </div>
                    <div className="width-wrapper mt-4">
                        <Link to="/register"> {t("LOGIN.REGISTER")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    updateCartPreview
};

export default connect(null, mapDispatchToProps)(Login);
