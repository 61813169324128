import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SortDropdown} from "../../../../components/Input/SortDropdown";
import {RimGroupSearchResultDTO, RimItemDTO} from "../../../../models/rims/RimDetailDTO";
import {SearchSort} from "../../../../models/shared/SearchSort";
import {WishListDTO} from "../../../../models/wishlist/WishlistDTO";
import RimCard, {RimSizeData} from "./RimCard/RimCard";

interface RimResultProps {
    carId?: string;
    carManufacturer?: string,
    carTradeName?: string,
    getWishlistCallback?: Function;
    isLoading?: boolean;
    rims: RimGroupSearchResultDTO[];
    rimSelectionCallback?: Function;
    selectedRim?: RimItemDTO;
    totalCount: number;
    wishlistEntries: WishListDTO[];
    onSortChange: (value: string) => void;
    sort: SearchSort;
    pageSize: number;
    firstSearch: boolean;
}

export default function RimResults(props: RimResultProps) {
    const [t] = useTranslation();
    const [showlistView, setListView] = useState(false);


    let rimSizeData: RimSizeData = {maxSize: -1, minSize: -1, colAmount: -1};

    if (props.rims) {
        rimSizeData.colAmount = props.rims.reduce((maxLength, rim) => Math.max(maxLength, rim.items?.length), 0);

        rimSizeData.maxSize = props.rims.reduce((maxSize, rim) => {
            const itemSizes = rim.items.map((item) => item.size);
            const maxItemSize = Math.max(...itemSizes);
            return Math.max(maxSize, maxItemSize);
        }, 0);

        rimSizeData.minSize = props.rims.reduce((minSize, rim) => {
            const itemSizes = rim.items.map((item) => item.size);
            const minItemSize = Math.min(...itemSizes);
            return Math.min(minSize, minItemSize);
        }, Infinity);
    }

    return (
        <>
            {!props.isLoading && (
                <>
                    {props.rims?.length > 0 && (
                        <>
                            <div className="rim-results">
                                <div
                                    className={
                                        "d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center"
                                    }
                                >
                                    <div className={"col-lg-6 col-12"}>
                                        <div className={"row justify-content-between"}>
                                            <div className={`col-9 d-flex align-items-center`}>
                                                <div className={"row"}>
                                                    <div className={`col result-counter`}>
                                                        <span className="text-highlight">{props.totalCount}&nbsp;</span>
                                                        <span>{t("RIMS.SEARCH.MATCHING_RESULTS")}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-3 d-flex justify-content-end align-items-end"}>
                                                <button
                                                    className={`icon-button ${showlistView && "active-view"}`}
                                                    onClick={() => {
                                                        setListView(true);
                                                    }}
                                                >
                                                    <i
                                                        className={"fas fa-list icon-image m-1  align-middle"}
                                                        data-tooltip-id="list"
                                                    />
                                                </button>

                                                <button
                                                    className={`icon-button ${!showlistView && "active-view"}`}
                                                    onClick={() => {
                                                        setListView(false);
                                                    }}
                                                >
                                                    <i
                                                        className={"fas fa-th-large icon-image m-1 align-middle"}
                                                        data-tooltip-id="grid view"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-lg d-none d-lg-block"}>
                                        <div className={"row justify-content-lg-end align-items-end"}>
                                            <div style={{width: "250px"}}>
                                                <SortDropdown
                                                    name="SORT"
                                                    placeholder="FILTER.PLACEHOLDER_SORT"
                                                    value={props.sort}
                                                    onChange={(e) => props.onSortChange(e.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rim-cards row mt-3">
                                    {props.rims?.map((rim, index) => (
                                        <div
                                            className={`col-${showlistView ? "12" : "6"} ${
                                                !showlistView && "col-sm-6 col-lg-4 col-xl-3 mb-3 px-lg-2"
                                            }`}
                                            id={rim.rimId}
                                            key={rim.rimId}
                                        >
                                            <RimCard
                                                rim={rim}
                                                carId={props.carId}
                                                carTradeName={props.carTradeName}
                                                carManufacturer={props.carManufacturer}
                                                selectedRim={props.selectedRim}
                                                rimSelectionCallback={(item) =>
                                                    props.rimSelectionCallback(item, rim)
                                                }
                                                getWishlistCallback={props.getWishlistCallback}
                                                wishlistEntries={props.wishlistEntries}
                                                isListView={showlistView}
                                                rimSizeData={rimSizeData}
                                            />
                                            {index > 0 && index % props.pageSize === 0 && (
                                                <div className={`page-divider page-${index / props.pageSize}`}></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    {props.rims?.length === 0 && !props.firstSearch && (
                        <div className="row my-5">
                            <div className="col-12">
                                <h3 className="text-center">{t("RIMS.SEARCH.NO_RESULTS")}</h3>
                            </div>
                            <div className="col-12 text-center">
                                <Link to="/" className="btn btn-primary">
                                    {t("RIMS.SEARCH.CHANGE_CAR")}
                                </Link>
                            </div>
                        </div>
                    )}
                </>
            )}
            {props.isLoading && (
                <div className="rim-results loading">
                    <div className="total-count"/>
                    <div className="rim-cards row mt-3">
                        {Array.from(new Array(12)).map((value, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-20p mb-3">
                                <div className="rim-card-loader"/>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
