export interface CookieConsentState {
    allowedCookies: [];
}

export enum CookieConsentReducerActionType {
    GET = "GET_COOKIECONSENT",
    SET = "SET_COOKIECONSENT",
}

export interface CookieAction {
    type: CookieConsentReducerActionType;
    payload: CookieConsentState;
}

export const cookieConsentReducer = (state: CookieConsentState = {allowedCookies: []}, action: CookieAction) => {

    switch (action.type) {
        default:
            return state;

        case CookieConsentReducerActionType.SET:
            return {
                ...state,
                allowedCookies: action.payload.allowedCookies
            };

    }
}