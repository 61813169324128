import React, { useContext, useState } from "react";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import {saveCarBuildDate} from "../../../../redux-store/api/ShoppingCartApi";
import {CartItemDTO} from "../../../../models/cart/CartDTO";
import { ShoppingCartContext } from "../../ShoppingCart";

interface Props {
    item: CartItemDTO;
}

export default function CarBuildDateInput({item}: Props) {
    const [t] = useTranslation();
    const [submitText, setSubmitText] = useState<string>("");
    const {changeLoadingCounter, cartChanged} = useContext(ShoppingCartContext);
    let timeout: any;

    function submitForm(values: FormikValues) {
        setSubmitText(t("CART.DATA_SUBMITTING"));
        changeLoadingCounter(true)
        saveCarBuildDate(item.id + '', values.carBuildDate).then((res) => {
            cartChanged();
            console.log(res);
            setSubmitText(t("CART.DATA_SUBMIT_SUCCESS", {0: values.carBuildDate}))
        }).catch((e) => {
            console.log(e);
            setSubmitText("CART.DATA_SUBMIT_ERROR");
        }).finally(() => {
            changeLoadingCounter(false)
            if (timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                setSubmitText("");
            }, 5000);
        });

    }

    return (
        <div className="mt-3">
            <Formik
                initialValues={{
                    carBuildDate: item.carBuildDate || ""
                }}
                enableReinitialize
                onSubmit={submitForm}
            >
                {(props: FormikProps<any>) => (
                    <Form>
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="input-group">
                                <Field autoComplete="off"
                                       className="form-control"
                                       type="text"
                                       name="carBuildDate"
                                       placeholder={t("CART.CAR_BUILD_DATE_PLACEHOLDER")}
                                />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-outline-primary">
                                        <i className="fas fa-sync"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>{submitText}</div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}


