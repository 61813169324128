import { OrderDTO } from "../order/OrderDTO";

export interface PaymentStatusDTO {
    status: PaymentStatus;
    orderId: number;
    order: OrderDTO
}
export enum PaymentStatus {
    GENERATED = "GENERATED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    COMPLETED = "COMPLETED"
}
