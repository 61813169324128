import React from "react";
import { useTranslation } from "react-i18next";

function Info() {
    const [t] = useTranslation();

    return (
        <div className="gradient-arrow-banner-box info-wrapper m-0">
            <div className="gradient-arrow-banner d-none d-xl-flex">
                <div className="pointer p0" />
                <div className="pointer p1" />
                <div className="pointer p2" />
                <div className="pointer p3" />
                <div className="pointer p4" />
            </div>
            <div className="text-overlay infos flex-column flex-xl-row">
                <div className="text-left-side">
                    <div className="h2 m-0">
                        {t("HOME.INFO.TITLE_1")} <br />
                        <span className="small ">{t("HOME.INFO.TITLE_2")}</span>
                    </div>
                </div>
                <div className="text-center-side flex-column flex-lg-row">
                    <div className="info px-4">
                        <div>
                            <img
                                className="img-fluid mb-2"
                                src="/home/icon-3d-felgenkonfigurator.png"
                                alt="icon-3d-felgenkonfigurator.png"
                                title={t("HOME.INFO.3D_CONFIGURATOR")}
                            />
                        </div>
                        <div className="h3 no-wrap ">{t("HOME.INFO.3D_CONFIGURATOR")}</div>
                        <b>{t("HOME.INFO.3D_CONFIGURATOR_INFO")}</b>
                    </div>
                    <div className="info px-4">
                        <div>
                            <img className="img-fluid mb-2" src="/home/icon-komplettraeder.png"
                                 alt="icon-komplettraeder.png" />
                        </div>
                        <div className="h3 no-wrap ">{t("HOME.INFO.MOUNTED_WHEELS")}</div>
                        <b>{t("HOME.INFO.MOUNTED_WHEELS_INFO")}</b>
                    </div>
                    <div className="info px-4">
                        <div>
                            <img
                                className="img-fluid mb-2"
                                src="/home/icon-kostenfreier-versand.png"
                                alt="icon-kostenfreier-versand.png"
                            />
                        </div>
                        <div className="h3 no-wrap ">{t("HOME.INFO.FREE_SHIPPING")}</div>
                        <b>{t("HOME.INFO.FREE_SHIPPING_INFO")}</b>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
