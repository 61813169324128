import { UserRegistrationDTO } from "../../models/registration/UserRegistrationDTO";
import { ChangePasswordRequestDTO } from "../../models/shared/ChangePasswordRequestDTO";
import { ResetPasswordRequestDTO } from "../../models/shared/ResetPasswordRequestDTO";
import { UserDTO } from "../../models/shared/UserDTO";
import axiosInstance from "./AxiosInstance";

export function register(registrationDTO: UserRegistrationDTO) {
    return axiosInstance.post(`/api/user`, registrationDTO);
}

export function activateAccount(userId: number, token: string) {
    return axiosInstance.get(`/api/user/${userId}/activate/${token}`);
}

export function getUserDetails() {
    return axiosInstance.get(`/api/user/details`).then((res) => res.data);
}

export function editUserDetails(user: UserDTO) {
    return axiosInstance.put(`/api/user/details`, user).then((res) => res.data);
}

export function sendPasswordToken(username: string) {
    const url = `/api/user/password/token`;
    return axiosInstance.get(url, { params: { username } });
}

export function resetPassword(reqBody: ResetPasswordRequestDTO) {
    const url = `/api/user/password/reset`;
    return axiosInstance.put(url, reqBody);
}

export function changePassword(reqBody: ChangePasswordRequestDTO) {
    const url = `/api/user/password`;
    return axiosInstance.put(url, reqBody);
}
