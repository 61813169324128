import { Helmet } from "react-helmet";
import { SharedSeo } from "../../models/strapi/SharedSeo";
import { MetaDefinition } from "../../types/global";

export function StrapiMetadataHelmet({metadata}: {metadata: SharedSeo}){

    if(metadata) {
        const metaDefinitions = generateMetaDefinitions(metadata)
        return <Helmet>
            <title>{metadata.metaTitle}</title>
            {metaDefinitions.map((d, index) => <meta key={'meta-'+index} {...d} />)}
        </Helmet>;
    }


    function generateMetaDefinitions(metadata: SharedSeo): MetaDefinition[] {
        return [
            { name: "title", content: metadata.metaTitle },
            { property: "og:title", content: metadata.metaTitle },

            { name: "description", content: metadata.metaDescription },
            { property: "og:description", content: metadata.metaDescription },

            { name: "keywords", content: metadata.metaKeywords },

            { property: "og:type", content: "website" }
        ];
    }
}
