import React from "react";
import TooltipSlider from "../TooltipSlider/TooltipSlider";
import { InputChanged } from "./Input";
import { InputHelper } from "./InputHelper";

const sliderRangeLabelStyle = {
    fontSize: "0.85em",
    marginTop: "-8px",
};
export function InputSlider(props: {
    min: number;
    max: number;
    defaultMin: number;
    defaultMax: number;
    onChange: InputChanged;
    range: boolean;
    name: string;
}) {
    return (
        <div>
            <TooltipSlider
                range={props.range}
                min={Math.floor(props.min)}
                max={Math.ceil(props.max)}
                defaultValue={[props.defaultMin, props.defaultMax]}
                allowCross={false}
                onChangeComplete={(v) => {
                    if (Array.isArray(v)) {
                        props.onChange(v.map((v) => v?.toString()));
                    } else {
                        props.onChange([v?.toString()]);
                    }
                }}
                tipFormatter={(value) => InputHelper.filterLabel(value, props.name)}
            />
            <div className="d-flex justify-content-between">
                <div style={sliderRangeLabelStyle}>{InputHelper.filterLabel(Math.floor(props.min), props.name)}</div>
                <div style={sliderRangeLabelStyle}>{InputHelper.filterLabel(Math.ceil(props.max), props.name)}</div>
            </div>
        </div>
    );
}
