import { produce } from "immer";
import { Location, NavigateFunction } from "react-router-dom";
import { FilterInterface } from "../models/shared/FilterInterface";
import { equalsIgnoreOrder } from "./Helper";

export class UrlFilterHelper {
    private static prefix = "filter_";
    private static parseRegex = new RegExp(`${UrlFilterHelper.prefix}(?<name>[a-zA-Z\-_\.]+)`);

    static pushFiltersToHistory(location: Location, navigate: NavigateFunction, filters: Partial<FilterInterface>[]) {
        const searchParams = new URLSearchParams(location.search);
        filters.map((filter) => {
            if(filter.key) {
                const filterKey = this.prefix + filter.key;
                if (filter.selectedValues?.length > 0) {
                    searchParams.set(filterKey, filter.selectedValues.toString());
                } else {
                    searchParams.delete(filterKey);
                }
            }
        });
        searchParams.set("page", "0");
        navigate({ search: searchParams.toString() });
    }

    static compareFilters(aFilter, bFilter) {
        if (aFilter === bFilter) {
            return true;
        }
        if (aFilter?.length !== bFilter?.length) {
            return false;
        }

        for (let filterIndex = 0; filterIndex < aFilter.length; filterIndex++) {
            const filterProps = Object.getOwnPropertyNames(aFilter[filterIndex]);

            for (const filterProp of filterProps) {
                const aFilterProp = aFilter[filterIndex][filterProp];
                const bFilterProp = bFilter[filterIndex][filterProp];
                if (aFilterProp !== bFilterProp) {
                    if (
                        typeof aFilterProp === "object" &&
                        typeof bFilterProp === "object"
                    ) {
                        if (aFilterProp?.length === bFilterProp?.length) {
                            if (
                                !equalsIgnoreOrder(aFilterProp, bFilterProp)
                            ) {
                                return false;
                            }
                        } else if(!((aFilterProp?.length === 0 && !bFilterProp) || (!aFilterProp && bFilterProp?.length === 0))) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    static getFilterFromLocation(location: Location, currentFilters: Partial<FilterInterface>[], compareFilters = true) {
        if (location?.search) {
            const filters = produce(currentFilters, (draft) => {
                const searchParams = new URLSearchParams(location?.search);
                draft?.forEach(f => {
                    if(f.defaultSelectedValues?.length > 0) {
                        f.selectedValues = [].concat(f.defaultSelectedValues);
                    } else {
                        f.selectedValues = [];
                    }
                });
                searchParams.forEach((searchParamsValues, searchParamsKey) => {
                    if (searchParamsKey.startsWith(this.prefix)) {
                        const parsedKey = this.parseRegex.exec(searchParamsKey)?.groups?.['name'];
                        const filter = draft?.find((f) => f.key === parsedKey);
                        const selectedValues = searchParamsValues.split(",");
                        if (filter) {
                            filter.selectedValues = selectedValues;
                        } else if(currentFilters?.length === 0) {
                            draft.push({
                                key: parsedKey,
                                selectedValues: selectedValues,
                            })
                        }
                    }
                });
            });

            if (!compareFilters || !UrlFilterHelper.compareFilters(filters, currentFilters)) {
                return filters as FilterInterface[];
            }
        }
    }
}
