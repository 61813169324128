import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { NavLink } from "react-router-dom";
import { getFallbackImage } from "../../helper/Helper";
import { CartDTO, CartPreviewDTO } from "../../models/cart/CartDTO";
import { getShoppingCart } from "../../redux-store/api/ShoppingCartApi";
import Image from "../Image/Image";

interface CartPreviewProps {
    cartPreview: CartPreviewDTO;
}

export function CartPreview({ cartPreview }: CartPreviewProps) {
    const { t } = useTranslation();
    const [showCartPreview, setShowCartPreview] = useState<boolean>();
    const [showLoading, setShowLoading] = useState<boolean>();
    const [cart, setCart] = useState<CartDTO>();
    const mouseEnter:MouseEventHandler = (event) => {
        if((event.target as HTMLElement)?.closest('a')?.classList.contains('nav-link')) {
            setShowCartPreview(true);
            setShowLoading(true);
            getShoppingCart().then((data) => {
                setCart(data);
                setShowLoading(false);
            });
        }
    };
    const mouseOut: MouseEventHandler = (event) => {
        if (!(event.relatedTarget as HTMLElement)?.closest(".cart-preview-nav")) {
            setShowCartPreview(false);
        }
    };

    function getLoadingElements() {
        const res = [];
        for (let i = 0; i <= (cartPreview.itemCount || 1); i++) {
            res.push(<div key={'loading-'+i} className="cart-preview-loading mb-2"></div>);
        }
        return res;
    }

    return (
        <li className="nav-item active cart-preview-nav" onMouseEnter={mouseEnter} onMouseOut={mouseOut}>
            <NavLink
                className={"nav-link cart" + (showCartPreview ? " visible" : "")}
                to="/warenkorb"
                title={t("CART.LINK_TITLE")}
            >
                <div className="position-relative">
                    <i className="fas fa-shopping-cart"></i>
                    {cartPreview?.itemCount > 0 && (
                        <span className="position-absolute translate-middle badge rounded-pill">
                            {cartPreview?.itemCount}
                        </span>
                    )}
                </div>
                {cartPreview?.sumGross ? (<>
                    <div className="cart-spacer"></div>
                    <NumericFormat
                        value={cartPreview?.sumGross}
                        displayType="text"
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="€"
                        prefix=""
                    /></>
                ) : (
                    ""
                )}
            </NavLink>
            {showCartPreview && (
                <div className="cart-preview p-3">
                    {showLoading && getLoadingElements()}
                    {!showLoading && cart && (
                        <>
                            {cart.positions.map((position) => (
                                <div className="cart-preview-position" key={position.id}>
                                    {position.items.map((item) => (
                                        <div className="cart-preview-item mb-2" key={item.id}>
                                            <div className="img-container">
                                                {item.itemType === "MOUNTINGKIT" || item.itemType === 'WHEELASSEMBLY' ? (
                                                    <i className="fa-3x fas fa-wrench bordered"/>
                                                ) : (<Image
                                                    className="img-fluid bordered"
                                                    fallback={getFallbackImage(item)}
                                                    src={item.imageLink}
                                                    alt={item.description}
                                                    fluid={true}
                                                />)}
                                            </div>
                                            <div className="description-container">
                                                <div>{item.description}</div>
                                                {item.errorMessage && <div className="fw-normal text-danger mt-2">{item.errorMessage}</div>}
                                                <div className="text-end">
                                                    {item?.priceGross != null && item.priceGross > 0 && (<NumericFormat
                                                        value={item.priceGross * position.quantity}
                                                        displayType="text"
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix="€"
                                                    />)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className="cart-preview-summary">
                                <NumericFormat
                                    value={cart.sumGross}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix="€"
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </li>
    );
}
