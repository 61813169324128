import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { getManufacturerPages } from "../../../redux-store/api/StrapiApi";

function RimBrands() {
    const [rimBrands, setRimBrands] = useState<any[]>([]);
    const [t] = useTranslation();
    useEffect(() => {
        getRimBrands();
    }, []);

    function getRimBrands() {
        let manufacturers = [
            { name: "2DRV", link: "2DRV" },
            { name: "Advanti Racing", link: "Advanti Racing" },
            { name: "AEZ", link: "AEZ" },
            { name: "Alutec", link: "Alutec" },
            { name: "AMG", link: "" },
            { name: "Antera", link: "" },
            { name: "Artform", link: "Artform" },
            { name: "Asanti Wheels", link: "" },
            { name: "ATS", link: "ATS" },
            { name: "Autec", link: "Autec" },
            { name: "Avus", link: "Avus" },
            { name: "Axe Wheels", link: "" },
            { name: "Axxion", link: "Axxion" },
            { name: "Barracuda", link: "Barracuda" },
            { name: "BBS", link: "BBS" },
            { name: "Borbet", link: "Borbet" },
            { name: "Brabus", link: "" },
            { name: "Breyton", link: "Breyton" },
            { name: "Brock", link: "Brock" },
            { name: "Carmani", link: "Carmani" },
            { name: "Cheetah Wheels", link: "Cheetah Wheels" },
            { name: "CMS", link: "CMS" },
            { name: "Corspeed", link: "Corspeed" },
            { name: "Damina Performance", link: "Damina Performance" },
            { name: "DBV", link: "DBV" },
            { name: "Delta", link: "Delta" },
            { name: "Deluxe Wheels", link: "" },
            { name: "Dezent", link: "Dezent" },
            { name: "Diamond", link: "" },
            { name: "Diewe Wheels", link: "Diewe Wheels" },
            { name: "Dolce Wheels", link: "" },
            { name: "Dotz", link: "Dotz" },
            { name: "Ecanto", link: "" },
            { name: "Elegance WHEELS", link: "eleganceWHEELS" },
            { name: "e-motion", link: "e-motion" },
            { name: "Enkei", link: "Enkei" },
            { name: "Enzo", link: "Enzo" },
            { name: "Eta Beta", link: "Eta Beta" },
            { name: "Fondmetal", link: "Fondmetal" },
            { name: "GMP", link: "GMP" },
            { name: "itWheels", link: "itWheels" },
            { name: "Keskin", link: "Keskin" },
            { name: "Königsräder", link: "Königsräder" },
            { name: "Lexani", link: "" },
            { name: "Lexani Forged", link: "" },
            { name: "Lüthen Motorsport", link: "" },
            { name: "MAK", link: "MAK" },
            { name: "MAM", link: "MAM" },
            { name: "mbDESIGN", link: "mbDESIGN" },
            { name: "Meisterwerk", link: "Meisterwerk" },
            { name: "Momo", link: "Momo" },
            { name: "Motec", link: "Motec" },
            { name: "MSW", link: "MSW" },
            { name: "Oxigin", link: "Oxigin" },
            { name: "OXXO", link: "OXXO" },
            { name: "OZ", link: "OZ" },
            { name: "Platin", link: "Platin" },
            { name: "Proline", link: "Proline" },
            { name: "Protrack", link: "" },
            { name: "Raffa Wheels", link: "" },
            { name: "RCDesign", link: "RCDesign" },
            { name: "RH", link: "RH" },
            { name: "Rial", link: "Rial" },
            { name: "Ronal", link: "Ronal" },
            { name: "Rotiform", link: "" },
            { name: "R-Style", link: "R-Style" },
            { name: "Schmidt Revolution", link: "Schmidt Revolution" },
            { name: "Seitronic Wheels", link: "" },
            { name: "Sparco", link: "Sparco" },
            { name: "Speedline Corse", link: "Speedline Corse" },
            { name: "Team Dynamics", link: "Team Dynamics" },
            { name: "TEC Speedwheels", link: "TEC Speedwheels" },
            { name: "Tomason", link: "Tomason" },
            { name: "Ultra Wheels", link: "Ultra Wheels" },
            { name: "Vossen Wheels", link: "http://www.vossenwheels.de" },
            { name: "V1 Wheels", link: "V1 Wheels" },
            { name: "Yido", link: "" },
            { name: "Z-Performance", link: "" }
        ];
        getManufacturerPages(manufacturers.length).then(manufacturerPages => {
            setRimBrands(manufacturers.map(m => {
                const page = manufacturerPages?.find(mp => mp.attributes?.nodeId.toLowerCase() === m.name.toLowerCase());
                let link = m.link;
                if (page && page.attributes.nodeId) {
                    link = "/felgen/" + encodeURIComponent(page.attributes.nodeId);
                } else if (m.link) {
                    if (!m.link.startsWith("http") && !m.link.startsWith("//")) {
                        link = "/felgen/" + encodeURIComponent(m.link);
                    }
                } else {
                    link = "mailto:anfrage@wheelscompany.com";
                }
                return {
                    ...m,
                    link
                };
            }));
        });
    }

    return (
        <div id="rim-brands-wrapper">
            <div className="container-fluid">
                <h4>{t('HOME.RIM_BRANDS.OUR_RIM_BRANDS')}</h4>
                <ul id="rim-brands">
                    {rimBrands.map((rimBrand: any) => (
                        <li className="rim-brand" key={rimBrand.name}>
                            <a title={rimBrand.name}
                               href={rimBrand.link}>
                                {rimBrand.name}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="mt-5">
                    <Link to='/felgen' className="btn btn-primary">{t('HOME.RIM_BRANDS.ALL_RIM_BRANDS')}</Link>
                </div>
            </div>
        </div>
    );
}

export default RimBrands;
