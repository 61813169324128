import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {classList} from "../../../helper/Helper";
import {ConstructionSeriesV3DTO} from "../../../models/car/ConstructionSeriesDTO";
import {CarSelectionInfo} from "../CarSelectionInfo";
import {useLocation, useNavigate} from "react-router-dom";
import ManufacturerSelection from "../../ManufacturerSelection/ManufacturerSelection";
import CarClassSelection from "../../CarClassSelection/CarClassSelection";
import ConstructionSeriesSelection from "../../ConstructionSeriesSelection/ConstructionSeriesSelection";
import CarModelSelection from "../../CarModelSelection/CarModelSelection";
import CarHistorySelect from "../../CarHistorySelect/CarHistorySelect";


export enum CarSelectionState {
    MANUFACTURER = 0,
    CAR_CLASS = 10,
    CONSTRUCTION_SERIES = 20,
    CAR_MODEL_SELECTION = 30
}

interface Props {
    isRimDetailPage: boolean;
    rimId?: string;
    preSelectedManufacturer?: string;
    headline?: string;
    isOnSeoPage?: boolean;
    isExpanded?: boolean;
}

function SelectCarByManualSelection(props: Props) {
    const [t] = useTranslation();
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState(CarSelectionState.MANUFACTURER);
    const steps = [1, 2, 3, 4];
    const [selectedManufacturer, setSelectedManufacturer] = useState<string>('');
    const [selectedCarClass, setSelectedCarClass] = useState<string>('');
    const [selectedConstructionSeries, setSelectedConstructionSeries] = useState<ConstructionSeriesV3DTO>();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    useEffect(() => {
        if(props.preSelectedManufacturer){
            const correctedName = titleCase(props.preSelectedManufacturer);
            setSelectedManufacturer(correctedName);
            setCurrentStep(CarSelectionState.CAR_CLASS)
        }
    }, [props.preSelectedManufacturer]);


    function titleCase(input: string){

        if(input.toLowerCase() == "bmw" || input.toLowerCase() == "vw"){
            return input.toUpperCase();
        }

        return input[0].toUpperCase() + input.slice(1).toLowerCase();
    }

    function selectCarFromHistory(e) {
        const carId = e.value;
        const searchParams = new URLSearchParams(location.search).toString();
        if (props.isRimDetailPage && props.rimId) {
            navigate({
                pathname: '/felgen/details/' + encodeURIComponent(props.rimId) + '/' + encodeURIComponent(carId),
                search: searchParams
            });
        } else {
            navigate({
                pathname: "/felgenkonfigurator/" + encodeURIComponent(carId),
                search: searchParams
            });
        }
    }

    function renderScreenLeft() {
        switch (currentStep) {
            case CarSelectionState.MANUFACTURER:
                return <div><ManufacturerSelection manufacturerSelected={onSelectedManufacturer} isLandingPage={false}/></div>;

            default:
                return <div><CarClassSelection selectedManufacturerId={selectedManufacturer}
                                               carClassSelected={onSelectedCarClass}
                                               goBack={() => setCurrentStep(CarSelectionState.MANUFACTURER)}/></div>;

        }
    }

    function renderScreenRight() {
        switch (currentStep){
            case CarSelectionState.CONSTRUCTION_SERIES:
                return <div><ConstructionSeriesSelection selectedManufacturerId={selectedManufacturer}
                                                  selectedCarClassId={selectedCarClass}
                                                  constructionSeriesSelected={onSelectedConstructionSeries}
                                                  goBack={() => setCurrentStep(CarSelectionState.MANUFACTURER)}/></div>;

            case CarSelectionState.CAR_MODEL_SELECTION:
                return <div><CarModelSelection selectedManufacturerId={selectedManufacturer}
                                          selectedCarClassId={selectedCarClass}
                                          constructionSeries={selectedConstructionSeries}
                                          isManualSelection={true}
                                          goBack={() => setCurrentStep(CarSelectionState.CONSTRUCTION_SERIES)}/></div>

            default:
                return <CarSelectionInfo headline={props.headline}
                                         isOnSeoPage={props.isOnSeoPage}
                                         isRimDetailPage={props.isRimDetailPage}/>;
        }
    }

    function onSelectedManufacturer(manufacturer: string) {
        setSelectedManufacturer(manufacturer);
        setCurrentStep(CarSelectionState.CAR_CLASS);
    }

    function onSelectedCarClass(carClass: string) {
        setSelectedCarClass(carClass);
        setCurrentStep(CarSelectionState.CONSTRUCTION_SERIES);
    }

    function onSelectedConstructionSeries(constructionSeries: ConstructionSeriesV3DTO) {
        setSelectedConstructionSeries(constructionSeries);
        setCurrentStep(CarSelectionState.CAR_MODEL_SELECTION);
    }

    function loadingBoxes() {
        let loadingBoxes = [];
        for (let i = 0; i < 25; i++) {
            loadingBoxes.push(
                <div className={"loading boxitem linkbox "} key={i + "_loading_cards"}>
                    <div className={" loading-animation "}/>
                </div>
            );
        }
    }

    return (
        <div className="manual-selection-wrapper">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 offset-xl-1">
                    <button className="btn btn-primary btn-collapsible w-100 d-md-none"
                            data-toggle="collapse" data-bs-target="#manual-selection" aria-expanded={isExpanded}
                            aria-controls="manual-selection" onClick={() => setIsExpanded(!isExpanded)}>
                        <div className="d-flex justify-content-between">
                            <div>{t("CAR_SELECTION.BY_MANUAL.COLLAPSE")}</div>
                            <div><i className="fas fa-chevron-down"/></div>
                        </div>
                    </button>
                    <div id="manual-selection" className={classList({
                        "collapse": true,
                        "bg-milky": !props.isOnSeoPage,
                        "bg-white": props.isOnSeoPage,
                        "show": isExpanded,
                    })}>
                        <div className="p-3">
                            <CarHistorySelect optionSelected={selectCarFromHistory}/>
                            {/*{props.isOnSeoPage ? null :*/}
                            {/*    <div className="car-image">*/}
                            {/*        <img*/}
                            {/*            src={(selectedConstructionSeries && selectedConstructionSeries?.carImage) ?*/}
                            {/*                selectedConstructionSeries?.carImage : "/Fahrzeug_platzhalter.png"}*/}
                            {/*            alt={(selectedConstructionSeries && selectedConstructionSeries.type) ?*/}
                            {/*                selectedConstructionSeries.type : "Fahrzeug.png"}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {renderScreenLeft()}
                        </div>
                    </div>
                </div>
                <div
                    className={`col-12 col-md-6 col-lg-8 col-xl-6 ${currentStep > 10 ? 'bg-milky' : ''}`}>
                    {renderScreenRight()}
                </div>
            </div>
        </div>
    );
}

export default SelectCarByManualSelection;
