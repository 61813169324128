import React from "react";
import {useTranslation} from "react-i18next";
import {RestrictionDetailsDTO} from "../../../../models/rims/CertificateInformationDTO";

interface Props {
    rimRestrictions: RestrictionDetailsDTO[];
}

function RimRestrictions({rimRestrictions}: Props) {
    const [t] = useTranslation();

    return (
        <div className="expandable-information">
            <button className="btn btn-collapsible" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseRimRestrictions" aria-expanded="false"
                    aria-controls="collapseRimRestrictions">
                <div className="d-flex justify-content-between">
                    <div>{t("RIMS.DETAILS.RIM_RESTRICTIONS.TITLE")}</div>
                    <div><i className="fas fa-chevron-down"/></div>
                </div>
            </button>
            <div className="collapse" id="collapseRimRestrictions">
                <div className="content">
                    <table className="table table-striped">
                        <tbody>
                        {rimRestrictions.map((restriction) => (
                            <tr key={restriction.restrictionId}>
                                <td>{restriction.restrictionId}</td>
                                <td>{restriction.restrictionText}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default RimRestrictions;
