import { useState } from "react";
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);
    const loader = loading ? <LoadingOverlay /> : null;
    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    return {
      loader,
      showLoader,
      hideLoader
    };
};

export default useFullPageLoader;
