import { CartDTO, CartItemDTO, PaymentMethod } from "../models/cart/CartDTO";
import { OrderDTO } from "../models/order/OrderDTO";
import { OrderItemDTO } from "../models/order/OrderItemDTO";
import { RimGroupSearchResultDTO } from "../models/rims/RimDetailDTO";
import { TyreDTO } from "../models/tyres/TyreDTO";
import { AnalyticsApi, AnalyticsProduct, AnalyticsPurchaseAction } from "./AnalyticsApi";

export class AnalyticsHelper extends AnalyticsApi {
    private static rimToGAProduct(r: RimGroupSearchResultDTO, index?: number): AnalyticsProduct {
        return {
            item_name: `${r.manufacturer} ${r.name}  ${r.boltPattern}`,
            item_id: r.rimId,
            item_brand: r.manufacturer,
            item_category: "Felge",
            item_variant: r.colourGroup
        };
    }

    private static tyreToGAProduct(r: TyreDTO, index?: number): AnalyticsProduct {
        return {
            item_name: `${r.brandName} ${r.description}`,
            item_id: r.productExternalId,
            item_brand: r.brandName,
            item_category: "Reifen",
            item_variant: r.tread,
            price: r.pricesDTO.minSellInPriceNet
        };
    }

    private static itemToGAProduct(i: CartItemDTO | OrderItemDTO): AnalyticsProduct {
        return {
            item_name: i.description,
            item_id: i.productId,
            item_brand: i.manufacturer,
            item_category: i.itemType,
            price: i.priceGross
        };
    }
    private static cartToGAProducts(cart: CartDTO | OrderDTO): Array<AnalyticsProduct>{
        let items:Array<AnalyticsProduct> = [];
        for (let position of cart?.positions) {
            items = items.concat(
                position.items.map((i) => {
                    return {
                        ...this.itemToGAProduct(i),
                        quantity: position.quantity,
                    };
                })
            );
        }
        return items
    }

    /// Rim tracking
    static trackRimImpressions(rims: Array<RimGroupSearchResultDTO>) {
        super.trackImpressions(rims?.map(this.rimToGAProduct));
    }

    static trackRimClick(rim: RimGroupSearchResultDTO, index?: number) {
        super.trackClick([this.rimToGAProduct(rim)], index);
    }

    static trackRimDetails(rim: any) {
        super.trackDetails([this.rimToGAProduct(rim)]);
    }

    /// Tyre tracking
    static trackTyreImpressions(tyres: Array<TyreDTO>) {
        if (tyres?.length > 0) {
            super.trackImpressions(tyres.map(this.tyreToGAProduct));
        }
    }

    static trackTyreClick(tyre: TyreDTO, index?: number) {
        super.trackClick([this.tyreToGAProduct(tyre)], index);
    }

    static trackTyreDetails(tyre: TyreDTO) {
        super.trackDetails([this.tyreToGAProduct(tyre)]);
    }

    /// Cart
    static trackAddItemsToCart(items: Array<CartItemDTO>, quantity: number) {
        super.trackAddToCart(
            items.map((i) => {
                return { ...this.itemToGAProduct(i), quantity };
            })
        );
    }

    static trackRemoveItemsFromCart(items: Array<CartItemDTO>, quantity: number) {
        super.trackRemoveFromCart(
            items.map((i) => {
                return { ...this.itemToGAProduct(i), quantity };
            })
        );
    }

    static trackPurchaseByOrder(order: OrderDTO) {
        const items = this.cartToGAProducts(order)
        const action: AnalyticsPurchaseAction = {
            currency: 'EUR',
            transaction_id: order.id + '',
            value: order.sumGross,
            tax: order.vat,
            shipping: order.shipping,
            coupon: order.voucherCode,
            items: items
        }
        super.trackPurchase(action);
    }

    static trackBeginCheckoutCart(cart: CartDTO){
        const items = this.cartToGAProducts(cart)
        super.trackBeginCheckout(items, cart.voucherCode);
    }

    static trackPaymentMethod(paymentMethod: PaymentMethod, cart: CartDTO) {
        const items = this.cartToGAProducts(cart)
        super.trackPaymentInfo(paymentMethod, items)
    }
}
