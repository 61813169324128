import React, {useEffect, useState} from "react";
import {getCarHistory} from "../../redux-store/api/CarApi";
import {ReactSelectOption} from "../../models/shared/ReactSelectOption";
import Select from "react-select";
import {getReactSelectStyles} from "../../helper/Helper";
import {useTranslation} from "react-i18next";


interface Props {
    optionSelected: Function;
}
function CarHistorySelect(props: Props) {
    const [carHistoryOptions, setCarHistoryOptions] = useState<ReactSelectOption[]>([]);
    const [t] = useTranslation();

    useEffect(() => {
        loadCarHistory();
    }, []);

    function loadCarHistory() {
        getCarHistory().then((res) => {
            const options = res?.map((car) => {
                const pitchCircle = car.pitchCircle?.replace(/\s+/g, "");
                return {
                    value: car.carId + "_" + pitchCircle,
                    label: car.tradeName + " (" + car.hsn + "/" + car.tsn + ")",
                };
            });
            setCarHistoryOptions(options);
        });
    }

    return (
        <>
            {carHistoryOptions && carHistoryOptions.length > 0 &&
                <div className="mt-1 mb-3">
                    <Select
                        options={carHistoryOptions}
                        placeholder={t("CAR_SELECTION.INPUT.LAST_CARS")}
                        styles={getReactSelectStyles()}
                        onChange={(e) => props.optionSelected(e)}
                    />
                </div>
            }</>
    )
}

export default CarHistorySelect;