import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import { usePrevious } from "../../helper/usePrevious";
import {Layer} from "../interfaces/rim-config.interface";

interface BmfImageProps {
    layer: Layer;
    index: number;
    image: string;
    currentImage: number;
    imageLoaded?: (image: string) => void;
    imageLoading?: (image: string) => void;
    rimLayerPrefix: string;
    defaultLayerPrefix: string;
    carColor: string;
    lowering?: number;
    smallImage?: boolean;
}

export function BmfImage(props: BmfImageProps) {
    const {image, index, layer, imageLoaded, imageLoading, currentImage, carColor, lowering} = props;
    const previousImage = usePrevious(image);
    const [loadingSent, setLoadingSent] = useState(false);
    const prefix = layer.isRimLayer ? props.rimLayerPrefix : props.defaultLayerPrefix;
    const loweredOffset = layer.canBeLowered ? lowering || 0 : 0;
    let src = prefix + image;
    if (layer.canBeColored && carColor) {
        const paths: string[] = src.split("/");
        const ucPaths: string[] = src.toUpperCase().split("/");
        paths[ucPaths.indexOf('CARPAINT') + 1] = carColor;
        src = paths.join('/');
    }

    function sendImageLoading(){
        if (image && !loadingSent) {
            setLoadingSent(true)
            imageLoading(image);
        }
    }

    useEffect(() => {
        if (previousImage && previousImage !== image) {
            setLoadingSent(false)
            imageLoaded(previousImage)
        }
        sendImageLoading();
    }, [image]);

    // this is needed to send that loading is required before the img tag can trigger the loaded event from a cached image
    sendImageLoading();



    return (
        <img
            height={!props.smallImage ? props.layer.dimensions[index].height : props.layer.dimensions[index].height * 0.5}
            width={!props.smallImage ? props.layer.dimensions[index].width : props.layer.dimensions[index].width * 0.5}
            style={{
                display: index === currentImage ? "block" : "none",
                zIndex: layer.zIndex,
                position: "absolute",
                left: layer.positions[index].x + "px",
                top: (layer.positions[index].y + loweredOffset) + "px",
                transition: layer.canBeLowered ? 'top 2s ease 0s' : ''
            }}
            key={"layer.index" + index}
            onLoad={() => imageLoaded(image)}
            onError={() => imageLoaded(image)}
            src={src}
        />
    )
}
