import ReactDom from "react-dom";
import { LoadingAnimation } from "../LoadingAnimation/LoadingAnimation";
import Filter from "./Filter";
import React from "react";
import { FilterInterface } from "../../models/shared/FilterInterface";

interface FilterModalProps {
    rimsLoading?: boolean;
    filterSearchState: any[];
    filterSelectionCallback: (filters: FilterInterface[]) => void;
    onClose: Function;
}
export default function FilterModal(props: FilterModalProps) {
    return ReactDom.createPortal(
        <>
            <div className={"filter-modal__backdrop"} onClick={() => props.onClose()}>
                <div className={"filter-modal__dialog"} onClick={(evt) => evt.stopPropagation()}>
                    <div className={"d-flex"}>
                        <LoadingAnimation isLoading={props.rimsLoading}>
                            <Filter
                                filters={props.filterSearchState}
                                filterSelectionCallback={props.filterSelectionCallback}
                                side
                                disableInstantSearch={true}
                                disableReload={true}
                                onFilterActivate={props.onClose}
                            />
                        </LoadingAnimation>
                        <button
                            className={"btn-close"}
                            type="button"
                            onClick={() => props.onClose()}
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
            </div>
        </>,
        document.getElementById("portal"),
    );
}
