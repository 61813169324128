import React from "react";

interface Props {
    isLoading: boolean,
    children: any,
    style?: any;
}

export function LoadingAnimation(props: Props) {
    if (props.isLoading) {
        return <div className="loading" style={props.style}/>;
    }
    return props.children;
}
