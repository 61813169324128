import { ManufacturerDTO } from "../../models/car/ManufacturerDTO";
import { StrapiCategoryPage } from "../../models/strapi/StrapiCategoryPage";

export enum RimBrandActionType {
    SET_DATA = 'RimBrandActionType [SET_DATA]',
}

export class RimBrandAction {
    constructor(
        public type: RimBrandActionType,
        public payload: StrapiCategoryPage
    ) {}
}

export const rimBrandReducer = (state: StrapiCategoryPage = {} as any, {type, payload}: RimBrandAction) => {
    switch (type) {
        case RimBrandActionType.SET_DATA:
            return {
                ...payload
            }
        default:
            return state;
    }
}
