import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import {deleteVoucher, redeemVoucher} from "../../../../redux-store/api/ShoppingCartApi";
import {CartDTO} from "../../../../models/cart/CartDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";
import {ShoppingCartContext} from "../../ShoppingCart";

interface Props {
    updateCart: (cart:CartDTO) => void;
    cart: CartDTO;
    isCartView?: boolean;
}

export default function RedeemVoucher({updateCart, cart, isCartView}: Props) {
    const [t] = useTranslation();
    const [voucherError, setVoucherError] = useState(false);
    const [voucherAccepted, setVoucherAccepted] = useState(false);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const {changeLoadingCounter} = useContext(ShoppingCartContext);

    function submitForm(values: FormikValues) {
        changeLoadingCounter(true);
        redeemVoucher(values.voucherCode)
            .then((res) => {
                updateCart(res);
                if (res.voucherWarning) {
                    setVoucherError(true);
                    setVoucherAccepted(false);
                } else {
                    setVoucherError(false);
                    setVoucherAccepted(true);
                }
            })
            .catch(err => {
                setVoucherError(true);
                setVoucherAccepted(false);
            }).finally(() => {
            changeLoadingCounter(false);
        });
    }

    function removeVoucherFromCart() {
        changeLoadingCounter(true);
        deleteVoucher()
            .then(res => {
                updateCart(res);
                setVoucherAccepted(false);
            })
            .catch(err => {
                console.error(err);
            }).finally(() => {
            changeLoadingCounter(false);
        });
    }

    return (
        <div className="redeem-voucher">
            {((isSmallScreen && !isCartView) || (!isSmallScreen)) &&
                <div className="heading">{t('CART.VOUCHER')}</div>
            }
            {!cart?.voucherCode &&
                <Formik
                    initialValues={{
                        voucherCode: ""
                    }}
                    onSubmit={submitForm}
                >
                    {(props: FormikProps<any>) => (
                        <Form>
                            <div className="input-group">
                                <Field autoComplete="off"
                                       className="form-control"
                                       type="text"
                                       name="voucherCode"
                                       placeholder={t("CART.VOUCHER_CODE")}
                                       required
                                />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-outline-secondary">
                                        {t("CART.REDEEM_VOUCHER")}
                                    </button>
                                </div>
                            </div>
                            {voucherError &&
                                <div className="d-flex justify-content-end voucher-error">
                                    <div className="text-danger mt-2">{t('CART.VOUCHER_ERROR')}</div>
                                </div>
                            }
                        </Form>
                    )}
                </Formik>
            }
            {cart?.voucherCode &&
                <>
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="input-group">
                            <input
                                className="form-control"
                                type="text"
                                name="voucherCode"
                                value={cart.voucherCode}
                                placeholder={t("CART.VOUCHER_CODE")}
                                disabled
                            />
                            <div className="input-group-append">
                                <button type="button"
                                        onClick={() => removeVoucherFromCart()}
                                        className="btn btn-outline-primary"
                                >
                                    {t("CART.DELETE_VOUCHER")}
                                </button>
                            </div>
                        </div>
                    </div>
                    {voucherAccepted &&
                        <div className="d-flex justify-content-end">
                            <div className="text-green mt-2">{t('CART.VOUCHER_ACCEPTED')}</div>
                        </div>
                    }
                    {cart?.voucherWarning &&
                        <div className="d-flex justify-content-end voucher-error">
                            <div className="text-danger mt-2">{t('CART.VOUCHER_ERROR')}</div>
                        </div>
                    }
                </>
            }
        </div>
    );
}
