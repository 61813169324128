import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { InputType } from "../../models/shared/Types";
import ColorSelection from "../ColorSelection/ColorSelection";
import RimSizeSelection from "../RimSizeSelection/RimSizeSelection";
import { InputCheckbox } from "./InputCheckbox";
import { InputHelper } from "./InputHelper";
import { InputSelect } from "./InputSelect";
import { InputSlider } from "./InputSlider";

export interface InputInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value?: string[];
    defaultValue?: any[];
    label?: string;
    onChange?: InputChanged;
    options?: string[];
    type: InputType;
    translateValue?: boolean;
    translatePrefix?: string;
    formatNumber?: boolean;
}
export type InputChanged = (newValues: string[]) => void;

interface Props extends InputInterface, WithTranslation {
    labelClass?: string;
    hideLabel?: boolean;
    hideClear?: boolean;
}

interface State {}

class Input extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getInputByType() {
        switch (this.props.type) {
            case "SINGLE_SELECT_DROPDOWN":
                return <InputSelect isMulti={false} {...this.props} />;
            case "MULTI_SELECT_DROPDOWN":
                if (this.props.name == "COLOR_GROUP") {
                    return (
                        <ColorSelection
                            containerClasses={""}
                            objectClasses={"m-1"}
                            multiSelect={true}
                            onSelect={this.props.onChange}
                            colors={this.props.options}
                            values={this.props.value}
                        />
                    );
                }
                return <InputSelect isMulti={true} {...this.props} />;
            case "MULTI_SLIDER":
            case "SINGLE_SLIDER":
                const min = Number(this.props.options[0]);
                const max = Number(this.props.options[1]);
                const defaultMin = Number(this.props.value?.length > 0 ? this.props.value[0] : this.props.options[0]);
                const defaultMax = Number(this.props.value?.length > 0 ? this.props.value[1] : this.props.options[1]);
                return (
                    <InputSlider
                        range={this.props.type === "MULTI_SLIDER"}
                        name={this.props.name}
                        min={min}
                        max={max}
                        defaultMin={defaultMin}
                        defaultMax={defaultMax}
                        onChange={this.props.onChange}
                    />
                );
            case "CHECKBOX":
                if (this.props.name === "SIZE") {
                    return (
                        <RimSizeSelection
                            values={this.props.value}
                            options={this.props.options}
                            containerClasses={"row justify-content-start p-3"}
                            objectClasses={"col-1 m-1"}
                            multiSelect={true}
                            onSelect={this.props.onChange}
                        />
                    );
                }

                if (this.props.options.length === 1) {
                    // SINGLE_CHECKBOX
                    return (
                        <InputCheckbox
                            option={this.props.options[0]}
                            name={this.props.name}
                            onChange={this.props.onChange}
                            value={this.props.value}
                        />
                    );
                }
                // MULTI_CHECKBOX
                return <InputSelect isMulti={true} {...this.props} />;
            case "RADIO_BUTTON":
                return (
                    <>
                        {this.props.options?.map((opt, index) => (
                            <div className="form-check" key={this.props.name + '_' + index} >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={this.props.name + '_' + index}
                                    id={this.props.name + index}
                                    value={opt}
                                    onChange={(evt) => this.props.onChange([evt.currentTarget.value])}
                                    checked={this.props.value?.includes(opt) || false}
                                />
                                <label className="form-check-label" htmlFor={this.props.name + '_' + index} onClick={() => this.props.onChange([opt])}>
                                    {InputHelper.filterLabel(opt, this.props.name, this.props.t, this.props.translateValue, this.props.translatePrefix)}
                                </label>
                            </div>
                        ))}
                    </>
                );
            default:
                const { defaultValue, translateValue, formatNumber, t, tReady, onChange, ...cleanProps } = this.props;
                return <input type="text" onChange={(evt) => onChange([evt.currentTarget.value])} {...cleanProps} />;
        }
    }

    render() {
        return (
            <FormGroup controlId={this.props.name}>
                {this.props.label && (
                    <FormLabel
                        className={classNames(this.props.labelClass, "text-uppercase", {
                            "label-not-visible": (this.props.type === "CHECKBOX" && this.props.options.length === 1) || this.props.hideLabel,
                        })}
                    >
                        {this.props.label}
                    </FormLabel>
                )}
                {this.getInputByType()}
            </FormGroup>
        );
    }
}

export default withTranslation()(Input);
