import { flattenObj } from "../../helper/Helper";
import axiosInstance from "./AxiosInstance";

export function logToServer(error: ErrorDTO) {
    const customParameters = flattenObj(error?.customParameters);
    axiosInstance
        .post(`/api/logging`, {
            ...error,
            customParameters,
        })
        .then(() => {})
        .catch(() => {});
}

export interface ErrorDTO {
    logLevel?: LogLevel;
    dateTime?: Date;
    name: string;
    message?: string;
    stack?: string;
    customParameters?: any;
}

export enum LogLevel {
    DEBUG = "DEBUG",
    ERROR = "ERROR",
    FATAL = "FATAL",
    INFO = "INFO",
    OFF = "OFF",
    TRACE = "TRACE",
    WARN = "WARN",
}
