import { InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getReactSelectStyles } from "../../../../helper/Helper";
import { RimTyreAssignmentDTO } from "../../../../models/rims/RimTyreAssignmentDTO";
import { ReactSelectOption } from "../../../../models/shared/ReactSelectOption";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value" | "defaultValue"> {
    onChange: (newValue: ReactSelectOption<RimTyreAssignmentDTO>) => void;
    options: RimTyreAssignmentDTO[],
    defaultValue?: RimTyreAssignmentDTO[]
    value: RimTyreAssignmentDTO
}
export function TyreSizeSelect(props: Props) {
    const [t] = useTranslation();
    function optionsToSelectOptions(
        options: Array<RimTyreAssignmentDTO>,
    ): ReactSelectOption<RimTyreAssignmentDTO>[] {
        return options?.map((option) => optionToSelectOption(option));
    }
    function optionToSelectOption(option: RimTyreAssignmentDTO): ReactSelectOption<RimTyreAssignmentDTO> {
        if(option) {
            return {
                value: option,
                label: `VA: ${option.front?.description} - HA: ${option.rear?.description ? option.rear.description : option.front?.description
                }`
            };
        }
    }

    return (
        <Select
            name={props.name}
            options={optionsToSelectOptions(props.options)}
            defaultValue={optionsToSelectOptions(props.defaultValue)}
            value={optionToSelectOption(props.value)}
            onChange={props.onChange}
            placeholder={t(props.placeholder ? props.placeholder : "INPUT.PLACEHOLDER.SELECT")}
            isDisabled={props.readOnly}
            isClearable={!props.required}
            styles={getReactSelectStyles(1000)}
        />
    );
}
