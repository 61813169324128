import { getCartPreview } from "../api/ShoppingCartApi";

export const updateCartPreview = () => dispatch => {
    return getCartPreview().then(res => {
        dispatch({
            type: "PREVIEW_UPDATE",
            payload: res
        });
    }).catch(e => {
        dispatch({
            type: "PREVIEW_ERROR",
            payload: console.log(e)
        });
    });
}
