import React from "react";
import {NumericFormat} from 'react-number-format';
import {useTranslation} from "react-i18next";
import RecommendedRetailPrice from "../RecommendedRetailPrice/RecommendedRetailPrice";
import {StockInfo} from "../StockInfo/StockInfo";
import {RimDetailDTO} from "../../models/rims/RimDetailDTO";
import {RimSizeAssignmentDTO} from "../../models/rims/RimSizeAssignmentDTO";
import RatePayInfo from "../RatePayInfo/RatePayInfo";

interface Props {
    selectedRimSize?: RimSizeAssignmentDTO;
    rimDetails?: RimDetailDTO;
    amount: number;
    onlyPrice?: boolean;
}

function RimPrice({selectedRimSize, rimDetails, amount, onlyPrice}: Props) {
    const [t] = useTranslation();

    enum WheelType {
        FRONT,
        REAR,
        ALL
    }



    const rearMinSellNetPrice = rimDetails?.rimRear?.priceAndStock?.price?.minSellInPriceNet;
    const rearRecommendedPrice = rimDetails?.rimRear?.priceAndStock?.price?.recommendedRetailPrice;

    const frontMinSellNetPrice = rimDetails?.rimFront?.priceAndStock?.price?.minSellInPriceNet;
    const frontRecommendedPrice = rimDetails?.rimFront?.priceAndStock?.price?.recommendedRetailPrice;


    if (rimDetails?.rimFront?.priceAndStock == null) {
        return <>
            <div className={"fw-bold text-end"}>{t("RIMS.DETAILS.NOT_AVAILABLE")}</div>
            {!onlyPrice && <StockInfo stockColor={selectedRimSize?.rear?.stocksColor}/>}
        </>
    }

    function getPrice(value: number) {

        if(selectedRimSize?.rear) {
            return <>
                {onlyPrice && <span className={"small text-dark"}>{t("RIMS.SEARCH.STARTING_AT")}&nbsp;</span>}
                <NumericFormat
                    value={value * amount * 0.5}
                    displayType="text"
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix="€"
                />
            </>
        }

        return <>
            {onlyPrice && <span className={"small text-dark"}>{t("RIMS.SEARCH.STARTING_AT")}&nbsp;</span>}
            <NumericFormat
                value={value * amount}
                displayType="text"
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="€"
            />
        </>
    }

    function getImageSrc(wheelType: WheelType): string{
        switch (wheelType){
            case WheelType.FRONT:
                return "car_front_wheel.svg";
            case WheelType.REAR:
                return "car_rear_wheel.svg";
            case WheelType.ALL:
                return "car_front_rear_wheels.svg";

        }
    }

    function getImage(wheelType: WheelType) {
        const src = getImageSrc(wheelType);
        return <img src={`/${src}`} className="axis small me-2" alt={`${src}`}/>
    }

    function getRecommendedRetailPrice(price: number, recommendedRetailPrice: number){
        return <RecommendedRetailPrice
                    price={price}
                    recommendedRetailPrice={recommendedRetailPrice}
                    hideIfEmpty={true}
                />
    }

    if (onlyPrice) {
        return <>
            {selectedRimSize?.rear
                ?  <>
                        <div className="mb-3">
                            {getRecommendedRetailPrice(frontMinSellNetPrice * amount * 0.5, frontRecommendedPrice * amount * 0.5)}
                            <div className="d-flex justify-content-end align-items-baseline">
                                {getImage(WheelType.FRONT)}
                                <div className="price">
                                    {getPrice(frontMinSellNetPrice)}
                                </div>
                            </div>
                            <div className="caption">
                                <span>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: amount * 0.5})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</span>
                            </div>
                        </div>
                        <div>
                            {getRecommendedRetailPrice(rearMinSellNetPrice * amount * 0.5, rearRecommendedPrice * amount * 0.5)}
                            <div className="d-flex justify-content-end align-items-baseline">
                                {getImage(WheelType.REAR)}
                                <div className="price">
                                    {getPrice(rearMinSellNetPrice)}
                                </div>
                            </div>
                            <div className="caption">
                                <div>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: amount * 0.5})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</div>
                            </div>
                        </div>
                   </>
                :  <>
                        <span className="d-flex justify-content-end align-items-baseline">
                            {getRecommendedRetailPrice(frontMinSellNetPrice * amount, frontRecommendedPrice * amount)}
                            {getImage(WheelType.ALL)}
                           <h5 className="price m-0">
                               {getPrice(frontMinSellNetPrice)}
                           </h5>
                        </span>
                        <div className={"text-end"}>
                            <span>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount})}</span>
                            <span> {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</span>
                        </div>
                    </>
            }
        </>

    }

    return (
        <div className="rim-price-and-stock">
            {selectedRimSize?.rear ? (
                <>
                    <div className="mb-3">
                        {getRecommendedRetailPrice(frontMinSellNetPrice * amount * 0.5, frontRecommendedPrice * amount * 0.5)}
                        <div className="d-flex justify-content-end align-items-baseline">
                            {getImage(WheelType.FRONT)}
                            <div className="price">
                                {getPrice(frontMinSellNetPrice)}
                            </div>
                        </div>
                        <div className="caption">
                            <div>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: amount * 0.5})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</div>
                            <div>{t("RIMS.DETAILS.FREE_SHIPPING_DE")}</div>

                            <StockInfo stockColor={selectedRimSize?.front?.stocksColor}/>
                            <RatePayInfo/>
                        </div>
                    </div>
                    <div>
                        {getRecommendedRetailPrice(rearMinSellNetPrice * amount * 0.5, rearMinSellNetPrice * amount * 0.5)}
                        <div className="d-flex justify-content-end align-items-baseline">
                            {getImage(WheelType.REAR)}
                            <div className="price">
                                {getPrice(rearMinSellNetPrice)}
                            </div>
                        </div>
                        <div className="caption">
                            <div>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: amount * 0.5})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</div>
                            <div>{t("RIMS.DETAILS.FREE_SHIPPING_DE")}</div>
                            <StockInfo stockColor={selectedRimSize?.rear?.stocksColor}/>
                            <RatePayInfo/>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {getRecommendedRetailPrice(frontMinSellNetPrice * amount, frontMinSellNetPrice * amount)}
                    <div className="d-flex justify-content-end align-items-baseline">
                        {getImage(WheelType.ALL)}
                        <div className="price">
                            {getPrice(frontMinSellNetPrice)}
                        </div>
                    </div>
                    <div className="caption">
                        <div>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</div>
                       <div>{t("RIMS.DETAILS.FREE_SHIPPING_DE")}</div>
                        <StockInfo stockColor={selectedRimSize?.front?.stocksColor}
                            stock={rimDetails?.rimFront?.priceAndStock?.quantity}
                        />
                        <RatePayInfo/>
                    </div>
                </>
            )}
        </div>
    );
}

export default RimPrice;
