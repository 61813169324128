import { createSlice } from '@reduxjs/toolkit'

export const wishlistCountSlice = createSlice({
    name: 'wishlistCount',
    initialState: {
        value: 0,
    },
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        update: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, update } = wishlistCountSlice.actions

export const selectWishlistCount = (state) => state.wishlistCount.value

export default wishlistCountSlice.reducer
