import React from "react";
import {useTranslation} from "react-i18next";
import { PaymentMethod } from "../../../models/cart/CartDTO";

interface Props {
    close: Function;
    paymentLink: string;
    paymentMethod: PaymentMethod
}

export function PaymentLinkModal(props: Props) {
    const {t} = useTranslation();

    return (
        <div className="custom-modal tpms-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="custom-modal-title">{t("PAYMENT.ALMOST_DONE")}</h5>
                    <button type="button" className="btn-close" onClick={()=> props.close()} aria-label="Close">
                    </button>
                </div>
                <div className="modal-body text-center">
                    <a href={props.paymentLink} className="btn btn-primary">{t("PAYMENT.PROCEED_TO")} {t("ENUMS.PAYMENT_METHODS."+props.paymentMethod)}</a>
                </div>
            </div>
        </div>
    );
}

