import {CarImageDTO} from "../../models/car/CarImageDTO";
import axiosInstance from "./AxiosInstance";
import {CarHistoryEntryDTO} from "../../models/car/CarHistoryEntryDTO";
import {ManufacturerDTO} from "../../models/car/ManufacturerDTO";
import {TpmsCheckDTO} from "../../models/car/TpmsCheckDTO";
import {CarDetailDTO} from "../../models/car/CarDetailDTO";
import {CarModelsV3ResponseDTO} from "../../models/car/CarModelV2DTO";
import {
    ConstructionSeriesV3DTO,
    ConstructionSeriesV3ResponseDTO
} from "../../models/car/ConstructionSeriesDTO";
import {SelectCarByHsnTsnSearchRequestDTO} from "../../components/CarSelection/SelectCarByHsnTsn/SelectCarByHsnTsn";

export function getManufacturers() {
    return axiosInstance.get<ManufacturerDTO[]>("/api/car/manufacturer").then(res => res.data);
}

export function getCarClassesV3(manufacturerId: string) {
    return axiosInstance.get<string[]>(`/api/car/classes/v3?manufacturer=${manufacturerId}`).then(res => res.data);
}

export function getConstructionSeriesV3(carClass: string, manufacturer: string) {
    return axiosInstance.get<ConstructionSeriesV3ResponseDTO>(`/api/car/constructionSeries/v3?manufacturer=${manufacturer}&modelId=${encodeURIComponent(carClass)}`)
        .then(res => res.data);
}

export function getCarModelsV3(reqBody: ConstructionSeriesV3DTO
    | Partial<SelectCarByHsnTsnSearchRequestDTO>) {
    let carBodyId;
    let hsn;
    let tsn;
    if ("carBodyId" in reqBody) {
        carBodyId = reqBody.carBodyId;
    }
    if ("hsn" in reqBody) {
        hsn = reqBody.hsn;
    }
    if ("tsn" in reqBody) {
        tsn = reqBody.tsn;
        if (tsn.length > 4) {
            tsn = tsn.substring(0, 3);
        }
    }

    let path = "";
    if (hsn && tsn) {
        path = `/api/car/model/hsntsn/v3?hsn=${hsn}&tsn=${tsn}`;
    } else {
        path = `/api/car/models/getModelsByCarBodyId/v3?carBodyId=${carBodyId}`;
    }
    return axiosInstance.get<CarModelsV3ResponseDTO>(`${path}`)
        .then(res => res.data.models)
        .then(res => {
            const uniqueIds = [];

            return res.filter(element => {
                const isDuplicate = uniqueIds.includes(element.id);

                if (!isDuplicate) {
                    uniqueIds.push(element.id);

                    return true;
                }
            });
        });
}

export function getCarHistory() {
    return axiosInstance.get<CarHistoryEntryDTO[]>("/api/car/history").then(res => res.data);
}

export function getCarDetails(carId: string) {
    carId = carId?.split("_")[0];
    return axiosInstance.get<CarDetailDTO>(`/api/car/detail/id?id=${carId}`).then(res => res.data);
}

export function getCarImage(carId: string, rimId: string) {
    carId = carId?.split("_")[0];
    return axiosInstance.get<CarImageDTO>(`/api/image/car/${carId}`).then(res => res.data);
}

export function checkForTpms(carId: string) {
    const id = carId?.split("_")[0];
    return axiosInstance.get<TpmsCheckDTO>(`/api/car/tpms/id?carId=${id}`)
        .then(res => res.data);
}
