import React from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {CompleteWheels} from "./CompleteWheels/CompleteWheels";
import Service from "./Service/Service";
import Info from "./Info/Info";
import BrandPictures from "./BrandPictures/BrandPictures";
import Offer from "./Offer/Offer";
import SeoText from "./SeoText/SeoText";
import Approach from "./Approach/Approach";
import RimBrands from "./RimBrands/RimBrands";
import CarSelection from "../../components/CarSelection/CarSelection";
import {useMediaQuery} from "../../helper/useMediaQuery";
import CarSelectionMobile from "../../components/CarSelectionMobile/CarSelectionMobile";

function Home() {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    return (
        <>
            <Helmet>
                <title>{t('HOME.HTML_TITLE')}</title>
                <meta name="description" content={t('HOME.HTML_META_DESCRIPTION')}/>
            </Helmet>
            <div className="home-wrapper">
            {!isSmallScreen &&
                    <CarSelection isRimDetailPage={false} isOnSeoPage={false}/>
                }
                {isSmallScreen &&
                    <CarSelectionMobile isRimDetailPage={false} isLandingPage={true} isOnSeoPage={false}/>
                }
                <Offer/>
                <Info/>
                <Service/>
                <Approach/>
                <BrandPictures/>
                <SeoText/>
                <RimBrands/>
                <CompleteWheels/>
            </div>
        </>
    );
}

export default Home;
