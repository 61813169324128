import React from "react";
import SvgIcon, {SVG_ICONS} from "../SvgIcon/SvgIcon";

interface SeasonIconProps {
    season: string;
    size: string;
    margin?: string
    className?: string
}

function SeasonIcon(props: SeasonIconProps) {

    switch (props.season) {
        case "WINTER":
            return (
                <SvgIcon
                    icon={SVG_ICONS.SNOWFLAKE}
                    color="#4EACD6"
                    size={props.size}
                    className={props.className}
                    margin={props.margin}
                />
            );
        case "SUMMER":
            return (
                <SvgIcon icon={SVG_ICONS.SUN}
                         color="#E19A00"
                         size={props.size}
                         className={props.className}
                         margin={props.margin}
                />
            );
        default:
            return (
                <SvgIcon
                    icon={SVG_ICONS.ALL_SEASON}
                    color="#E19A00"
                    secondColor="#4EACD6"
                    size={props.size}
                    className={props.className}
                    margin={props.margin}
                />
            );
    }
}

export default SeasonIcon;
