import { PaymentMethod } from "../../models/cart/CartDTO";
import { OrderResultDTO } from "../../models/checkout/OrderResultDTO";
import axiosInstance from "./AxiosInstance";
import {OrderDTO} from "../../models/order/OrderDTO";
import {OrderHistoryDTO} from "../../models/order/OrderHistoryDTO";
import {OrderMetaDataDTO} from "../../models/checkout/OrderMetaDataDTO";

export function sendOrder() {
    return axiosInstance.post<OrderResultDTO>(`/api/order`, {})
        .then(res => res.data);
}

export function updateOrderAddress(orderId: number, data: OrderMetaDataDTO) {
    return axiosInstance.put<OrderResultDTO>(`/api/order/customerdata/${orderId}`, data)
        .then(res => res.data);
}

export function clearCart() {
    return axiosInstance.post<OrderResultDTO>(`/api/cart/clear`, {})
        .then(res => res.data);
}

export function updateOrderPayment(orderId: number, paymentMethod: PaymentMethod) {
    return axiosInstance
        .put<OrderResultDTO>(
            `${process.env.HOST || ""}/api/order/updateOrderPaymentMethod/${orderId}`,
            {},
            { params: { paymentMethod } }
        )
        .then((res) => res.data);
}

export function getOrderHistory() {
    return axiosInstance.get<OrderHistoryDTO[]>("/api/order")
        .then(res => res.data);
}

export function getOrderDetails(id: number) {
    return axiosInstance.get<OrderDTO>(`/api/order/${id}/orderdetails`)
        .then(res => res.data);
}
