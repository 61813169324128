import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {ErrorMessage, Field, Form, Formik, FormikProps, FormikValues} from "formik";
import {getFormFieldValidationStyles} from "../../../helper/formikHelper";
import {changePassword} from "../../../redux-store/api/UserApi";
import {ChangePasswordRequestDTO} from "../../../models/shared/ChangePasswordRequestDTO";

function Password() {
    const [t] = useTranslation();
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [error, setError] = useState(false);
    const validationSchema = yup.object().shape({
        oldPassword: yup.string()
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        newPassword: yup.string().min(8, t("FORMS.VALIDATIONS.MIN_PASSWORD_LENGTH"))
            .matches(/(?=.*[0-9])/, {message: t("FORMS.VALIDATIONS.NO_VALID_PASSWORD"), excludeEmptyString: true})
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        confirmNewPassword: yup.string().oneOf([yup.ref("newPassword"), ""], t("FORMS.VALIDATIONS.PASSWORDS_MUST_MATCH"))
            .required(t("FORMS.VALIDATIONS.REQUIRED"))
    });

    function submitForm(values: FormikValues) {
        const newPasswordRequest: ChangePasswordRequestDTO = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
        };
        changePassword(newPasswordRequest).then((res) => {
            setPasswordSuccess(true);
        }).catch(err => {
            console.error("ERROR in changing password");
            setError(true);
        });
    }

    return (
        <div className="change-password-wrapper">
            <Helmet>
                <title>{t("SETTINGS.PASSWORD.CHANGE_PASSWORD")}</title>
            </Helmet>
            <div className="container">
                <h1 className="text-dark pb-4"><i>{t("SETTINGS.PASSWORD.CHANGE_PASSWORD")}</i></h1>
                <p>
                    <Link to="/einstellungen">
                        <i className="fas fa-long-arrow-alt-left me-1"/> {t("SETTINGS.BACK")}
                    </Link>
                </p>
                <div className="change-password-box">
                    {passwordSuccess &&
                    <div className="message">
                        <i className="fas fa-check me-3"/>{t("SETTINGS.PASSWORD.PASSWORD_CHANGED")}
                    </div>
                    }
                    {error &&
                    <div className="message">
                        {t("SETTINGS.PASSWORD.ERROR_CHANGING_PASSWORD")}
                    </div>
                    }
                    {!passwordSuccess && !error &&
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            confirmNewPassword: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={submitForm}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="container form-container">
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            <Field
                                                autoComplete="off"
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "oldPassword")}
                                                type="password"
                                                name="oldPassword"
                                                placeholder={t("SETTINGS.PASSWORD.OLD_PASSWORD")}
                                            />
                                            <ErrorMessage name="oldPassword"
                                                          render={msg => (<div
                                                              className="invalid-feedback">{msg}
                                                          </div>)}/>
                                        </div>
                                        <div className="col-12 my-2">
                                            <Field
                                                autoComplete="off"
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "newPassword")}
                                                type="password"
                                                name="newPassword"
                                                placeholder={t("SETTINGS.PASSWORD.NEW_PASSWORD")}
                                            />
                                            <ErrorMessage name="newPassword"
                                                          render={msg => (<div
                                                              className="invalid-feedback">{msg}
                                                          </div>)}/>
                                        </div>
                                        <div className="col-12 my-2">
                                            <Field
                                                autoComplete="off"
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "confirmNewPassword")}
                                                type="password"
                                                placeholder={t("SETTINGS.PASSWORD.OLD_PASSWORD_REPEAT")}
                                                name="confirmNewPassword"
                                            />
                                            <ErrorMessage name="confirmNewPassword"
                                                          render={msg => (<div
                                                              className="invalid-feedback">{msg}
                                                          </div>)}/>
                                        </div>
                                    </div>
                                    <div className="text-end mt-3">
                                        <button type="submit" className="btn btn-primary">
                                            {t("SETTINGS.PASSWORD.SAVE")}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    }
                </div>
            </div>
        </div>
    );
}

export default Password;
