import CarViewConfigPanel from "./carViewConfigOptions/CarViewConfigPanel";
import React, {useState} from "react";
import {CarViewRimPreview} from "./CarViewRimPreview";
import {RimConfigComponent} from "../../bmf3dImages/components/rim-config.component";
import CustomModal from "../CustomModal/CustomModal";
import CarDetailsModal from "../CarDetailsModal/CarDetailsModal";
import {SubCarViewProps} from "./CarView";
import ReactDom from "react-dom";
import MobileCarViewModal from "./MobileCarViewModal";


export default function MobileLandscapeCarView(props: SubCarViewProps) {

    const [isButtonBarExpanded, setIsButtonBarExpanded] = useState<boolean>(false);

    return (
        <>
            {/*/!*DEBUG INFO*!/*/}
            {/*{"MOBILE: "}*/}
            {/*{orientation.type} {' fullscreen: ' + props.fullscreen.toString()} {'smallScreen: ' + isSmallScreen.toString()}*/}


            <div id="car-view" className={`container-fluid h-100 g-0 px-0 background ${props.selectedBackground} fullscreen`}
                 onClick={(evt) =>  props.closeConfigOnOutsideClick(evt)}>
                {props.selectedRimGroup && props.selectedRimGroup.rimId &&
                    (
                        <CarViewRimPreview selectedRimId={props.rimId} rimGroup={props.selectedRimGroup}
                                           changeRimSize={props.changeRimSize}
                                           carId={props.carId.split("_")[0]}/>
                    )
                }
                {props.carId &&
                    (
                        <div className={`col${isButtonBarExpanded ? '-7' : '-11'} h-100 d-flex flex-column justify-content-center `}
                             onClick={() => setIsButtonBarExpanded(false)}>
                            <RimConfigComponent
                                vehicleId={props.carId}
                                hideColorSelect={true}
                                rimId={props.rimId}
                                use3d={props.use3d}
                                carColor={props.selectedColorId}
                                lowering={props.selectedLowering}
                                setCanBeLowered={(can) => props.setCanBeLowered(can)}
                                imageLoaded={(data) => props.imageLoaded(data)}
                                landScapeBarExpanded={isButtonBarExpanded}
                                fullScreenToggled={props.fullscreen}
                            />
                        </div>
                    )
                }

                <div className={`col${isButtonBarExpanded ? '-5' : ''} h-100`}>
                    <div className={"d-flex align-content-center h-100 justify-content-end"}>
                        <CarViewConfigPanel carBackgrounds={props.carBackgrounds}
                                            selectedColorId={props.selectedColorId}
                                            selectedBackground={props.selectedBackground}
                                            selectedLowering={props.selectedLowering}
                                            loweringMin={props.loweringMin}
                                            loweringMax={props.loweringMax}
                                            openCarDetails={props.openCarDetails}
                                            editCar={props.editCar}
                                            carColors={props.carColors}
                                            isSmallScreen={true}
                                            onOptionChangeHandler={(option, obj) => props.onChangeOptionHandler(option, obj)}
                                            isExpandedCallback={(state) => setIsButtonBarExpanded(state)}
                                            isExpanded={isButtonBarExpanded}
                                            rimDetails={props.rimDetails}
                                            rimId={props.rimId}
                                            rimSizes={props.rimSizes}
                                            rimSizeAssignments={props.rimSizeAssignments}
                                            toggleFullScreen={props.toggleFullScreenMode}
                                            carImages={props.carImages}
                        />
                    </div>
                </div>
            </div>
            {props.showCarDetails && <MobileCarViewModal
                open={props.showCarDetails}
                selectedColorId={props.selectedColorId}
                rimConfigData={props.rimConfigData}
                carImageUrl={props.carImageUrl}
                carDetails={props.carDetails}
                close={() => {
                    setIsButtonBarExpanded(false);
                    props.toggleCarDetails();
                }}/>
            }
        </>

    );
}
