import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {ErrorBoundary} from "../../helper/ErrorBoundary";
import { RedirectWithStatus } from "../../helper/RedirectWithStatus";
import { useTracking } from "../../helper/useTracking";
import { redirects } from "../../redirects";
import {routes} from "../../routes";
import ScrollToTop from "../ScrollToTop";
import CookieConsent from "./CookieConsent/CookieConsent";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import useFullPageLoader from "../../helper/useFullPageLoader";
import { PullOutMenu } from "./PullOutMenu/PullOutMenu";

function Layout() {
    useTracking();
    let location = useLocation();
    global.LOAD = useFullPageLoader();
    return (
        <ErrorBoundary>
            <div className="body-wrapper">
                <div className="header-container" id="header">
                    <Header loc={location.pathname}/>
                    {/* <Breadcrumb path={location.pathname} />*/}
                </div>
                <div className="body-container">
                    <Routes>
                        {redirects.map(redirect => <Route
                            key={redirect.from}
                            path={redirect.from}
                            element={<RedirectWithStatus {...redirect}/>}
                        />)}
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={route.preventScrollTop ? React.createElement(route.component) : <>
                                    <ScrollToTop/>
                                    {React.createElement(route.component)}
                                </>}
                            />
                        ))}
                        <Route
                            path={"*"}
                            element={React.createElement(NotFoundPage)}
                        />
                    </Routes>
                </div>
                <PullOutMenu></PullOutMenu>
                <Footer/>
                <CookieConsent/>
            </div>
        </ErrorBoundary>
    );
}

export default Layout;
