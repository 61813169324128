import React from "react";
import { useTranslation } from "react-i18next";
import { classList } from "../../helper/Helper";

export function CarSelectionInfo(props: { isRimDetailPage: boolean, headline?: string, isOnSeoPage?: boolean }) {
    const [t] = useTranslation();
    return (
        <div className="car-selection-info mx-xl-5">
            {props.isRimDetailPage
                ? <p className={classList({ "text-background": !props.isOnSeoPage })}>{t("RIMS.DETAILS.PLEASE_SELECT_CAR_FIRST")}</p>
                :  <div className={classList({ "text-background": !props.isOnSeoPage })}>
                        <h1>{props.headline ?? t("CAR_SELECTION.TITLE")}</h1>
                   </div>
            }
        </div>
    );
}
