import intl from "intl";
import areIntlLocalesSupported from "intl-locales-supported";
import { UserDTO } from "../models/shared/UserDTO";
import axiosInstance from "./api/AxiosInstance";
import {ContactRequestDTO} from "../models/shared/ContactRequestDTO";

const localesMyAppSupports = ["de-DE"];

if (global.Intl) {
    // Determine if the built-in `Intl` has the locale data we need.
    if (!areIntlLocalesSupported(localesMyAppSupports)) {
        // `Intl` exists, but it doesn't have the data we need, so load the
        // polyfill and patch the constructors we need with the polyfill's.
        Intl.NumberFormat = intl.NumberFormat;
        Intl.DateTimeFormat = intl.DateTimeFormat;
    }
} else {
    // No `Intl`, so use and load the polyfill.
    global.Intl = intl;
}

/*
 * Login
 */
export function login(user?: string, password?: string) {
    const url = `/api/login`;
    let auth: string;
    if (user && password) {
        auth = "Basic " + btoa(`${user}:${password}`);
    }

    return axiosInstance.post<UserDTO>(url, {}, {
        headers: {
            Authorization: auth
        }
    }).then(res => res.data);
}
export function loginGoogle(token: string) {
    const url = `/api/login/external/google`;
    console.log(token)
    return axiosInstance.post<UserDTO>(url, token, {headers: {"Content-Type": "text/plain"}}).then(res => res.data);
}
export function loginAmazon(token: string) {
    const url = `/api/login/external/amazon`;
    console.log(token)
    return axiosInstance.post<UserDTO>(url, token, {headers: {"Content-Type": "text/plain"}}).then(res => res.data);
}
export function loginAppleCode(code: string) {
    const url = `/api/login/external/apple/code`;
    console.log(code)
    return axiosInstance.post<UserDTO>(url, code, {headers: {"Content-Type": "text/plain"}}).then(res => res.data);
}
export function loginAppleToken(token: string) {
    const url = `/api/login/external/apple/token`;
    console.log(token)
    return axiosInstance.post<UserDTO>(url, token, {headers: {"Content-Type": "text/plain"}}).then(res => res.data);
}

export function postLogout() {
    return axiosInstance.post<any, any>("/api/logout")
        .then(res => res.data);
}

export function sendContactRequest(request: ContactRequestDTO) {
    return axiosInstance.post("/api/contact", request).then(res => res.data);
}

export function getOfferSlider() {
    return axiosInstance.get(`/api/offerslider`)
        .then(res => res.data);
}
