import React from "react";
import { useTranslation } from "react-i18next";
import {TyreEULabelDTO} from "../../models/tyres/TyreDTO";

interface Props {
    tyreEULabel: TyreEULabelDTO
}

export default function TyreEuLabelImage({tyreEULabel}: Props) {
    const [t] = useTranslation();
    return (
        <>
            {tyreEULabel.eprelRegistrationId ? (<>
                    <img
                        className="img-fluid eu-tyre-label"
                        src={`/tyrelabel/v3?eprelid=${tyreEULabel?.eprelRegistrationId}&width=200`}
                        alt="EU-Reifenlabel"
                    />
                    <div className="product-information-sheet mt-3">
                        <a
                            href={`https://eprel.ec.europa.eu/qr/${tyreEULabel?.eprelRegistrationId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fas fa-file" /> {t("TYRES.DETAILS.PRODUCT_INFORMATION")}
                        </a>
                    </div>
                </>
            ) : (
                <img
                    className="img-fluid eu-tyre-label"
                    src={`/tyrelabel/v1?rollresist=${tyreEULabel?.rollingResistance}&wetgrip=${tyreEULabel?.wetGrip}&noisedb=${tyreEULabel?.noiseEmission}&noiseclass=${tyreEULabel?.noiseClass}&tyreclass=${tyreEULabel?.vehicleClass}&width=200`}
                    alt="EU-Reifenlabel"
                />
            )}
        </>
    );
}
