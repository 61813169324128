import React, {useState} from 'react';
import {Image as RBImage, ImageProps as RBImageProps} from 'react-bootstrap';

export interface ImageProps extends RBImageProps {
    fallback?: string;
}

function Image (props: ImageProps) {
    const [useFallback, setUseFallback] = useState<boolean>(false);

    const noImageUrl = props.fallback ? props.fallback : "/no_images/komplettrad.svg";
    return (
        <RBImage alt={props.alt}
                 className={props.className}
                 crossOrigin={props.crossOrigin}
                 decoding={props.decoding}
                 height={props.height}
                 loading={props.loading}
                 referrerPolicy={props.referrerPolicy}
                 sizes={props.sizes}
                 src={ (useFallback || !props.src) ? noImageUrl : props.src}
                 srcSet={props.srcSet}
                 useMap={props.useMap}
                 width={props.width}
                 fluid={props.fluid}
                 rounded={props.rounded}
                 roundedCircle={props.roundedCircle}
                 thumbnail={props.thumbnail}
                 onError={() => setUseFallback(true)}
        />
    );
}

export default Image;
