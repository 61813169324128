import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getReactSelectStyles } from "../../helper/Helper";
import { SortName } from "../../models/shared/Enums";
import { ReactSelectOption } from "../../models/shared/ReactSelectOption";
import { SearchSort } from "../../models/shared/SearchSort";
import { SortDirection } from "../../models/shared/SortDirection";

export const SortOptions: SearchSort[] = [
    {name: SortName.RECOMMENDED, direction: SortDirection.ASC },
    {name: SortName.BRAND, direction: SortDirection.ASC },
    {name: SortName.BRAND, direction: SortDirection.DESC },
    {name: SortName.PRICE, direction: SortDirection.ASC },
    {name: SortName.PRICE, direction: SortDirection.DESC },
];
export function SortDropdown(props: {
    name: string;
    value: SearchSort;
    placeholder: string;
    onChange: (newValue: ReactSelectOption) => void;
    readOnly?: boolean;
    required?: boolean;
}) {
    const [t] = useTranslation();

    const convertSortValueToReactSelectValue = (sort: SearchSort) => {
        return {
            label: t("INPUT.SORT_OPTIONS." + sort.name + "_" + sort.direction),
            value: sort.name + "_" + sort.direction,
        };
    };
    return (
        <Select
            name={props.name}
            options={SortOptions.map(s => convertSortValueToReactSelectValue(s))}
            value={convertSortValueToReactSelectValue(props.value)}
            placeholder={t(props.placeholder ? props.placeholder : "INPUT.PLACEHOLDER.SELECT")}
            onChange={props.onChange}
            isDisabled={props.readOnly}
            isClearable={props.required}
            styles={getReactSelectStyles(1000)}
        />
    );
}
