import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import {getFallbackImage} from "../../helper/Helper";
import {CartDTO, CartPositionDTO} from "../../models/cart/CartDTO";
import {Axle, ItemType} from "../../models/shared/Enums";
import {updatePositionQuantity} from "../../redux-store/api/ShoppingCartApi";
import AmountInput from "../AmountInput/AmountInput";
import Image from "../Image/Image";
import CarBuildDateInput from "../../pages/ShoppingCart/components/CarBuildDateInput/CarBuildDateInput";
import CarDescriptionInput from "../../pages/ShoppingCart/components/CarDescriptionInput/CarDescriptionInput";
import trashSvg from '../../assets/icons/svg/delete.svg'
import TyreEuLabelImage from "../TyreEuLabelImage/TyreEuLabelImage";
import {useNavigate} from "react-router-dom";

interface Props {
    position: CartPositionDTO;
    updateCart?: (cart: CartDTO) => void;
    cartChanged?: Function;
    showInputs: boolean;
    removeButtonHandler?: Function;
    getAdditionalTyreForRimInCart?: Function;
}

function CartPositionMobile({position, updateCart, cartChanged, showInputs, removeButtonHandler, getAdditionalTyreForRimInCart}: Props) {
    const [t] = useTranslation();
    const [quantity, setQuantity] = useState(position.quantity);

    function changeQuantity(newQuantity: number) {
        setQuantity(newQuantity);
        updatePositionQuantity(position, newQuantity)
            .then((cart) => {
                updateCart(cart);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function getAdditionalTyre(rimId: string, certificateId: number, certificateBlockId: number) {
        getAdditionalTyreForRimInCart(rimId, certificateId, certificateBlockId, position.carId, position.quantity, position.id, position.boltCircle);
    }

    return <div className="cart-position-mobile">
        {position?.items?.map((i) => i.itemType)?.includes(ItemType.RIM) && (
            <div className="row">
                <div className="col-12">
                    <div className="info-message">
                        <i className="fas fa-info-circle info-icon"/>
                        {t("CART.RIM_INCLUDES_SCREWS")}
                    </div>
                </div>
            </div>
        )}
        {position.items.map((item, index, arr) => (
            <div key={item.id}>
                <div className={`position-item ${index < arr.length - 1 ? 'item-border-bottom' : ''}`}>
                    <div className="row position-item-row py-2">
                        <div className="col-4 d-flex justify-content-center align-items-center pe-0">
                            {item.itemType === "MOUNTINGKIT" || item.itemType === 'WHEELASSEMBLY' ? (
                                <i className={`fa-6x fas fa-wrench bordered`}/>
                            ) : (
                                <Image
                                    className={`img-fluid bordered ${item.itemType === ItemType.TPMS ? 'flip-image' : ''}`}
                                    fallback={getFallbackImage(item)}
                                    src={item.imageLink}
                                    alt={item.description}
                                    fluid={true}
                                    width={'120px'}
                                />
                            )}
                        </div>
                        <div className="col-8">
                            {item.productMetadata &&
                                <div>
                                    <div className="item-title">
                                        {item.manufacturer}
                                        {item.itemType === 'RIM' && <span> {item.productMetadata?.rimType}</span>}
                                        {item.itemType === 'TYRE' && <span> {item.productMetadata?.tread}</span>}
                                    </div>
                                    {item.itemType === 'RIM' &&
                                        <div>
                                            <div
                                                className="mb-3">{`${item.productMetadata.width}x${item.productMetadata.size} ET${item.productMetadata.offset}, ${item.productMetadata.boltPattern}`}</div>
                                            <div>{item.productMetadata.colorGroup}</div>
                                        </div>
                                    }
                                    {item.itemType === 'TYRE' &&
                                        <div>
                                            <div
                                                className="mb-3">{`${item.productMetadata.width}/${item.productMetadata.section}R${item.productMetadata.diameter} ${item.productMetadata.loadIndex}${item.productMetadata.speedIndex}`}</div>
                                            <div>{item.productMetadata.season ? t("TYRES.SEARCH.SEASONS." + item.productMetadata.season) : '&nbsp;'}</div>
                                        </div>
                                    }
                                </div>
                            }
                            {!item.productMetadata &&
                                <>
                                    <div className="item-title">{item.manufacturer}</div>
                                    <div
                                        className={`mb-3 ${item.itemType === 'TPMS' || item.itemType === 'WHEELASSEMBLY' ? 'item-title' : ''}`}>
                                        {item.description}
                                    </div>
                                </>
                            }
                            {+item.productId > 0 && item.productEan &&
                                <div>{t("CART.ARTICLE_NUMBER")}:&nbsp;{item.productEan}</div>}
                            {(position.axle === "FRONT" || position.axle === "REAR") && (
                                <div>{t("CART.AXLE." + position.axle)}</div>
                            )}
                            {!showInputs && item.itemType === 'TPMS' &&
                                <div className="mt-3">
                                    {t("CART.CAR_BUILD_DATE_PLACEHOLDER")}: {item.carBuildDate}
                                </div>
                            }
                            {item.errorMessage && <div className="text-danger mt-2">{item.errorMessage}</div>}
                            {!item?.errorMessage && item?.priceGross && item?.priceGross > 0 && (
                                <div className="d-flex justify-content-between mt-4">
                                    <div className="">{t("CART.PIECE_PRICE")}</div>
                                    <strong>
                                        <NumericFormat
                                            value={item.priceGross}
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            suffix="€"
                                        />
                                    </strong>
                                </div>
                            )}
                            {showInputs && item.itemType === 'TPMS' &&
                                <CarBuildDateInput item={item}/>
                            }
                            {showInputs && position.positionType === 'RIM' && !position.carHsn &&
                                <CarDescriptionInput position={position}/>
                            }
                            {!showInputs && position.positionType === 'RIM' && !position.carHsn &&
                                <div className="car-description text-start mt-2">
                                    {t("CART.CAR_DESCRIPTION")}: {position.carDescription}
                                </div>
                            }
                        </div>
                    </div>
                    {(item.tyreEULabel || item.tyreEULabel?.eprelRegistrationId) &&
                        <div className="row mt-2">
                            <div className="offset-4 col-6 text-left">
                                {item.tyreEULabel &&
                                    <TyreEuLabelImage tyreEULabel={item.tyreEULabel}/>
                                }
                            </div>
                        </div>
                    }
                    {showInputs && position.positionType === 'RIM' && position.carId && position.axle === Axle.BOTH &&
                        <div className="col-12 mt-3">
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() => getAdditionalTyre(item.productExternalId, item.productMetadata.certificateId, item.productMetadata.certificateBlockId)}
                            >
                                {t("CART.ADD_TYRE")}
                            </button>
                        </div>
                    }
                </div>
            </div>
        ))}
        <div className="position-action">
            <div className="row align-items-center">
                <div className="col-7">
                    {showInputs &&
                        <button className="icon-btn"
                                onClick={() => removeButtonHandler(position)}>
                                <img src={trashSvg} className="filter-orange me-2"/> {t('CART.REMOVE_POSITION')}
                        </button>
                    }
                </div>
                <div className="col d-flex justify-content-end">
                    <AmountInput
                        amount={quantity}
                        handleAmountChange={(amount) => changeQuantity(amount)}
                        onlyEvenNumbers={false}
                        readonly={!showInputs}
                    />
                </div>

            </div>
        </div>
        <div className="position-sum">
            {position.items.filter(i=> i.priceGross).map((item, index, arr) => (
                <div className="d-flex justify-content-between mb-2" key={item.id}>
                    {item.itemType === 'RIM' &&
                        <div>
                            {t('CART.RIM_SET')}:
                        </div>
                    }
                    {item.itemType === 'TYRE' &&
                        <div>
                            {t('CART.TYRE_SET')}:
                        </div>
                    }
                    {item.itemType === 'TPMS' &&
                        <div>
                            {t('CART.TPMS')}:
                        </div>
                    }
                    {item.itemType === "MOUNTINGKIT" &&
                        <div>
                            {t('CART.MOUNTING_KIT')}:
                        </div>
                    }
                    {item.itemType === 'WHEELASSEMBLY' &&
                        <div>
                            {t('CART.ASSEMBLY')}:
                        </div>
                    }
                    <div className="fw-bold">
                        {item.priceGross && item.priceGross > 0 &&
                            <NumericFormat
                                value={item.priceGross * position.quantity}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                suffix="€"/>
                        }

                    </div>
                </div>
            ))}
            <div className="d-flex justify-content-between">
                <div className="fw-bold">{t('CART.POSITION_SUM_MOBILE')}:</div>
                <div className="fw-bold">
                    <NumericFormat
                        value={position.sumGross}
                        displayType="text"
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix="€"/>
                </div>
            </div>
        </div>

    </div>;
}

export default CartPositionMobile;
