import classNames from "classnames";
import React, {useState} from "react";
import { ReactSelectOption } from "../../models/shared/ReactSelectOption";

interface RimSizeOptions {
    value: string;
    label: string;
}

interface RimSizeSelectionProps {
    values?: string[];
    options?: string[];
    multiSelect: boolean;
    containerClasses?: string;
    objectClasses?: string;
    onSelect?: (newValues: string[]) => void;
}

export default function RimSizeSelection(props: RimSizeSelectionProps){


    const [selectedSizes, setSelectedSizes] = useState<string[]>(props.values);

    function toggleRimSize(selectedOption: string ){
        if (props.multiSelect) {
            let options: string[] = [];
            if (selectedSizes.some(size => size === selectedOption)) {
                options = selectedSizes.filter(size => size !== selectedOption);
                setSelectedSizes(options);
                props.onSelect(options.map(v => v));
            } else {
                options = [...selectedSizes, selectedOption];
                setSelectedSizes(options);
                props.onSelect(options.map(v => v));
            }
            return;
        }
    }

    function isSelected(option: string): boolean {
        return selectedSizes.some(size => size === option);
    }

    function getRimSizes() {
        return (
            <>
                {
                    props.options.map((rim, i) => {
                        return (
                            <div
                                key={i}
                                className={classNames('rim-selection__rim-size m-1 p-0 small', props.objectClasses, {selected: isSelected(rim)})}
                                onClick={(e) => toggleRimSize(rim)}
                            >
                                {rim}
                            </div>
                        )
                    })
                }
            </>
        )
    }

    return (
        <div className={classNames(props.containerClasses, 'rim-selection')}>
            {getRimSizes()}
        </div>
    )
}
