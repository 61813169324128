import axios from "axios";

const defaultOptions = {
    baseURL: process.env.HOST || "",
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
    }
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use((config) => {
    const cmsPath = "/cms";
    if (process.env.CMS_INTERNAL_DOMAIN && config.url?.startsWith(cmsPath)) {
        config.baseURL = process.env.CMS_INTERNAL_DOMAIN;
        config.url = config.url?.substring(cmsPath.length);
    }
    return config;
});

export default axiosInstance;
