import { CartDTO } from "../../models/cart/CartDTO";

export enum ShoppingCartActionType {
    GET_SHOPPING_CART = "GET_SHOPPING_CART",
    STORE_SHOPPING_CART = "STORE_SHOPPING_CART"
}

export interface ShoppingCartAction {
    type: ShoppingCartActionType,
    payload: CartDTO
}

const initialState: CartDTO = {
    deliveryAddress: undefined,
    discount: 0,
    id: 0,
    invalid: false,
    invoiceAddress: undefined,
    lastChanged: "",
    positions: [],
    shipping: 0,
    sumGross: 0,
    sumNet: 0,
    userId: 0,
    vat: 0,
    voucherCode: "",
    email: ""
}

export const shoppingcartReducer = (state: CartDTO = initialState, {type, payload}: ShoppingCartAction) => {

    switch (type) {
        case ShoppingCartActionType.STORE_SHOPPING_CART:
            return {
                ...state,
                ...payload
            }

        default:
        case ShoppingCartActionType.GET_SHOPPING_CART:
            return state;
    }
};