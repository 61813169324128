export interface FullScreenModeState {
    isEnabled: boolean;
}


export enum FullScreenModeActionType {
    GET= "FULLSCREENMODE_GET",
    SET = "FULLSCREENMODE_SET",
}

export interface FullScreenModeAction {
    type: FullScreenModeActionType;
    payload: FullScreenModeState;
}

export const fullScreenModeReducer = (state: FullScreenModeState = {isEnabled: false}, action: FullScreenModeAction) => {

    switch (action.type) {
        default:
            return state;

        case FullScreenModeActionType.SET:
            return {
                ...action.payload
            };
    }
}