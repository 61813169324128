import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Moment} from 'moment';
import {getOfferSlider} from '../../../redux-store/api';

interface OfferSliderEntryDTO {
    id: number;
    imageId?: number;
    hoverText?: string;
    link?: string;
    sortIndex?: number;
    validFrom?: string | Moment;
    validTo?: string | Moment;
    image?: any;
}

function Offer () {
    const [offers, setOffers] = useState<OfferSliderEntryDTO[]>([]);

    useEffect(() => {
        getOfferSliders();
    }, []);

    function getOfferSliders () {
        getOfferSlider().then(
            (res) => {
                setOffers(res);
            }
        ).catch(error => {
            console.error(error);
        });
    }


    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        adaptiveHeight: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerMode: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    function LinkOrDiv ({offer, children}) {
        if(offer.link){
            if(/^http(s?):\/\//.test(offer.link)){
                return <a href={offer.link} title={offer.hoverText || ''} className="offer" key={offer.id} target="_blank">
                    {children}
                </a>
            } else {
                return <Link to={offer.link} title={offer.hoverText || ''} className="offer" key={offer.id}>
                    {children}
                </Link>
            }
        } else {
            return <div className="offer" key={offer.id}>
                {children}
            </div>
        }
    }

    function TooltipOrNot ({offer, children}) {
        return offer.hoverText ?
            <OverlayTrigger key={offer.id}
                            placement="top"
                            overlay={
                                <Tooltip id={offer.id.toString()}>
                                    {offer.hoverText}
                                </Tooltip>
                            }>
                {children}
            </OverlayTrigger>
            :
            <React.Fragment>{children}</React.Fragment>
    }

    return (
        <div className="offer-wrapper">
            <div className="container-fluid">
                <Slider {...settings}>
                    {offers?.map((offer: OfferSliderEntryDTO, index) => (
                        <LinkOrDiv offer={offer} key={offer.id}>
                            <TooltipOrNot offer={offer} key={offer.id}>
                                <img src={"/api/offerslider/" + offer.imageId + "/image"} alt="offer-slider-image"/>
                            </TooltipOrNot>
                        </LinkOrDiv>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Offer;
