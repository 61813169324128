import { TFunction } from "i18next";
import { CountryOptionType } from "../shared/Types";

export enum CountryCodes {
    AD = "AD",
    AE = "AE",
    AF = "AF",
    AG = "AG",
    AI = "AI",
    AL = "AL",
    AM = "AM",
    AN = "AN",
    AO = "AO",
    AQ = "AQ",
    AR = "AR",
    AS = "AS",
    AT = "AT",
    AU = "AU",
    AW = "AW",
    AX = "AX",
    AZ = "AZ",
    BA = "BA",
    BB = "BB",
    BD = "BD",
    BE = "BE",
    BF = "BF",
    BG = "BG",
    BH = "BH",
    BI = "BI",
    BJ = "BJ",
    BL = "BL",
    BM = "BM",
    BN = "BN",
    BO = "BO",
    BQ = "BQ",
    BR = "BR",
    BS = "BS",
    BT = "BT",
    BV = "BV",
    BW = "BW",
    BY = "BY",
    BZ = "BZ",
    CA = "CA",
    CC = "CC",
    CD = "CD",
    CF = "CF",
    CG = "CG",
    CH = "CH",
    CI = "CI",
    CK = "CK",
    CL = "CL",
    CM = "CM",
    CN = "CN",
    CO = "CO",
    CR = "CR",
    CS = "CS",
    CU = "CU",
    CV = "CV",
    CW = "CW",
    CX = "CX",
    CY = "CY",
    CZ = "CZ",
    DE = "DE",
    DJ = "DJ",
    DK = "DK",
    DM = "DM",
    DO = "DO",
    DZ = "DZ",
    EC = "EC",
    EE = "EE",
    EG = "EG",
    EH = "EH",
    ER = "ER",
    ES = "ES",
    ET = "ET",
    FI = "FI",
    FJ = "FJ",
    FK = "FK",
    FM = "FM",
    FO = "FO",
    FR = "FR",
    GA = "GA",
    GB = "GB",
    GD = "GD",
    GE = "GE",
    GF = "GF",
    GG = "GG",
    GH = "GH",
    GI = "GI",
    GL = "GL",
    GM = "GM",
    GN = "GN",
    GP = "GP",
    GQ = "GQ",
    GR = "GR",
    GS = "GS",
    GT = "GT",
    GU = "GU",
    GW = "GW",
    GY = "GY",
    HK = "HK",
    HM = "HM",
    HN = "HN",
    HR = "HR",
    HT = "HT",
    HU = "HU",
    ID = "ID",
    IE = "IE",
    IL = "IL",
    IM = "IM",
    IN = "IN",
    IO = "IO",
    IQ = "IQ",
    IR = "IR",
    IS = "IS",
    IT = "IT",
    JE = "JE",
    JM = "JM",
    JO = "JO",
    JP = "JP",
    KE = "KE",
    KG = "KG",
    KH = "KH",
    KI = "KI",
    KM = "KM",
    KN = "KN",
    KP = "KP",
    KR = "KR",
    KW = "KW",
    KY = "KY",
    KZ = "KZ",
    LA = "LA",
    LB = "LB",
    LC = "LC",
    LI = "LI",
    LK = "LK",
    LR = "LR",
    LS = "LS",
    LT = "LT",
    LU = "LU",
    LV = "LV",
    LY = "LY",
    MA = "MA",
    MC = "MC",
    MD = "MD",
    ME = "ME",
    MF = "MF",
    MG = "MG",
    MH = "MH",
    MK = "MK",
    ML = "ML",
    MM = "MM",
    MN = "MN",
    MO = "MO",
    MP = "MP",
    MQ = "MQ",
    MR = "MR",
    MS = "MS",
    MT = "MT",
    MU = "MU",
    MV = "MV",
    MW = "MW",
    MX = "MX",
    MY = "MY",
    MZ = "MZ",
    NA = "NA",
    NC = "NC",
    NE = "NE",
    NF = "NF",
    NG = "NG",
    NI = "NI",
    NL = "NL",
    NO = "NO",
    NP = "NP",
    NR = "NR",
    NU = "NU",
    NZ = "NZ",
    OM = "OM",
    PA = "PA",
    PE = "PE",
    PF = "PF",
    PG = "PG",
    PH = "PH",
    PK = "PK",
    PL = "PL",
    PM = "PM",
    PN = "PN",
    PR = "PR",
    PS = "PS",
    PT = "PT",
    PW = "PW",
    PY = "PY",
    QA = "QA",
    RE = "RE",
    RO = "RO",
    RS = "RS",
    RU = "RU",
    RW = "RW",
    SA = "SA",
    SB = "SB",
    SC = "SC",
    SD = "SD",
    SE = "SE",
    SG = "SG",
    SH = "SH",
    SI = "SI",
    SJ = "SJ",
    SK = "SK",
    SL = "SL",
    SM = "SM",
    SN = "SN",
    SO = "SO",
    SR = "SR",
    SS = "SS",
    ST = "ST",
    SV = "SV",
    SX = "SX",
    SY = "SY",
    SZ = "SZ",
    TC = "TC",
    TD = "TD",
    TF = "TF",
    TG = "TG",
    TH = "TH",
    TJ = "TJ",
    TK = "TK",
    TL = "TL",
    TM = "TM",
    TN = "TN",
    TO = "TO",
    TR = "TR",
    TT = "TT",
    TV = "TV",
    TW = "TW",
    TZ = "TZ",
    UA = "UA",
    UG = "UG",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VA = "VA",
    VC = "VC",
    VE = "VE",
    VG = "VG",
    VI = "VI",
    VN = "VN",
    VU = "VU",
    WF = "WF",
    WS = "WS",
    XK = "XK",
    YE = "YE",
    YT = "YT",
    ZA = "ZA",
    ZM = "ZM",
    ZW = "ZW"
}

export const countryCodeList = [
    CountryCodes.BE,    CountryCodes.BA,    CountryCodes.BG,    CountryCodes.DK,    CountryCodes.DE,    CountryCodes.EE,
    CountryCodes.FI,    CountryCodes.FR,    CountryCodes.GE,    CountryCodes.GR,    CountryCodes.GB,    CountryCodes.IE,
    CountryCodes.IS,    CountryCodes.IT,    CountryCodes.HR,    CountryCodes.LV,    CountryCodes.LI,    CountryCodes.LT,
    CountryCodes.LU,    CountryCodes.MT,    CountryCodes.MD,    CountryCodes.NL,    CountryCodes.NO,    CountryCodes.AT,
    CountryCodes.PL,    CountryCodes.PT,    CountryCodes.RO,    CountryCodes.RU,    CountryCodes.SE,    CountryCodes.CH,
    CountryCodes.RS,    CountryCodes.SK,    CountryCodes.SI,    CountryCodes.ES,    CountryCodes.CZ,    CountryCodes.UA,
    CountryCodes.HU,    CountryCodes.BY,
];

export const countryZipRegex = {
    [CountryCodes.AD]: /^(?:AD)(\d{3})$/,
    [CountryCodes.AE]: /^.+$/,
    [CountryCodes.AF]: /^\d{4}$/,
    [CountryCodes.AG]: /^\d{5}$/,
    [CountryCodes.AI]: /^(AI-2640)$/,
    [CountryCodes.AL]: /^\d{4}$/,
    [CountryCodes.AM]: /^(\d{4})|(\d{6})$/,
    [CountryCodes.AO]: /^.+$/,
    [CountryCodes.AQ]: /^(7151)$/,
    [CountryCodes.AR]: /^([A-Z]\d{4}[A-Z]{3})|([A-Z]\d{4})$/,
    [CountryCodes.AS]: /^\d{5}$/,
    [CountryCodes.AT]: /^\d{4}$/,
    [CountryCodes.AU]: /^\d{4}$/,
    [CountryCodes.AW]: /^.+$/,
    [CountryCodes.AZ]: /^(AZ) (\d{4})$/,
    [CountryCodes.BA]: /^\d{5}$/,
    [CountryCodes.BB]: /^(?:BB)(\d{5})$/,
    [CountryCodes.BD]: /^\d{4}$/,
    [CountryCodes.BE]: /^\d{4}$/,
    [CountryCodes.BF]: /^.+$/,
    [CountryCodes.BG]: /^\d{4}$/,
    [CountryCodes.BH]: /^\d{3}\d?$/,
    [CountryCodes.BI]: /^.+$/,
    [CountryCodes.BJ]: /^.+$/,
    [CountryCodes.BM]: /^[A-Z]{2} \d{2}$/,
    [CountryCodes.BN]: /^[A-Z]{2}\d{4}$/,
    [CountryCodes.BO]: /^.+$/,
    [CountryCodes.BQ]: /^.+$/,
    [CountryCodes.BR]: /^[0-9]{5}-[0-9]{3}$/,
    [CountryCodes.BS]: /^.+$/,
    [CountryCodes.BT]: /^\d{5}$/,
    [CountryCodes.BV]: /^.+$/,
    [CountryCodes.BW]: /^.+$/,
    [CountryCodes.BY]: /^\d{6}$/,
    [CountryCodes.BZ]: /^.+$/,
    [CountryCodes.CA]: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] +?[0-9][A-Z][0-9]$/,
    [CountryCodes.CC]: /^(6799)$/,
    [CountryCodes.CD]: /^.+$/,
    [CountryCodes.CF]: /^.+$/,
    [CountryCodes.CG]: /^.+$/,
    [CountryCodes.CH]: /^\d{4}$/,
    [CountryCodes.CI]: /^.+$/,
    [CountryCodes.CK]: /^.+$/,
    [CountryCodes.CL]: /^\d{7}$/,
    [CountryCodes.CM]: /^.+$/,
    [CountryCodes.CN]: /^\d{6}$/,
    [CountryCodes.CO]: /^\d{6}$/,
    [CountryCodes.CR]: /^\d{5}$/,
    [CountryCodes.CU]: /^(?:CP)(\d{5})$/,
    [CountryCodes.CV]: /^\d{4}$/,
    [CountryCodes.CW]: /^.+$/,
    [CountryCodes.CX]: /^(6798)$/,
    [CountryCodes.CY]: /^\d{4}$/,
    [CountryCodes.CZ]: /^[0-9]{3} [0-9]{2}|[0-9]{5}$/,
    [CountryCodes.DE]: /^\d{5}$/,
    [CountryCodes.DJ]: /^.+$/,
    [CountryCodes.DK]: /^\d{4}$/,
    [CountryCodes.DM]: /^.+$/,
    [CountryCodes.DO]: /^\d{5}$/,
    [CountryCodes.DZ]: /^\d{5}$/,
    [CountryCodes.EC]: /^\d{6}$/,
    [CountryCodes.EE]: /^\d{5}$/,
    [CountryCodes.EG]: /^\d{5}$/,
    [CountryCodes.ER]: /^.+$/,
    [CountryCodes.ES]: /^\d{5}$/,
    [CountryCodes.ET]: /^\d{4}$/,
    [CountryCodes.FI]: /^\d{5}$/,
    [CountryCodes.FJ]: /^.+$/,
    [CountryCodes.FK]: /^(FIQQ 1ZZ)$/,
    [CountryCodes.FM]: /^\d{5}$/,
    [CountryCodes.FO]: /^\d{3}$/,
    [CountryCodes.FR]: /^\d{5}$/,
    [CountryCodes.GA]: /^.+$/,
    [CountryCodes.GB]:
        /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/,
    [CountryCodes.GD]: /^.+$/,
    [CountryCodes.GE]: /^\d{4}$/,
    [CountryCodes.GG]: /^(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}$/,
    [CountryCodes.GH]: /^.+$/,
    [CountryCodes.GI]: /^(GX11 1AA)$/,
    [CountryCodes.GL]: /^\d{4}$/,
    [CountryCodes.GM]: /^.+$/,
    [CountryCodes.GN]: /^\d{3}$/,
    [CountryCodes.GQ]: /^.+$/,
    [CountryCodes.GR]: /^(\d{3}) \d{2}|\d{5}$/,
    [CountryCodes.GS]: /^(SIQQ 1ZZ)$/,
    [CountryCodes.GT]: /^\d{5}$/,
    [CountryCodes.GU]: /^((969)[1-3][0-2])$/,
    [CountryCodes.GW]: /^\d{4}$/,
    [CountryCodes.GY]: /^.+$/,
    [CountryCodes.HK]: /^(999077)$/,
    [CountryCodes.HM]: /^(7151)$/,
    [CountryCodes.HN]: /^\d{5}$/,
    [CountryCodes.HR]: /^\d{5}$/,
    [CountryCodes.HT]: /^(?:HT)(\d{4})$/,
    [CountryCodes.HU]: /^\d{4}$/,
    [CountryCodes.ID]: /^\d{5}$/,
    [CountryCodes.IE]: /^.+$/,
    [CountryCodes.IL]: /^\d{7}$/,
    [CountryCodes.IM]: /^(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}$/,
    [CountryCodes.IN]: /^\d{6}$/,
    [CountryCodes.IO]: /^(BB9D 1ZZ)$/,
    [CountryCodes.IQ]: /^\d{5}$/,
    [CountryCodes.IR]: /^\d{5}[\-]?\d{5}$/,
    [CountryCodes.IS]: /^\d{3}$/,
    [CountryCodes.IT]: /^\d{5}$/,
    [CountryCodes.JE]: /^JE[0-9]{1}[\s]([\d][A-Z]{2})$/,
    [CountryCodes.JM]: /^(JM)[A-Z]{3}\d{2}$/,
    [CountryCodes.JO]: /^\d{5}$/,
    [CountryCodes.JP]: /^(\d{3}-\d{4})$/,
    [CountryCodes.KE]: /^\d{5}$/,
    [CountryCodes.KG]: /^\d{6}$/,
    [CountryCodes.KH]: /^\d{5}$/,
    [CountryCodes.KI]: /^.+$/,
    [CountryCodes.KM]: /^.+$/,
    [CountryCodes.KN]: /^.+$/,
    [CountryCodes.KP]: /^.+$/,
    [CountryCodes.KR]: /^\d{5}$/,
    [CountryCodes.KW]: /^\d{5}$/,
    [CountryCodes.KY]: /^[K][Y][0-9]{1}[-]([0-9]){4}$/,
    [CountryCodes.KZ]: /^\d{6}$/,
    [CountryCodes.LA]: /^\d{5}$/,
    [CountryCodes.LB]: /^\d{4}( \d{4})?$/,
    [CountryCodes.LC]: /^.+$/,
    [CountryCodes.LI]: /^\d{4}$/,
    [CountryCodes.LK]: /^\d{5}$/,
    [CountryCodes.LR]: /^\d{4}$/,
    [CountryCodes.LS]: /^\d{3}$/,
    [CountryCodes.LT]: /^((?:LT)[\-])?(\d{5})$/,
    [CountryCodes.LU]: /^((?:L)[\-])?(\d{4})$/,
    [CountryCodes.LV]: /^[L]{1}[V]{1}[-]([0-9]){4}$/,
    [CountryCodes.LY]: /^.+$/,
    [CountryCodes.MA]: /^\d{5}$/,
    [CountryCodes.MD]: /^(?:MD)[\-]?(\d{4})$/,
    [CountryCodes.ME]: /^\d{5}$/,
    [CountryCodes.MG]: /^\d{3}$/,
    [CountryCodes.MH]: /^((969)[6-7][0-9])$/,
    [CountryCodes.MK]: /^\d{4}$/,
    [CountryCodes.ML]: /^.+$/,
    [CountryCodes.MM]: /^\d{5}$/,
    [CountryCodes.MN]: /^\d{5}$/,
    [CountryCodes.MO]: /^(999078)$/,
    [CountryCodes.MP]: /^\d{5}$/,
    [CountryCodes.MR]: /^.+$/,
    [CountryCodes.MS]: /^(?:MSR )(\d{4})$/,
    [CountryCodes.MT]:
        /^[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}$/,
    [CountryCodes.MU]: /^([0-9A-Z]\d{4})$/,
    [CountryCodes.MV]: /^\d{5}$/,
    [CountryCodes.MW]: /^.+$/,
    [CountryCodes.MX]: /^\d{5}$/,
    [CountryCodes.MY]: /^\d{5}$/,
    [CountryCodes.MZ]: /^\d{4}$/,
    [CountryCodes.NA]: /^.+$/,
    [CountryCodes.NC]: /^\d{5}$/,
    [CountryCodes.NE]: /^\d{4}$/,
    [CountryCodes.NF]: /^(2899)$/,
    [CountryCodes.NG]: /^\d{6}$/,
    [CountryCodes.NI]: /^\d{5}$/,
    [CountryCodes.NL]: /^[0-9]{4} [A-Z]{2}|[0-9]{4}[A-Z]{2}$/,
    [CountryCodes.NO]: /^\d{4}$/,
    [CountryCodes.NP]: /^\d{5}$/,
    [CountryCodes.NR]: /^.+$/,
    [CountryCodes.NU]: /^.+$/,
    [CountryCodes.NZ]: /^\d{4}$/,
    [CountryCodes.OM]: /^\d{3}$/,
    [CountryCodes.PA]: /^.+$/,
    [CountryCodes.PE]: /^\d{5}$/,
    [CountryCodes.PF]: /^((987)\d{2})$/,
    [CountryCodes.PG]: /^\d{3}$/,
    [CountryCodes.PH]: /^\d{4}$/,
    [CountryCodes.PK]: /^\d{5}$/,
    [CountryCodes.PL]: /^[0-9]{2}[-]([0-9]){3}$/,
    [CountryCodes.PN]: /^(PCR9 1ZZ)$/,
    [CountryCodes.PS]: /^(\d{3}-\d{3})$/,
    [CountryCodes.PT]: /^\d{4}((-)\d{3})$/,
    [CountryCodes.PW]: /^(96939|96940)$/,
    [CountryCodes.PY]: /^\d{4}$/,
    [CountryCodes.QA]: /^.+$/,
    [CountryCodes.RO]: /^\d{6}$/,
    [CountryCodes.RS]: /^\d{5}$/,
    [CountryCodes.RU]: /^\d{6}$/,
    [CountryCodes.RW]: /^.+$/,
    [CountryCodes.SA]: /^\d{5}([\-]\d{4})?$/,
    [CountryCodes.SB]: /^.+$/,
    [CountryCodes.SC]: /^.+$/,
    [CountryCodes.SD]: /^\d{5}$/,
    [CountryCodes.SE]: /^(\d{3} \d{2})$/,
    [CountryCodes.SG]: /^\d{6}$/,
    [CountryCodes.SH]: /^(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)$/,
    [CountryCodes.SI]: /^\d{4}$/,
    [CountryCodes.SK]: /^(\d{3} \d{2})|\d{5}$/,
    [CountryCodes.SL]: /^.+$/,
    [CountryCodes.SM]: /^(4789\d)$/,
    [CountryCodes.SN]: /^\d{5}$/,
    [CountryCodes.SO]: /^.+$/,
    [CountryCodes.SR]: /^.+$/,
    [CountryCodes.SS]: /^\d{5}$/,
    [CountryCodes.ST]: /^.+$/,
    [CountryCodes.SV]: /^((CP) \d{4})$/,
    [CountryCodes.SX]: /^.+$/,
    [CountryCodes.SY]: /^.+$/,
    [CountryCodes.SZ]: /^([A-Z]\d{3})$/,
    [CountryCodes.TC]: /^(TKCA 1ZZ)$/,
    [CountryCodes.TD]: /^.+$/,
    [CountryCodes.TF]: /^((984)\d{2})$/,
    [CountryCodes.TG]: /^.+$/,
    [CountryCodes.TH]: /^\d{5}$/,
    [CountryCodes.TJ]: /^\d{3}$/,
    [CountryCodes.TK]: /^.+$/,
    [CountryCodes.TL]: /^.+$/,
    [CountryCodes.TM]: /^\d{6}$/,
    [CountryCodes.TN]: /^\d{4}$/,
    [CountryCodes.TO]: /^.+$/,
    [CountryCodes.TR]: /^\d{5}$/,
    [CountryCodes.TT]: /^.+$/,
    [CountryCodes.TV]: /^.+$/,
    [CountryCodes.TW]: /^(\d{3}[-]\d{2})|(\d{3})$/,
    [CountryCodes.TZ]: /^\d{5}$/,
    [CountryCodes.UA]: /^\d{5}$/,
    [CountryCodes.UG]: /^.+$/,
    [CountryCodes.UM]: /^.+$/,
    [CountryCodes.US]: /^[0-9]{5}(?:-[0-9]{4})?$/,
    [CountryCodes.UY]: /^\d{5}$/,
    [CountryCodes.UZ]: /^\d{6}$/,
    [CountryCodes.VA]: /^(00120)$/,
    [CountryCodes.VC]: /^(VC)(\d{4})$/,
    [CountryCodes.VE]: /^\d{4}$/,
    [CountryCodes.VG]: /^(VG11)[0-6][0]$/,
    [CountryCodes.VI]: /^\d{5}$/,
    [CountryCodes.VN]: /^\d{6}$/,
    [CountryCodes.VU]: /^.+$/,
    [CountryCodes.WF]: /^((986)\d{2})$/,
    [CountryCodes.WS]: /^(96799)$/,
    [CountryCodes.YE]: /^.+$/,
    [CountryCodes.ZA]: /^\d{4}$/,
    [CountryCodes.ZM]: /^\d{5}$/,
    [CountryCodes.ZW]: /^.+$/,
};

export function getCountryList(t: TFunction, countriesWithShipping?: Array<string>): CountryOptionType[] {
    let countryList: string[] = [];

    if (countriesWithShipping) {
        countryList = countriesWithShipping;
    } else {
        countryList = countryCodeList;
    }

    return countryList
        .map((c) => ({
            code: c,
            label: t("ENUMS.COUNTRY_CODES." + c)
        }))
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
}
