import {CartItemDTO} from "../models/cart/CartDTO";
import {KitInformationDTO} from "../models/rims/KitInformationDTO";
import {RimInformationDTO} from "../models/rims/RimDetailDTO";
import {RimSizeDTO} from "../models/rims/RimSizeDTO";
import {ItemType} from "../models/shared/Enums";
import {TyreDTO} from "../models/tyres/TyreDTO";
import {TpmsItem} from "../models/car/TpmsCheckDTO";

export function generateTpmsPosition(tpmsItem: TpmsItem): CartItemDTO {
    return {
        description: tpmsItem.description,
        imageLink: tpmsItem.imageLink,
        itemType: ItemType.TPMS,
        manufacturer: tpmsItem.manufacturer,
        productEan: tpmsItem.productEan,
        productExternalId: tpmsItem.productExternalId,
        productId: tpmsItem.productId.toString(),
        priceGross: tpmsItem.price
    };
}

export function generateMountingKitPosition(kit: KitInformationDTO): CartItemDTO {
    return {
        description: kit.description,
        manufacturer: kit.brand,
        itemType: ItemType.MOUNTINGKIT,
        imageLink: undefined,
        productId: kit.id,
        productEan: kit.ean || "",
        productExternalId: kit.externalId,
        tyreEULabel: undefined,
    };
}

export function generateTyrePosition(tyre: TyreDTO): CartItemDTO {
    return {
        description: tyre.brandName + " " + tyre.description,
        manufacturer: tyre.brandName,
        itemType: ItemType.TYRE,
        imageLink: tyre.imageDTO?.original,
        productId: tyre.productId,
        productEan: tyre.gtin || "",
        productExternalId: tyre.productExternalId,
        tyreEULabel: tyre.tyreEULabelDTO,
        productMetadata: {
            metadataType: "TYRE",
            season: tyre.season,
            loadIndex: tyre.loadIndex,
            speedIndex: tyre.speedIndex,
            tread: tyre.tread,
            diameter: tyre.diameter,
            width: tyre.width,
            section: tyre.section
        },
        priceGross: tyre.pricesDTO?.minSellInPriceNet
    };
}

export function generateRimPosition(rim: RimInformationDTO, rimSize: RimSizeDTO, certificateId: number, certificateBlockId: number): CartItemDTO {
    const rimSizeStr = rimSize?.rimSizeDisplay || rim?.width + "x" + rim?.size + " ET" + rim?.offset;
    return {
        description: `${rim.rimManufacturer} ${rim.rimType} ${rimSizeStr} ${rim.boltPattern} ${rim.colorGroup}`,
        manufacturer: rim.rimManufacturer,
        itemType: ItemType.RIM,
        imageLink: rim.imagePerspectiveDTO?.original,
        productId: rim.productId + "",
        productEan: rim.rimEan || "",
        productExternalId: rim.rimExternalId,
        productMetadata: {
            metadataType: "RIM",
            colorGroup: rim.colorGroup,
            width: rim.width,
            offset: rim.offset,
            boltPattern: rim.boltPattern,
            hubBoreHole: rim.hubBoreHole,
            size: rim.size,
            rimType: rim.rimType,
            certificateId: certificateId,
            certificateBlockId: certificateBlockId
        },
        priceGross: rim.priceAndStock?.price?.minSellInPriceNet
    };
}
