import React from "react";
import {useTranslation} from "react-i18next";
import { getStockColorFromDeliveryStatus, getStockColorFromQuantity } from "../../helper/Helper";
import { DeliveryStatus, StocksColor } from "../../models/shared/Enums";

interface Props {
    stock?: number;
    stockColor?: StocksColor;
    deliveryStatus?: DeliveryStatus
    className?: string;
}

export function StockInfo(props: Props) {
    const {t} = useTranslation();
    const stockColor = props.stockColor || getStockColorFromDeliveryStatus(props.deliveryStatus) || getStockColorFromQuantity(props.stock);

    const classes = `stock-info ${props.className}`;
    let iconClasses = "";
    let translationText = "";

    switch (stockColor) {
        case StocksColor.GREEN:
            iconClasses = "fas fa-check";
            translationText = "STOCK_INFO.IN_STOCK";
            break;

        case StocksColor.ORANGE:
            iconClasses = "fas fa-exclamation-triangle";
            translationText = "STOCK_INFO.FEW_IN_STOCK";
            break;

        case StocksColor.RED:
            iconClasses = "fas fa-ban";
            translationText = "STOCK_INFO.NO_STOCK";
            break;

        default:
            return null;
    }

    return (
        <div className={classes}>
            <i className={iconClasses}/>
            {t(translationText)}
        </div>
    );
}
