import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { showWinterImage } from "../../helper/Helper";
import SelectCarByHsnTsn from "./SelectCarByHsnTsn/SelectCarByHsnTsn";
import SelectCarByManualSelection from "./SelectCarByManualSelection/SelectCarByManualSelection";

interface Props {
    isRimDetailPage: boolean;
    rimId?: string;
    preSelectedManufacturer?:string;
    backgroundImage?: string;
    headline?: string;
    isOnSeoPage?: boolean;
    isExpanded?: boolean;
}

function CarSelection({isRimDetailPage, rimId, preSelectedManufacturer, backgroundImage, headline, isOnSeoPage, isExpanded}: Props) {
    const [t] = useTranslation();
    const [activeTab, setActiveTab] = useState("manualSelection");

    function getSelectionStyle(){
        if(backgroundImage){
            return {
                backgroundImage: `url('/cms${backgroundImage}')`
            }
        } else {
            return {}
        }
    }

    return (
        <div id="car-selection" style={getSelectionStyle()} className={showWinterImage() && !backgroundImage ? 'winter' : ''}>
            <div className={`container-fluid `}>
                <div className="row tab-selection-row">
                    <div className="col-12 col-md-6 col-lg-4 offset-xl-1">
                        <ul className="nav nav-tabs car-selection-list">
                            <li className={`car-selection-header ${(activeTab === "manualSelection" ? "active" : "")}`}>
                                <a onClick={() => setActiveTab("manualSelection")}
                                   className={activeTab === "manualSelection" ? "active" : ""}>
                                    {t("CAR_SELECTION.BY_MANUAL.TAB")}
                                </a>
                            </li>
                            <li className={`car-selection-header ${(activeTab === "hsnTsn" ? "active" : "")}`}>
                                <a onClick={() => setActiveTab("hsnTsn")}
                                   className={activeTab === "hsnTsn" ? "active" : ""}>
                                    {t("CAR_SELECTION.BY_HSN_TSN.TAB")}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content pb-3">
                    <div id="manualSelection"
                        className={`tab-pane ${activeTab === "manualSelection" ? "active" : ""} `}>
                        {activeTab === "manualSelection" && (
                            <SelectCarByManualSelection
                                headline={headline}
                                isRimDetailPage={isRimDetailPage}
                                rimId={rimId}
                                preSelectedManufacturer={preSelectedManufacturer}
                                isOnSeoPage={isOnSeoPage}
                                isExpanded={isExpanded}
                            />
                        )}
                    </div>
                    <div id="hsnTsn" className={"tab-pane " + (activeTab === "hsnTsn" ? "active" : "")}>
                        {activeTab === "hsnTsn" && (
                            <SelectCarByHsnTsn
                                headline={headline}
                                isRimDetailPage={isRimDetailPage}
                                rimId={rimId}
                                isOnSeoPage={isOnSeoPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarSelection;
