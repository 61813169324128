import React from "react";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface Props {
    amount: number;
    handleAmountChange: Function;
    onlyEvenNumbers?: boolean;
    readonly?: boolean;
}

function AmountInput({amount, handleAmountChange, onlyEvenNumbers, readonly = false}: Props) {
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    function onAmountChanged(newAmount: number) {
        if (newAmount < 1 && !onlyEvenNumbers) {
            handleAmountChange(1);
        } else if (newAmount < 2 && onlyEvenNumbers) {
            handleAmountChange(2);
        } else if (newAmount % 2 === 1 && onlyEvenNumbers) {
            if (newAmount > amount) {
                handleAmountChange(+newAmount + 1);
            } else {
                handleAmountChange(+newAmount - 1);
            }
        } else {
            handleAmountChange(+newAmount);
        }

    }

    return (
        <div className="custom-amount-input">
            <div className="amount-input-field-wrapper">
                <input type="number"
                       value={amount}
                       className="amount-field"
                       onChange={(e) => onAmountChanged(+e.target.value)}
                       disabled={readonly}
                />
            </div>
            {isSmallScreen &&
                <>
                    <div className="circle ms-2" onClick={() => onAmountChanged(amount - 1)}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-minus  "/>
                        </div>
                    </div>
                    <div className="circle ms-2" onClick={() => onAmountChanged(amount + 1)}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <i className="fas fa-plus "/>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default AmountInput;
