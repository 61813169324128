import {IAvailableColors, IRimConfig, ISearchRequestParams} from "../interfaces/rim-config.interface";

export async function getMountingRimByType(requestParams: ISearchRequestParams): Promise<IRimConfig> {
    return await fetch(
        process.env.REACT_APP_API +
            "mountingStage/" +
            requestParams.vehicleId +
            "/" +
            requestParams.rimId +
            "?use3D=" +
            requestParams.use3d,
        {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        }
    )
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.log(err);
        })
        .then((res) => {
            return { ...res, rimId: requestParams.rimId };
        });

}

export async function getAvailableColors(carId: string): Promise<IAvailableColors[]> {
    return await fetch(process.env.REACT_APP_API + "vehicleColors/" + carId, {
        method: 'GET',
        headers: {"Content-Type": "application/json"},
    }).then((res) => {
            return res.json()
        }
    ).catch(err => {
        console.log(err)
    })

}

export async function getColorPlate(colorId: string | undefined): Promise<any> {
    return await fetch(process.env.REACT_APP_API + "colorPlate/" + colorId, {
        method: 'GET',
        headers: {"Content-Type": "application/json"},
    }).then((res) => {
            return res.url
        }
    ).catch(err => {
        console.log(err)
    })

}
