import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../helper/useMediaQuery";
import starSvg from "../../../assets/icons/svg/star.svg";

export function PullOutMenu() {
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const rating = 4.7;
    const [t] = useTranslation();
    return isSmallScreen ? null : (
        <div className="pull-out-menu">
            <div className="item-wrapper">
                <a href="tel:+494264776930" className="item">
                    <div className="icon">
                        <i className="fas fa-phone"></i>
                    </div>
                    <div className="label">04264 77693 0</div>
                </a>
                <a href="mailto:info@wheelscompany.com" className="item">
                    <div className="icon">
                        <i className="far fa-envelope"></i>
                    </div>
                    <div className="label">info@wheelscompany.com</div>
                </a>
                <a href="https://shop-berater.com/bewertung/wheelscompany-com.html" target="_blank" className="item">
                    <div className="icon d-flex flex-column align-items-center justify-content-center px-2">
                        <i className="fas fa-users"></i>
                        <div className="d-flex">{rating}/5</div>
                        <div className="d-flex">{t('GLOBAL.RATING_TEXT')}</div>
                    </div>
                    <div className="label">
                        <div className="rating">
                            <div className="rating-upper" style={{width: 100/5*rating + '%'}}>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                            </div>
                            <div className="rating-lower">
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                                <img src={starSvg}/>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}
