import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {RimDTO} from "../../models/rims/RimSearchResultDTO";
import { NumericFormat } from "react-number-format";
import {RimItemDTO} from "../../models/rims/RimDetailDTO";

interface CarViewRimPreviewProps {
    rimGroup: RimDTO;
    selectedRimId: string;
    changeRimSize: (item: RimItemDTO) => void;
    carId: string
}

export function CarViewRimPreview({rimGroup, selectedRimId, changeRimSize, carId}: CarViewRimPreviewProps) {
    const rimSizes = [];
    const {t} = useTranslation();

    let rimDetailUrl = {
        pathname: "../felgen/details/" + selectedRimId + "/" + encodeURIComponent(carId),
        search: new URLSearchParams(location.search).toString(),
    };

    for (const item of rimGroup?.items) {
        const rimSize = item.size;
        let isSelectedRimSize = selectedRimId === item.rimId;

        if (isSelectedRimSize) {
            selectedRimId = item.rimId;
        }

        rimSizes.push(
            <div
                key={rimSize}
                className={"rim-size rim-size-" + rimSize + (isSelectedRimSize ? " selected" : "")}
                onClick={() => changeRimSize(item)}
            >
                {rimSize}
            </div>
        );
    }

    function getPrice() {
        if (rimGroup.items) {
            for (const item of rimGroup.items) {
                if (item.rimId === selectedRimId) {
                    return item.price?.minSellInPriceNet ? (+item.price?.minSellInPriceNet * 4) : t("RIMS.SEARCH.PRICE_BY_REQUEST");
                }
            }
            return rimGroup.items[0].price?.minSellInPriceNet ? (+rimGroup.items[0].price?.minSellInPriceNet * 4) : t("RIMS.SEARCH.PRICE_BY_REQUEST");
        }
    }

    return (
        <div className="d-none d-lg-block rim-preview">
            <div className="rim-title">
                <strong>{rimGroup.manufacturer} </strong><br/>
                {rimGroup.name}
            </div>
            <div className="py-1">{rimGroup.colourGroup}</div>
            <div className="py-1 rim-size-wrapper">{rimSizes}</div>
            <div className="py-1">
                {t("RIMS.SEARCH.STARTING_AT")}&nbsp;
                <span className="text-highlight fw-bold">
                    <NumericFormat
                        value={getPrice()}
                        displayType="text"
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="€"
                    />
                </span>
            </div>
            <div className="py-1">
                <Link to={rimDetailUrl} className="btn btn-primary">{t("GLOBAL.BTN.DETAILS")}</Link>
            </div>
        </div>
    );
}
