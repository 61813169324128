import { WishlistResponse } from "../../models/wishlist/WishlistResponse";
import axiosInstance from "./AxiosInstance";

export function getWishlist() {
    return axiosInstance.get<WishlistResponse>(`/api/wishlist`)
        .then(res => res.data);
}
export function getWishlistCount() {
    return axiosInstance.get<number>(`/api/wishlist/count`)
        .then(res => res.data);
}

export function addArticleToWishlist(article) {
    return axiosInstance.post<WishlistResponse>(`/api/wishlist`, article)
        .then(res => res.data);
}

export function deleteArticleFromWishlist(positionId) {
    return axiosInstance.delete<void>(`/api/wishlist/${positionId}`)
        .then(res => res.data);
}
