import React, {useContext} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CartDTO} from "../../../models/cart/CartDTO";
import ShoppingCartFilled from "./ShoppingCartFilled";
import ShoppingCartEmpty from "./ShoppingCartEmpty";
import {ShoppingCartContext} from "../ShoppingCart";
import LoadingDetails from "../../../components/LoadingDetails/LoadingDetails";

interface ShoppingCartWrapperProps {
    cart: CartDTO;
}

function ShoppingCartWrapper(props: ShoppingCartWrapperProps) {
    const [t] = useTranslation();
    const {isFirstCheck} = useContext(ShoppingCartContext);

    return isFirstCheck && props.cart?.positions?.length === 0
        ? <LoadingDetails/>
        : props.cart?.positions?.length === 0
            ? <ShoppingCartEmpty/>
            : <ShoppingCartFilled/>
}


const mapStateToProps = state => ({
    cart: state.shoppingcart
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartWrapper);
