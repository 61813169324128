import React from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { getReactSelectStyles } from "../../helper/Helper";
import { ReactSelectOption } from "../../models/shared/ReactSelectOption";
import { InputInterface } from "./Input";
import { InputHelper } from "./InputHelper";

interface InputSelectProps extends InputInterface {
    isMulti: boolean;
    hideClear?: boolean;
}
export function InputSelect(props: InputSelectProps) {
    const reactSelectStyles = getReactSelectStyles(1000);
    const [t] = useTranslation();

    const selectChanged = (values: ReactSelectOption | ReactSelectOption[]) => {
        if (Array.isArray(values)) {
            props.onChange(values.map((v) => v.value));
        } else if (values) {
            props.onChange([values.value]);
        } else {
            props.onChange([]);
        }
    };

    const formatOptionLabel = (option) => {
        if (props?.translateValue) {
            return <div>{t("INPUT.TRANSLATE_VALUES." + option.label)}</div>;
        } else if (props?.formatNumber) {
            return <NumericFormat value={option.label} displayType="text" decimalScale={0} />;
        }
        return <div>{option.label}</div>;
    };
    const addClearingOption = !props.required && props.hideClear;
    return (
        <Select
            isMulti={props.isMulti}
            name={props.name}
            options={InputHelper.formatToReactSelectMultiOptions(props.options, addClearingOption)}
            defaultValue={InputHelper.formatToReactSelectOptions(props.defaultValue)}
            value={InputHelper.formatToReactSelectOptions(props.value)}
            formatOptionLabel={formatOptionLabel}
            onChange={selectChanged}
            placeholder={t(props.placeholder ? props.placeholder : "INPUT.PLACEHOLDER.SELECT")}
            isDisabled={props.readOnly}
            isClearable={!props.required && !props.hideClear}
            styles={reactSelectStyles}
        />
    );
}
