import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    close: Function;
}

function PaymentCanceledModal(props: Props) {
    const { t } = useTranslation();

    const handleClose = () => {
        props.close();
    };

    return (
        <div className="custom-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="custom-modal-title">
                        {t("PAY_PAL.CANCELED.TITLE")}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    {t("PAY_PAL.CANCELED.TEXT")}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleClose}
                    >
                        {t("PAY_PAL.CANCELED.CLOSE")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PaymentCanceledModal;
