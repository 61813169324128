import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CartDTO } from "../../models/cart/CartDTO";
import { PayPalButtonWrapper } from "./PayPalButtonWrapper";

interface PaypalIntegrationProps {
    canSubmit: boolean;
    cart: CartDTO;
}

export function PaypalIntegration({ canSubmit, cart }: PaypalIntegrationProps) {
    const [t] = useTranslation();

    const beforeUnloadListener = useCallback((event) => {
        event.preventDefault();
        return event.returnValue = t("CHECKOUT.PAYMENT_IN_PROGRESS");
    }, []);

    function updatePaymentInProgress(val: boolean) {
        if (val) {
            addEventListener("beforeunload", beforeUnloadListener);
        } else {
            removeEventListener('beforeunload', beforeUnloadListener);
        }
    }
    let paypalClientId = ""
    if(typeof window !== 'undefined') {
        paypalClientId = window.paypalClientId
    } else {
        paypalClientId = process?.env?.PAYPAL_CLIENT_ID
    }

    return (
        <>
            <PayPalScriptProvider
                options={{
                    clientId: paypalClientId,
                    enableFunding: "paylater",
                    disableFunding: "sepa",
                    currency: "EUR",
                    components: "buttons,messages",
                    locale: "de_DE"
                }}
            >
                <PayPalButtonWrapper canSubmit={canSubmit} cart={cart}></PayPalButtonWrapper>
            </PayPalScriptProvider>
            <div className="pay-pal-info">
                {t("PAY_PAL.INFO_1")}
                <a
                    href="/AGB.pdf"
                    title={t("PAY_PAL.AGBS")}
                    target="_blank"
                    className="highlighted mx-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    {t("PAY_PAL.AGBS")}
                </a>
                {t("PAY_PAL.INFO_2")}
                <Link
                    to="/widerruf"
                    target="_blank"
                    className="highlighted mx-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    {t("PAY_PAL.CANCELLATION_POLICY")}
                </Link>
                {t("PAY_PAL.INFO_3")}
            </div>
        </>
    );
}
