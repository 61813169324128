import { PaymentMethod } from "../models/cart/CartDTO";

export class AnalyticsApi {
    static trackImpressions(impressions: Array<AnalyticsProduct>) {
        if (impressions?.length > 0) {
            gtag("event", "view_item_list", {
                item_list_id: "search_results",
                item_list_name: "Suchergebnisse",
                items: impressions.map((impression, index) => {
                    return {
                        ...impression,
                        index: index,
                        item_list_id: "search_results",
                        item_list_name: "Suchergebnisse",
                    };
                }),
            });
        }
    }

    static trackClick(productObj: Array<AnalyticsProduct>, index?: number) {
        index = +index || 0;
        gtag("event", "select_item", {
            item_list_id: "search_results",
            item_list_name: "Suchergebnisse",
            items: productObj.map((p, i)=> {
                return {...p, index: index+i};
            }),
        });
    }

    static trackDetails(productObj: Array<AnalyticsProduct>) {
        gtag('event', 'view_item',{
            currency: 'EUR',
            items: productObj,
        });
    }

    static trackAddToCart(products: Array<AnalyticsProduct & {quantity: number}>){
        gtag("event", "add_to_cart", {
            currency: 'EUR',
            value: products.reduce(this.sumProductPrices, 0),
            items: products
        });
    }

    static trackRemoveFromCart(products: Array<AnalyticsProduct & {quantity: number}>){
        gtag("event", "remove_from_cart", {
            currency: 'EUR',
            value: products.reduce(this.sumProductPrices, 0),
            items: products
        });
    }

    static trackBeginCheckout(products: Array<AnalyticsProduct>, coupon?: string) {
        gtag("event", "begin_checkout", {
            currency: "EUR",
            value: products.reduce(this.sumProductPrices, 0),
            coupon: coupon,
            items: products
        });
    }
    static trackPurchase(action: AnalyticsPurchaseAction) {
        gtag("event", "purchase", action)
    }

    static trackPaymentInfo(paymentMethod: PaymentMethod, items: Array<AnalyticsProduct>) {
        gtag("event", "begin_checkout", {
            currency: "EUR",
            value: items.reduce(this.sumProductPrices, 0),
            payment_type: paymentMethod,
            items: items
        });
    }

    static setDefaultGAConsent(){
        // gtag('consent', 'default', {
        //     'ad_storage': `granted`,
        //     'ad_user_data': `granted`,
        //     'ad_personalization': `granted`,
        //     'analytics_storage': `granted`
        // });
    }

    static updateGAConsent(granted: boolean = false){
        const state = granted ? "granted" : "denied";
        gtag('consent', 'update', {
            'ad_storage': `${state}`,
            'ad_user_data': `${state}`,
            'ad_personalization': `${state}`,
            'analytics_storage': `${state}`
        });
    }

    private static sumProductPrices(previousValue: number, currentValue: AnalyticsProduct, currentIndex: number, array: AnalyticsProduct[]) {
        return previousValue + (currentValue.quantity * currentValue.price);
    }
}

export interface AnalyticsProduct {
    item_name: string;
    item_id: string;
    price?: number;
    item_brand?: string;
    item_category?: string;
    item_variant?: string;
    quantity?: number;
    affiliation?: string
}
export interface AnalyticsPurchaseAction {
    transaction_id: string,
    value: number,
    tax: number,
    shipping: number,
    currency: string,
    coupon: string,
    items: Array<AnalyticsProduct & {}>
}
