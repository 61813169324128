import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import {Typeahead} from "react-bootstrap-typeahead";
import {TradeName} from "../../components/TradeName/TradeName";
import {UserRegistrationDTO} from "../../models/registration/UserRegistrationDTO";
import {register} from "../../redux-store/api/UserApi";
import {CountryOptionType} from "../../models/shared/Types";
import { CountryCodes, countryZipRegex, getCountryList } from "../../models/checkout/CountryCodes";
import {getFormFieldValidationStyles} from "../../helper/formikHelper";
import ConfirmationPage from "../../components/ConfirmationPage/ConfirmationPage";

function Registration() {
    const [t] = useTranslation();
    const [registered, setRegistered] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("REGISTER.REGISTRATION_ERROR.INFO_1");

    const validationSchema = yup.object().shape({
        firstName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        lastName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        street: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        zipCode: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")).when(['country'], (country:any, schema) => {
            return country ? schema.matches(countryZipRegex[country], t("FORMS.VALIDATIONS.ZIP_FORMAT")) : schema;
        }),
        city: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        country: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        phoneNumber: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        username: yup.string()
            .email(t("FORMS.VALIDATIONS.MAIL_PATTERN"))
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        usernameRepeat: yup.string()
            .oneOf([yup.ref("username"), ""], t("FORMS.VALIDATIONS.MAILS_MUST_MATCH"))
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        password: yup.string()
            .min(8, t("FORMS.VALIDATIONS.MIN_PASSWORD_LENGTH"))
            .matches(/(?=.*[0-9])/, {message: t("FORMS.VALIDATIONS.NO_VALID_PASSWORD"), excludeEmptyString: true})
            .required(t("FORMS.VALIDATIONS.REQUIRED")),
        confirmPassword: yup.string()
            .oneOf([yup.ref("password"), ""], t("FORMS.VALIDATIONS.PASSWORDS_MUST_MATCH"))
            .required(t("FORMS.VALIDATIONS.REQUIRED"))
    });
    const countryOptions: CountryOptionType[] = getCountryList(t);
    const initialValues = {
        title: "",
        firstName: "",
        lastName: "",
        street: "",
        zipCode: "",
        city: "",
        country: CountryCodes.DE,
        phoneNumber: "",
        username: "",
        usernameRepeat: "",
        password: "",
        confirmPassword: ""
    };

    function submitForm(data: FormikValues) {
        const registrationData: UserRegistrationDTO = {
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            street: data.street,
            zipCode: data.zipCode,
            city: data.city,
            country: data.country[0].code,
            phoneNumber: data.phoneNumber,
            username: data.username,
            password: data.password,
            locale: "de_DE"
        };
        register(registrationData).then((res) => {
            if (res.status === 200) {
                setRegistered(true);
                setError(false);
            }
        }).catch(err => {
            if (err.response.status === 409) {
                setErrorMessage("REGISTER.REGISTRATION_ERROR.MAIL_ALREADY_EXISTS");
            } else {
                setErrorMessage("REGISTER.REGISTRATION_ERROR.INFO_1");
            }
            setRegistered(true);
            setError(true);
        });
    }

    return (
        <div className="registration-wrapper">
            {registered &&
                <ConfirmationPage
                    title={error ? t("REGISTER.REGISTRATION_ERROR.TITLE") : t("REGISTER.REGISTRATION_SUCCESS.TITLE")}
                    text1={error ? t(errorMessage) : t("REGISTER.REGISTRATION_SUCCESS.INFO_1")}
                    text2={error ? "" : t("REGISTER.REGISTRATION_SUCCESS.INFO_2")}
                    error={error}
                />
            }
            {!registered && (
                <>
                    <TradeName>{t("REGISTER.CREATE_ACCOUNT")}</TradeName>
                    <div className="container mb-3">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(data) => submitForm(data)}
                        >
                            {({
                                  handleBlur,
                                  touched,
                                  setFieldValue,
                                  errors,
                                  values
                              }) => (
                                <Form>
                                    <div className="row">
                                        <fieldset className="col-12 my-2" onClick={(e) => { // <- note: onClick not onChange
                                            // Check if currently selected value in group is the currently clicked item and its already active
                                            const target: HTMLInputElement = e.target as HTMLInputElement
                                            if (values.title === target.id && target.checked) {
                                                target.checked = false; // deactivate it
                                                setFieldValue("title", null) // set value to null since nothing is selected
                                            }
                                        }}>
                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input"
                                                       type="radio"
                                                       name="title"
                                                       id={t("FORMS.CUSTOMER_DATA.MR")}
                                                       value={t("FORMS.CUSTOMER_DATA.MR")}
                                                />
                                                <label className="form-check-label"
                                                       htmlFor={t("FORMS.CUSTOMER_DATA.MR")}>
                                                    {t("FORMS.CUSTOMER_DATA.MR")}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input"
                                                       type="radio"
                                                       name="title"
                                                       id={t("FORMS.CUSTOMER_DATA.MS")}
                                                       value={t("FORMS.CUSTOMER_DATA.MS")}
                                                />
                                                <label className="form-check-label"
                                                       htmlFor={t("FORMS.CUSTOMER_DATA.MS")}>
                                                    {t("FORMS.CUSTOMER_DATA.MS")}
                                                </label>
                                            </div>
                                        </fieldset>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "firstName")}
                                                type="text"
                                                name="firstName"
                                                placeholder={t("FORMS.CUSTOMER_DATA.FIRST_NAME")}
                                                required
                                            />
                                            <ErrorMessage name="firstName"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "lastName")}
                                                type="text"
                                                name="lastName"
                                                placeholder={t("FORMS.CUSTOMER_DATA.LAST_NAME")}
                                                required
                                            />
                                            <ErrorMessage name="lastName"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "street")}
                                                type="text"
                                                name="street"
                                                placeholder={t("FORMS.CUSTOMER_DATA.STREET")}
                                                required
                                            />
                                            <ErrorMessage name="street"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "zipCode")}
                                                type="text"
                                                name="zipCode"
                                                placeholder={t("FORMS.CUSTOMER_DATA.ZIP_CODE")}
                                                required
                                            />
                                            <ErrorMessage name="zipCode"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "city")}
                                                type="text"
                                                name="city"
                                                placeholder={t("FORMS.CUSTOMER_DATA.CITY")}
                                                required
                                            />
                                            <ErrorMessage name="city"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                name="country"
                                            >
                                                {({
                                                      form: {values}
                                                  }) => (
                                                    <div>
                                                        <Typeahead options={countryOptions}
                                                                   id="country-autocomplete"
                                                                   inputProps={{
                                                                       autoComplete: "nope",
                                                                       required: true,
                                                                       name: "country",
                                                                       className: getFormFieldValidationStyles(errors, touched, "country")
                                                                   }}
                                                                   paginate={false}
                                                                   placeholder={t("FORMS.CUSTOMER_DATA.COUNTRY")}
                                                                   emptyLabel={t("FORMS.CUSTOMER_DATA.NO_MATCHED_COUNTRIES")}
                                                                   onChange={(e: CountryOptionType[]) => {
                                                                       setFieldValue("country", e[0]?.code);
                                                                   }}
                                                                   onBlur={handleBlur}
                                                                   selected={values.country ? [{code: values.country,
                                                                       label: t("ENUMS.COUNTRY_CODES." + values.country)}] : []}
                                                        />
                                                        <ErrorMessage name="country"
                                                                      render={msg => <div
                                                                          className="invalid-feedback">{msg}</div>}/>
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field autoComplete="off"
                                                   className={getFormFieldValidationStyles(errors, touched, "phoneNumber")}
                                                   type="tel"
                                                   name="phoneNumber"
                                                   placeholder={t("FORMS.CUSTOMER_DATA.PHONE")}
                                                   required
                                            />
                                            <ErrorMessage name="phoneNumber"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field autoComplete="off"
                                                   className={getFormFieldValidationStyles(errors, touched, "username")}
                                                   type="email"
                                                   name="username"
                                                   placeholder={t("FORMS.CUSTOMER_DATA.MAIL")}
                                                   required
                                            />
                                            <ErrorMessage name="username"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "usernameRepeat")}
                                                type="email"
                                                name="usernameRepeat"
                                                placeholder={t("FORMS.CUSTOMER_DATA.MAIL_CONFIRM")}
                                                required
                                            />
                                            <ErrorMessage name="usernameRepeat"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field autoComplete="off"
                                                   className={getFormFieldValidationStyles(errors, touched, "password")}
                                                   type="password"
                                                   name="password"
                                                   placeholder={t("FORMS.CUSTOMER_DATA.PASSWORD")}
                                                   required
                                            />
                                            <ErrorMessage name="password"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 col-sm-6 my-2">
                                            <Field
                                                className={getFormFieldValidationStyles(errors, touched, "confirmPassword")}
                                                type="password"
                                                name="confirmPassword"
                                                placeholder={t("FORMS.CUSTOMER_DATA.PASSWORD_CONFIRM")}
                                                required
                                            />
                                            <ErrorMessage name="confirmPassword"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                        <div className="col-12 mt-3 text-end">
                                            <button type="submit"
                                                    className="btn btn-primary"
                                            >
                                                {t("REGISTER.CREATE_ACCOUNT")}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            )}
        </div>
    );
}

export default Registration;
