import React, {useState} from "react";
import {TradeName} from "../../TradeName/TradeName";
import {useTranslation} from "react-i18next";

interface Props {
    backgrounds: Array<string>
    selectedBackground: string;
    backgroundChanged?: Function;
    isSmallScreen: boolean;
}

export default function CarViewBackgrounds(props: Props) {
    const [t] = useTranslation();
    const [currentBackground, setCurrentBackground] = useState(props.selectedBackground);

    function getButtonBar(){
        return (
            <div className={`${!props.isSmallScreen ? 'd-grid mt-4' : 'car-view-rim-page__button'} `}>
                <button className="btn w-100 btn-primary mt-2 fw-normal"
                        onClick={() => props.backgroundChanged(currentBackground)}>
                    {t('CAR_VIEW.CLOSE')}
                </button>
            </div>
        );
    }

    return (
        <div className={`${props.isSmallScreen ? 'd-flex flex-column justify-content-between h-100' : ''} `}>
            <div className={`title-container mb-0`}>
                <h1 className={`title ${props.isSmallScreen ? 'mobile' : ''}`}>
                    {t('CAR_VIEW.BACKGROUND')}
                </h1>
            </div>
            <div className="p-3 h-75">
                <div className="car-background-tiles">
                    {props.backgrounds.map((bg) => (
                        <div key={bg}
                            onClick={() => {
                                setCurrentBackground(bg);
                                props.backgroundChanged(bg, true);
                            }}
                            className={`background ${bg} ${
                                bg === currentBackground ? "current" : ""
                            }`}
                        />
                    ))}
                </div>
                {!props.isSmallScreen && getButtonBar()}
            </div>
            {props.isSmallScreen && getButtonBar()}
        </div>
    );
}