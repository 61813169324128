import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import CustomModal from "../../components/CustomModal/CustomModal";
import {TradeName} from "../../components/TradeName/TradeName";
import {CartDTO} from "../../models/cart/CartDTO";
import {CheckoutSteps} from "../../models/checkout/CheckoutSteps";
import {changePaymentMethod} from "../../redux-store/api/ShoppingCartApi";
import CustomerData from "./CustomerData/CustomerData";
import OrderSummary from "./OrderSummary/OrderSummary";
import PaymentCanceledModal from "./Payment/PaymentCanceledModal";
import PaymentAndShipping from "./PaymentAndShipping/PaymentAndShipping";
import { Stepper } from 'react-form-stepper';
import {StepStyleDTO} from "react-form-stepper/dist/components/Step/StepTypes";
import {ConnectorStyleProps} from "react-form-stepper/dist/components/Connector/ConnectorTypes";
import {scrollToTopFn} from "../../components/ScrollToTop";

const checkoutSteps = [CheckoutSteps.CUSTOMER_DATA, CheckoutSteps.PAYMENT_METHOD, CheckoutSteps.ORDER_SUMMARY];

const stepStyle: StepStyleDTO = {
    activeBgColor: "#e19a00",
    activeTextColor: "#fff",
    completedBgColor: "#393939",
    completedTextColor: "#fff",
    inactiveBgColor: "#b7bbc0",
    inactiveTextColor: "#fff",
    size: "2.5em",
    circleFontSize: "1rem",
    labelFontSize: "1rem",
    borderRadius: "50%",
    fontWeight: "700"
};

const connectorStyle: ConnectorStyleProps = {
    disabledColor: "#b7bbc0",
    activeColor: "#b7bbc0",
    completedColor: "#b7bbc0",
    size: 2,
    style: 'solid'
}

function Checkout() {
    const [t] = useTranslation();
    const location = useLocation();
    const [cart, setCart] = useState<CartDTO>(undefined);
    const [currentStep, setCurrentStep] = useState(new URLSearchParams(location.search).has("paymentCanceled") ? 1 : 0);
    const [paymentCanceled, setPaymentCanceled] = useState(new URLSearchParams(location.search).has("paymentCanceled"));

    function nextStep() {
        const newStep = currentStep + 1;
        if (newStep >= 0 && newStep < checkoutSteps.length) {
            setCurrentStep(newStep);
            scrollToTopFn();
        }
    }

    function previousStep() {
        const previousStep = currentStep - 1;
        if (previousStep >= 0) {
            setCurrentStep(previousStep);
            scrollToTopFn();
        }
    }

    function updateCart(cart: CartDTO) {
        setCart(cart);
    }

    function updateShipping(paymentMethod) {
        changePaymentMethod(paymentMethod).then((cart: CartDTO) => {
            updateCart(cart);
            nextStep();
        });
    }

    function renderScreen() {
        switch (currentStep) {
            case 0:
                return <CustomerData nextStep={nextStep} updateCart={updateCart} cart={cart}/>;
            case 1:
                return (
                    <PaymentAndShipping
                        previousStep={previousStep}
                        submit={(paymentMethod) => updateShipping(paymentMethod)}
                        selectedPaymentMethod={cart?.paymentMethod}
                        cart={cart}
                        updateCartHandler={updateCart}
                    />
                );
            case 2:
                return <OrderSummary cart={cart} previousStep={previousStep}
                                     updateCartHandler={updateCart}/>;
            default:
                break;
        }
    }

    useEffect(() => {
        scrollToTopFn();
    }, []);


    return (
        <>
            <TradeName>{t("CHECKOUT.TITLE")}</TradeName>
            <div className="container">
                <Helmet>
                    <title>{t("CHECKOUT.TITLE")}</title>
                </Helmet>
                <Stepper
                    steps={[
                        {label: t("CHECKOUT.STEPS." + CheckoutSteps.CUSTOMER_DATA)},
                        {label: t("CHECKOUT.STEPS." + CheckoutSteps.PAYMENT_METHOD)},
                        {label: t("CHECKOUT.STEPS." +  CheckoutSteps.ORDER_SUMMARY)}]}
                    activeStep={currentStep}
                    styleConfig={stepStyle}
                    connectorStyleConfig={connectorStyle}
                />
                {renderScreen()}
            </div>

            <CustomModal open={paymentCanceled}>
                <PaymentCanceledModal close={() => setPaymentCanceled(false)}/>
            </CustomModal>
        </>
    );
}

export default Checkout;
