import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {CarDetailDTO} from "../../../../models/car/CarDetailDTO";
import {RimDetailDTO} from "../../../../models/rims/RimDetailDTO";
import {useMediaQuery} from "../../../../helper/useMediaQuery";

interface Props {
    carDetails?: CarDetailDTO;
    rimDetails?: RimDetailDTO;
    isLoading?: boolean;
}

export default function CompleteWheelBreadcrumb({carDetails, rimDetails, isLoading}: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 767.98px)');

    return (
        <>
            {!isLoading &&
            <div className="border-box">
                <div className="box-title">
                    <div className="text-uppercase">{t("TYRES.SEARCH.BREADCRUMB.TITLE")}</div>
                </div>
                <div className={`box-content complete-wheel-breadcrumb ${isSmallScreen ? 'flex-column text-center' : ''}`}>
                    <div className="breadcrumb-entry mx-4">
                        <div className="title">
                            {t("TYRES.SEARCH.BREADCRUMB.CAR")}
                        </div>
                        <div className="content">
                            {carDetails?.tradeName}
                        </div>
                        <div>
                            <Link to={`/felgen/details/${rimDetails?.rimFront?.rimExternalId}`}>
                                {t("TYRES.SEARCH.BREADCRUMB.CHANGE_CAR")}
                            </Link>
                        </div>
                    </div>
                    <div>
                        {isSmallScreen ? <i className="fa fa-chevron-down"/> :
                            <i className="fa fa-chevron-right"/>
                        }
                    </div>
                    <div className="breadcrumb-entry mx-4">
                        <div className="title">
                            {t("TYRES.SEARCH.BREADCRUMB.RIM")}
                        </div>
                        <div className="content">
                            {!rimDetails?.rimRear &&
                            <div>
                                <img src="/car_front_rear_wheels.svg"
                                     className="axis small me-2"
                                     alt="car_front_rear_wheels.svg"
                                />
                                {rimDetails?.rimFront.rimManufacturer} {rimDetails?.rimFront.rimType} {rimDetails?.rimFront.width}x{rimDetails?.rimFront.size} ET{rimDetails?.rimFront.offset}
                            </div>
                            }
                            {rimDetails?.rimRear &&
                            <>
                                <div>
                                    <img src="/car_front_wheel.svg"
                                         className="axis small me-2"
                                         alt="car_front_wheel.svg"
                                    />
                                    {rimDetails?.rimFront.rimManufacturer} {rimDetails?.rimFront.rimType} {rimDetails?.rimFront.width}x{rimDetails?.rimFront.size} ET{rimDetails?.rimFront.offset}
                                </div>
                                <div>
                                    <img src="/car_rear_wheel.svg"
                                         className="axis small me-2"
                                         alt="car_rear_wheel.svg"
                                    />
                                    {rimDetails?.rimRear.rimManufacturer} {rimDetails?.rimRear.rimType} {rimDetails?.rimRear.width}x{rimDetails?.rimRear.size} ET{rimDetails?.rimRear.offset}
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
            {isLoading &&
            <div className="complete-wheel-breadcrumb loading"/>
            }
        </>
    );
}
