import React from "react";
import {useTranslation} from "react-i18next";

function Approach() {
    const [t] = useTranslation();

    return (
        <div className="approach-wrapper">
            <div className="container-fluid">
                <p className={"d-none d-md-flex flex-column justify-content-start approach-address"}>
                    <span className={"d-block"}>Wheelscompany GmbH</span>
                    <span className={"d-block"}>Carl-von-Linde-Str. 6</span>
                    <span className={"d-block"}>27367 Sottrum</span>
                </p>
                <h3>
                {/*<span className="d-none d-md-inline-block">{t('HOME.APPROACH.VISIT')}&nbsp;</span>*/}
                {/*<span className="text-highlight">{t('HOME.APPROACH.GERMANYS')}&nbsp;</span>*/}
                {/*<span className="text-highlight">{t('HOME.APPROACH.BIGGEST')}&nbsp;</span>*/}
                {/*<span>{t('HOME.APPROACH.RIM_EXHIBITION')}</span>*/}

                {/*<small className="d-none d-md-inline-block">Wheelscompany GmbH<br/>Carl-von-Linde-Str. 6<br/>27367 Sottrum</small>*/}
                <small className="d-inline-block d-md-none">Wheelscompany GmbH <br/> Carl-von-Linde-Str. 6 <br/> 27367 Sottrum</small>
                </h3>
                <div className="tile-wrapper">
                    <div className="tile">
                        <img src="/home/map.png" alt="map.png"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Approach;
