import Tooltip from "rc-tooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import { TyreDTO } from "../../../../models/tyres/TyreDTO";

interface Props {
    tyre: TyreDTO;
}

function TyreDetailsTable({ tyre }: Props) {
    const [t] = useTranslation();
    return (
        <div>
            <div className="row mb-3 mt-3 mt-sm-0">
                <div className="col-11 ps-0">
                    <h3>
                        <div className="tyre-info" />
                        <b>{`${tyre.width}/${tyre.section} R${tyre.diameter} ${tyre.loadIndex} ${tyre.speedIndex}`}</b>
                    </h3>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info" />
                    {t("TYRES.DETAILS.BRAND_NAME")}:
                </div>
                <div className="col-6">
                    <b>{tyre.brandName}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info" />
                    {t("TYRES.DETAILS.TREAD")}:
                </div>
                <div className="col-6">
                    <b>{tyre.tread}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info" />
                    {t("TYRES.DETAILS.VEHICLE_TYPE")}:
                </div>
                <div className="col-6">
                    {tyre.vehicleType &&
                      <span><b>{t("TYRES.SEARCH.VEHICLE_TYPES." + tyre.vehicleType)}</b></span>
                    }
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info" />
                    {t("TYRES.DETAILS.SEASON")}:
                </div>
                <div className="col-6">
                    {tyre.season &&
                      <span><b>{t("TYRES.SEARCH.SEASONS." + tyre.season)}</b></span>
                    }
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.WIDTH")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.TYRE_SIZE_WIDTH")}</div>
                                <div><strong>{t("TYRES.DETAILS.SECTION")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.TYRE_SIZE_SECTION")}</div>
                                <div><strong>{t("TYRES.DETAILS.DIAMETER")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.TYRE_SIZE_DIAMETER")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.TYRE_SIZE")}:
                </div>
                <div className="col-6">
                    <b>{`${tyre.width}/${tyre.section} R${tyre.diameter}`}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.LOAD_INDEX")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.LOAD_INDEX")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.LOAD_INDEX")}:
                </div>
                <div className="col-6">
                    <b>{tyre.loadIndex}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.SPEED_INDEX")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.SPEED_INDEX")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.SPEED_INDEX")}:
                </div>
                <div className="col-6">
                    <b>{tyre.speedIndex}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.ROLLING_RESISTANCE")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.ROLLING_RESISTANCE")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.ROLLING_RESISTANCE")}:
                </div>
                <div className="col-6">
                    <b>{tyre.tyreEULabelDTO?.rollingResistance}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.WET_GRIP")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.WET_GRIP")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.WET_GRIP")}:
                </div>
                <div className="col-6">
                    <b>{tyre.tyreEULabelDTO?.wetGrip}</b>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex ps-0">
                    <div className="tyre-info">
                        <Tooltip placement="right" trigger={["click", "hover"]} overlay={
                            <div className="text-start">
                                <div><strong>{t("TYRES.DETAILS.NOISE_EMISSION")}:</strong></div>
                                <div>{t("TYRES.DETAILS.INFO_TEXTS.NOISE_EMISSION")}</div>
                            </div>
                        }>
                            <i className="fa fa-info-circle" />
                        </Tooltip>
                    </div>
                    {t("TYRES.DETAILS.NOISE_EMISSION")}:
                </div>
                <div className="col-6">
                    <b>{tyre.tyreEULabelDTO?.noiseEmission}</b>
                </div>
            </div>
        </div>
    );
}

export default TyreDetailsTable;
