import {CarDetailDTO} from "../../models/car/CarDetailDTO";
import {IRimConfig} from "../../bmf3dImages/interfaces/rim-config.interface";
import {useTranslation} from "react-i18next";
import React from "react";

interface CustomModalProps {
    open: boolean;
    close: Function
    carDetails: CarDetailDTO;
    selectedColorId: any;
    carImageUrl: string;
    rimConfigData: IRimConfig;
}

export default function MobileCarViewModal(props: CustomModalProps) {

    if(!open){
        return null;
    }

    const {t} = useTranslation();
    let showCarImage = true;
    if (props.rimConfigData?.numberOfImagesAngles === 1) {
        for (let layer of props.rimConfigData?.layers) {
            showCarImage = showCarImage && !layer?.images?.[0].includes("noCarImage");
        }
    }
    const carDetailsContent: { key: string, data: string }[] = [
        {key: "TYPE", data: props.carDetails.typ},
        {
            key: "HSN_TSN_VSN",
            data: `${props.carDetails.hsn} / ${props.carDetails.tsn} / ${props.carDetails.vsn}`
        },
        {key: "KW_PS", data: `${props.carDetails.kw} / ${props.carDetails.ps}`},
        {key: "ABE_EG", data: props.carDetails.egabe[0]},
        {key: "PITCH_CIRCLE", data: props.carDetails.pitchCircle},
        {key: "HUB_BOREHOLE", data: props.carDetails.ilk},
        {key: "CONSTRUCTION_YEAR", data: props.carDetails.constructionYear},
        {key: "CAPACITY", data: props.carDetails.capacity},
        {key: "FUEL_TYPE", data: props.carDetails.fuelValue},
        {key: "DRIVE", data: props.carDetails.driveValue},
        {key: "DRIVEN_AXES", data: props.carDetails.axes},
    ];

    const navItems: string[] = ["car", "tyres", "tpms"];

    const handleClose = () => {props.close()};
    return (
        <section className={"mobile-modal"}>
            <div className="d-flex justify-content-between m-1 mobile-modal__sticky">
                <h5 className="custom-modal-title d-inline-block">
                    {t("CAR_DETAILS_MODAL.TITLE")}
                </h5>
                <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
            </div>

            <ul className="nav nav-pills nav-justified" id="myTab" role="tablist">
                {navItems.map((navItem, index) =>
                    <li key={"carModal-item-" + index} className="nav-item" role="presentation">
                        <a className={`nav-link ${index === 0 ? 'active' : ''}`} id="car-tab"
                           data-bs-toggle="tab" href={`#${navItem}`} role="tab"
                           aria-controls={`${navItem}`}
                           aria-selected={index == 0}>{t("CAR_DETAILS_MODAL." + navItem.toUpperCase())}</a>
                    </li>
                )}
            </ul>
            {props.carDetails &&
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="car" role="tabpanel"
                         aria-labelledby="car-tab">
                        <div className="row mt-3">
                            <div
                                className={"col-12 order-1 order-md-0" + (showCarImage ? ' col-md-6' : '')}>
                                <table className="table table-striped">
                                    <tbody>
                                    {carDetailsContent.map((carDetail, index) =>
                                        <tr key={"carModal-content-" + index}>
                                            <td>{t("CAR_DETAILS_MODAL." + carDetail.key)}</td>
                                            <td>{carDetail.data}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {showCarImage && (
                                <div className="col-12 col-md-6 order-0 order-md-1">
                                    <div className="mobile-modal__car-image">
                                        <img className={"img-fluid"} src={props.carImageUrl}/>
                                    </div>
                                </div>
                            )}{" "}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tyres" role="tabpanel" aria-labelledby="tyres-tab">
                        {props.carDetails?.coc?.length > 0 && (
                            <table className="table table-striped mt-3">
                                <thead>
                                <tr>
                                    <th>{t("CAR_DETAILS_MODAL.FRONT_AXIS")}</th>
                                    <th>{t("CAR_DETAILS_MODAL.REAR_AXIS")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.carDetails?.coc?.map((c, index) => (
                                    <tr key={"coc_" + index}>
                                        <td>
                                            {c.vwidth}/{c.vaspectratio} {c.vconst}{c.vdim} {c.vload}{c.vspeed}<br/>
                                            {c.vrimwidth}x{c.vdim} {t("CAR_DETAILS_MODAL.OFFSET_SHORT")} {c.vet}
                                        </td>
                                        <td>
                                            {c.hwidth}/{c.haspectratio} {c.hconst}{c.hdim} {c.hload}{c.hspeed}<br/>
                                            {c.hrimwidth}x{c.hdim} {t("CAR_DETAILS_MODAL.OFFSET_SHORT")} {c.het}
                                        </td>
                                    </tr>
                                ))
                                }
                                </tbody>
                            </table>
                        )}
                        {props.carDetails?.coc?.length <= 0 && (
                            <div className="text-center fw-bold mt-3">
                                {t("CAR_DETAILS_MODAL.NO_INFORMATION_AVAILABLE")}
                            </div>
                        )}
                    </div>
                    <div className="tab-pane fade" id="tpms" role="tabpanel" aria-labelledby="tpms-tab">
                        {props.carDetails?.tpmsList?.length > 0 && (
                            <table className="table table-striped mt-3">
                                <tbody>
                                {props.carDetails?.tpmsList?.map(tpms => (
                                    <tr key={tpms.manufacturerArticleId}>
                                        <td>{tpms.manufacturer}</td>
                                        <td>{tpms.description1} {tpms.description2}</td>
                                        <td>{tpms.manufacturerArticleId}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                        {props.carDetails?.tpmsList?.length <= 0 && (
                            <div className="text-center fw-bold mt-3">
                                {t("CAR_DETAILS_MODAL.NO_INFORMATION_AVAILABLE")}
                            </div>
                        )}
                    </div>
                    <div className={"mobile-modal__space"}></div>
                </div>

            }
        </section>
    )
}
