import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {CarModelV3DTO} from "../../models/car/CarModelV2DTO";
import {getCarModelsV3} from "../../redux-store/api/CarApi";
import {SelectCarByHsnTsnSearchRequestDTO} from "../CarSelection/SelectCarByHsnTsn/SelectCarByHsnTsn";
import {useLocation, useNavigate} from "react-router-dom";
import {ConstructionSeriesV3DTO} from "../../models/car/ConstructionSeriesDTO";
import Select from "react-select";
import {getReactSelectStyles} from "../../helper/Helper";
import {scrollToTopFn} from "../ScrollToTop";
import {StringHelper} from "../../helper/StringHelper";

export type CarModelSelectionRequestParams =
    ConstructionSeriesV3DTO
    | Partial<SelectCarByHsnTsnSearchRequestDTO>;

interface Props {
    selectedManufacturerId?: string;
    selectedCarClassId?: string;
    constructionSeries?: ConstructionSeriesV3DTO;
    hsnTsnRequest?: Partial<SelectCarByHsnTsnSearchRequestDTO>;
    isRimDetailPage?: boolean;
    rimId?: string;
    isManualSelection?: boolean;
    goBack?: Function;
}

function CarModelSelection(props: Props) {
    const [t] = useTranslation();
    const [carModels, setCarModels] = useState<CarModelV3DTO[]>([]);
    const [results, setResults] = useState<CarModelV3DTO[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [fuelFilter, setFuelFilter] = useState<string>();
    const [psFilter, setPsFilter] = useState<string>();
    const [yearFilter, setYearFilter] = useState<number>();
    const [pitchCircleFilter, setPitchCircleFilter] = useState<string>();

    useEffect(() => {
        scrollToTopFn();
        if (props.isManualSelection) {
            fetchCarModels(props.constructionSeries);
        } else {
            fetchCarModels(props.hsnTsnRequest);
        }
    }, []);

    function fetchCarModels(requestParams: CarModelSelectionRequestParams) {
        getCarModelsV3(requestParams).then((res) => {
            if (res.length === 1) {
                navigate(carModelSelected(res[0]));
            } else {
                setResults(res);
                setCarModels(res);
            }
        })
    }

    function carModelSelected(model: CarModelV3DTO) {
        const searchParams = new URLSearchParams(location.search).toString();
        if (props.isRimDetailPage && props.rimId) {
            return {
                pathname: '/felgen/details/' + encodeURIComponent(props.rimId) + '/' + encodeURIComponent(getModelUrl(model)),
                search: searchParams
            };
        } else {
            return {
                pathname: '/felgenkonfigurator/' + encodeURIComponent(getModelUrl(model)),
                search: searchParams
            };
        }
    }

    function getModelUrl(model: CarModelV3DTO){
        let url = model.id;
        if(model.manufacturer){
            url += `-${StringHelper.toSnakeCase(model.manufacturer)}`;
            if(model.version){
                url += `_${StringHelper.toSnakeCase(model.version)}`;
            }
        }
        return url;
    }

    function onCarModelSelected(model: CarModelV3DTO) {
        navigate(carModelSelected(model));
    }

    function buildYearOptions() {
        let max = 0;
        let min = new Date().getFullYear();
        carModels
            .filter(m => filterAll(fuelFilter, psFilter, 0, pitchCircleFilter, m))
            .forEach(el => {
                let yearFrom = el.yearFrom;
                let yearTo = el.yearTo;
                if (!yearTo || yearTo === 0) {
                    yearTo = new Date().getFullYear();
                }
                if (yearFrom < min) {
                    min = yearFrom;
                }
                if (yearTo > max) {
                    max = yearTo;
                }
            });
        let options = [];
        if (min && max) {
            options.push({
                label: t("CAR_SELECTION.CAR_LIST.ALL"),
                value: 0
            });
            for (let i = min; i <= max; i++) {
                options.push({label: i, value: i});
            }
        }
        return options;
    }

    function buildFuelOptions() {
        let fuelTypes = [];
        carModels
            .filter(m => filterAll("", psFilter, yearFilter, pitchCircleFilter, m))
            .forEach((model) => {
                fuelTypes.push(model.fuel);
            });
        let uniqueAndSorted = [...new Set(fuelTypes)].sort();
        uniqueAndSorted = uniqueAndSorted.map((fuelType) => ({
            label: fuelType,
            value: fuelType
        }));
        uniqueAndSorted.unshift({
            label: t("CAR_SELECTION.CAR_LIST.ALL"),
            value: ""
        });
        return uniqueAndSorted;
    }

    function buildPsOptions() {
        let psTypes = [];
        carModels
            .filter(m => filterAll(fuelFilter, "", yearFilter, pitchCircleFilter, m))
            .forEach((model) => {
                psTypes.push(model.ps + "PS / " + model.kw + "kW");
            });
        let uniqueAndSorted = [...new Set(psTypes.sort(sortNumeric))];
        uniqueAndSorted = uniqueAndSorted.map((psType) => ({
            label: psType,
            value: psType
        }));
        uniqueAndSorted.unshift({
            label: t("CAR_SELECTION.CAR_LIST.ALL"),
            value: ""
        });
        return uniqueAndSorted;
    }

    function buildPitchCircleOptions() {
        let pitchCircles: any[] = [];
        carModels
            .filter(model => filterAll(fuelFilter, psFilter, yearFilter, "", model))
            .forEach(model => {

                if (!pitchCircles.includes(model.pitchCircle))
                    pitchCircles.push(model.pitchCircle)
            })
        let uniqueAndSorted = [...new Set(pitchCircles.sort())];

        uniqueAndSorted = uniqueAndSorted.map((pitchCircle) => ({
            label: pitchCircle,
            value: pitchCircle
        }));
        uniqueAndSorted.unshift({
            label: t("CAR_SELECTION.CAR_LIST.ALL"),
            value: ""
        });
        return uniqueAndSorted;
    }

    function filterForFuel(e) {
        const filteredResults = carModels.filter((model) =>
            filterAll(e.value, psFilter, yearFilter, pitchCircleFilter, model));
        setFuelFilter(e.value);
        setResults(filteredResults);
    }

    function filterForYear(e) {
        const filteredResults = carModels.filter((model) =>
            filterAll(fuelFilter, psFilter, e.value, pitchCircleFilter, model));
        setYearFilter(e.value);
        setResults(filteredResults);
    }

    function filterForPS(e) {
        const filteredResults = carModels.filter((model) =>
            filterAll(fuelFilter, e.value, yearFilter, pitchCircleFilter, model));
        setPsFilter(e.value);
        setResults(filteredResults);
    }

    function filterForPitchCircle(e) {
        const filteredResults = carModels.filter(model => filterAll(fuelFilter, psFilter, yearFilter, e.value, model));
        setPitchCircleFilter(e.value);
        setResults(filteredResults);
    }

    function filterAll(fuel: string, ps: string, year: number, pitchCircle: string, model: CarModelV3DTO) {
        return filterFuel(fuel, model) && filterPS(ps, model) && filterYears(year, model) && filterPitchCircle(pitchCircle, model)
    }

    function filterFuel(fuel: string, model: CarModelV3DTO) {
        if (!fuel) {
            return true
        }
        let regex = new RegExp(fuel, "i");
        return model.fuel.match(regex)
    }

    function filterPitchCircle(pitchCircle: string, model: CarModelV3DTO) {
        if (!pitchCircle) {
            return true;
        }

        return model.pitchCircle == pitchCircle;
    }

    function filterYears(year: number, model: CarModelV3DTO) {
        let yearFrom = model.yearFrom;
        let yearTo = model.yearTo;
        if (!yearTo || yearTo === 0) {
            yearTo = new Date().getFullYear();
        }
        if (!year || year === 0) {
            return true;
        }
        return year >= yearFrom && year <= yearTo;
    }

    function filterPS(psFilter: string, model: CarModelV3DTO) {
        if (!psFilter || !psFilter.length) {
            return true;
        }
        let parts = psFilter.split('P');
        return +parts[0] === model.ps;
    }

    function sortNumeric(x, y) {
        let partsX = x.split("P");
        let partsY = y.split("P");
        return partsX[0] - partsY[0];
    }

    function goBackToPreviousStep() {
        props.goBack();
    }

    return (
        <>
            <div>
                <div className="car-model-selection row">
                    <div className="col-12 mb-4 mt-3">
                        <h1 className="mb-2">{t('CAR_SELECTION.CAR_LIST.TITLE')}</h1>
                        {props.isManualSelection && props.selectedManufacturerId && props.selectedCarClassId && props.constructionSeries && (
                            <div className="row mt-3">
                                <div className="col">
                                    <div>{props.selectedManufacturerId} {props.selectedCarClassId}</div>
                                    <div>
                                        {props.constructionSeries.type} ({(props.constructionSeries.yearTo ? props.constructionSeries.yearFrom + " - " + props.constructionSeries.yearTo : t('CAR_SELECTION.FROM') + " " + props.constructionSeries.yearFrom)})
                                    </div>
                                </div>
                                <div className="col">
                                    <img id={"car-model-selection-image"}
                                         src={props.constructionSeries.carImage ?? "/Fahrzeug_platzhalter.png"}
                                         alt={(props.constructionSeries.carImage) ?
                                             props.constructionSeries.type : "Fahrzeug.png"}
                                    />
                                </div>
                            </div>
                        )
                        }
                    </div>
                    <div className="col-12">
                        <div className="d-flex justify-content-end">
                            <div className="back-button"
                                 onClick={goBackToPreviousStep}>{t('CAR_SELECTION.BY_MANUAL.BACK')}</div>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3 className="mb-2">{t('CAR_SELECTION.CAR_LIST.QUICK_FILTER')}</h3>
                        <div className="row">
                            <div className="col-3 pe-0" id="psSelectCol">
                                <Select
                                    id="psSelect"
                                    name="psSelect"
                                    options={carModels ? buildPsOptions() : []}
                                    styles={getReactSelectStyles()}
                                    onChange={filterForPS}
                                    placeholder={t("CAR_SELECTION.CAR_LIST.LABEL.PS_KW")}
                                />
                            </div>
                            <div className="col-3 pe-0" id="fuelSelectCol">
                                <Select
                                    id="fuelSelect"
                                    name="fuelSelect"
                                    options={carModels ? buildFuelOptions() : []}
                                    styles={getReactSelectStyles()}
                                    onChange={filterForFuel}
                                    placeholder={t("CAR_SELECTION.CAR_LIST.LABEL.FUEL")}
                                />
                            </div>
                            <div className="col-3 pe-0" id="yearSelectCol">
                                <Select
                                    id="yearSelect"
                                    name="yearSelect"
                                    options={carModels ? buildYearOptions() : []}
                                    styles={getReactSelectStyles()}
                                    onChange={filterForYear}
                                    placeholder={t("CAR_SELECTION.CAR_LIST.LABEL.CONSTRUCTION_YEAR")}
                                />
                            </div>
                            <div className="col-3" id="pitchCircleSelectCol">
                                <Select
                                    id="pitchCircleSelectCol"
                                    name="pitchCircleSelectCol"
                                    options={carModels ? buildPitchCircleOptions() : []}
                                    styles={getReactSelectStyles()}
                                    onChange={filterForPitchCircle}
                                    placeholder={t("CAR_SELECTION.CAR_LIST.LABEL.PITCH_CIRCLE")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 car-model-table mt-3">
                        <h3 className="mb-2">{t('CAR_SELECTION.CAR_LIST.ALL_VARIANTS')}</h3>
                        {results && results.length > 0 ? (
                            <ul className="list-group">
                                {results.map((entry) => (
                                    <li key={entry.id} className={`list-group-item`}
                                        onClick={() => onCarModelSelected(entry)}>
                                        <table className=" ">
                                            <tbody>
                                            <tr className={"d-flex align-items-center"}>
                                                <td>
                                                    <b>{entry.yearTo === 0 && t('CAR_SELECTION.FROM') + " "}{entry.yearFrom}&nbsp;{entry.yearTo !== 0 && ' - ' + entry.yearTo}</b>
                                                    <br/>{entry.version}&nbsp;{entry.capacity}cm&sup3;
                                                    <br/>{entry.ps}PS/{entry.kw}KW&nbsp;{entry.pitchCircle} &nbsp;{entry.fuel}
                                                </td>
                                                {/*<td className={"col"}>*/}
                                                {/*    {entry.version}&nbsp;{entry.capacity}cm&sup3;*/}
                                                {/*</td>*/}
                                                {/*<td className={"col-3 px-1 no-wrap"}>*/}
                                                {/*    {entry.yearTo === 0 && t('CAR_SELECTION.FROM') + " "}{entry.yearFrom}&nbsp;{entry.yearTo !== 0 && ' - ' + entry.yearTo}*/}
                                                {/*    <br/>{entry.fuel}*/}
                                                {/*</td>*/}
                                                {/*<td className={"col-3 px-2"}>*/}
                                                {/*    {entry.ps}PS*/}
                                                {/*    <br/>{entry.kw}KW*/}
                                                {/*</td>*/}
                                                {/*<td className={"col-3 pe-1"}>*/}
                                                {/*    {entry.pitchCircle}*/}
                                                {/*</td>*/}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <h1 className="my-3 text-center">{t('CAR_SELECTION.CAR_LIST.NO_RESULTS')}</h1>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarModelSelection;
