import axiosInstance from "./AxiosInstance";
import {cleanupFiltersForRequest} from "../../helper/Helper";
import {RimTyreAssignmentRequest} from "../../models/rims/RimTyreAssignmentRequest";
import {RimSizeAssignmentRequest} from "../../models/rims/RimSizeAssignmentRequest";
import {RimSearchRequestDTO} from "../../models/rims/RimSearchRequestDTO";
import {RimTyreAssignmentDTO} from "../../models/rims/RimTyreAssignmentDTO";
import {AxiosResponse} from "axios";
import {RimSizeAssignmentDTO} from "../../models/rims/RimSizeAssignmentDTO";
import {RimDetailDTO} from "../../models/rims/RimDetailDTO";
import {RimSearchResultDTO} from "../../models/rims/RimSearchResultDTO";


export function getRims(requestBody: RimSearchRequestDTO) {
    const carId = requestBody.carId ? decodeURIComponent(requestBody.carId) : undefined;

    const path = (carId ? "/api/rim/configurator/search" : "/api/rim/search");

    const requestJson = {
        ...requestBody,
        filters: cleanupFiltersForRequest(requestBody.filters),
        carId
    };
    return axiosInstance.post<RimSearchRequestDTO, AxiosResponse<RimSearchResultDTO>>(
        path, requestJson
    ).then(res => res.data)
        .then((res: any) => {
            if (res.filters) {
                // Format Filters to Array
                if (!Array.isArray(res.filters)) {
                    res.filters = Object.values(res.filters);
                }
            }
            return res;
        });
}

export function getRimDetails(carId: string, rimFrontId: string, rimRearId?: string) {
    const rimDetailRequestData = {
        carId: carId?.split("_")[0],
        rimFrontId,
        rimRearId
    };
    return axiosInstance.post<any, AxiosResponse<RimDetailDTO>>(`/api/rim/configurator/detail`, rimDetailRequestData)
        .then(res => res.data);
}

export function getRimTyreAssignment(req: RimTyreAssignmentRequest) {
    return axiosInstance.post<RimTyreAssignmentRequest, AxiosResponse<RimTyreAssignmentDTO[]>>(`/api/rim/configurator/tyre`, req)
        .then((res) => res.data);
}

export function getRimSizeAssignments(req: RimSizeAssignmentRequest) {
    return axiosInstance.post<RimSizeAssignmentRequest, AxiosResponse<RimSizeAssignmentDTO[]>>(`/api/rim/configurator/size`, req)
        .then(res => res.data);
}

export function checkRimAvailableForCar(rimProductId: number, carId: string) {
    return axiosInstance.post(
        `/api/rim/${rimProductId}/check/${carId.split("_")[0]}`
    ).then(res => res.data).catch(() => false);
}
