import {useTranslation} from "react-i18next";
import {getConstructionSeriesV3} from "../../redux-store/api/CarApi";
import React, {useEffect, useState} from "react";
import {ConstructionSeriesV3DTO} from "../../models/car/ConstructionSeriesDTO";
import {scrollToTopFn} from "../ScrollToTop";

interface Props {
    selectedManufacturerId: string;
    selectedCarClassId: string;
    constructionSeriesSelected: Function;
    goBack: Function;
}

function ConstructionSeriesSelection(props: Props) {
    const [t] = useTranslation();
    const [constructionSeries, setConstructionSeries] = useState<ConstructionSeriesV3DTO[]>([]);

    useEffect(() => {
        scrollToTopFn();
        fetchConstructionSeries(props.selectedManufacturerId, props.selectedCarClassId);
    }, [props.selectedManufacturerId, props.selectedCarClassId]);

    function fetchConstructionSeries(manufacturerId: string, carClassId: string) {
        getConstructionSeriesV3(carClassId, manufacturerId).then((res) => {
            setConstructionSeries(res.constructionSeries);
        }).catch((error) => {
            console.error('fetchSeries', error);
        });
    }

    function goBackToPreviousStep() {
        props.goBack();
    }

    return (
        <>
            <div className="construction-series-selection row">
                <div className="col-12 mb-4 mt-3">
                    <h1 className="mb-2">{t('CAR_SELECTION.BY_MANUAL.CHOOSE_CONSTRUCTION_SERIES')}</h1>
                    <div>{props.selectedManufacturerId} {props.selectedCarClassId}</div>
                </div>
                <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <div className="back-button"
                             onClick={goBackToPreviousStep}>{t('CAR_SELECTION.BY_MANUAL.BACK')}</div>
                    </div>
                </div>
                <div className="col-12">
                    <h3 className="mb-2">{t('CAR_SELECTION.BY_MANUAL.ALL_CONSTRUCTION_SERIES')}</h3>
                </div>
                <div className="col-12">
                    <div className="grid-view scrollable">
                        {constructionSeries && constructionSeries.length > 0 ? (
                            constructionSeries.map((entry) => (
                                    <div className={`construction-series-card`}
                                         key={entry.type + entry.carBodyId}
                                         onClick={() => props.constructionSeriesSelected(entry)}>
                                        <div className="car-image mx-auto my-auto">
                                            <img className="img-fluid" src={(entry.carImage) ?
                                                entry.carImage : "/Fahrzeug_platzhalter.png"}
                                                 alt={(entry.carImage) ?
                                                     entry.type : "Fahrzeug.png"}
                                            />
                                        </div>
                                        <div>
                                            <div className="fw-bold">{entry.type}</div>
                                            <hr/>
                                            <div className="info">
                                                <div>{(entry.yearTo ? entry.yearFrom + " - " + entry.yearTo : t('CAR_SELECTION.FROM') + " " + entry.yearFrom)}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <h1 className="my-3 text-center">{t('CAR_SELECTION.CAR_LIST.NO_RESULTS')}</h1>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConstructionSeriesSelection;
