import {getShoppingCart} from "../api/ShoppingCartApi";
import {ShoppingCartActionType} from "../reducers/shoppingCartReducer";

export const storeShoppingCart = (shoppingCart) => (dispatch) => {
    dispatch({
        type: ShoppingCartActionType.STORE_SHOPPING_CART,
        payload: shoppingCart
    });
};

export const fetchShoppingCart = () => (dispatch) => {
    return getShoppingCart().then((res) => {
        storeShoppingCart(res)(dispatch);
    });
};
