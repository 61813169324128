import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { loadScript } from "../helper/loadScript";
import { loginAmazon } from "../redux-store/api";
import { SignInProps } from "../types/SignInProps";

export const AmazonSignIn = ({ updateLoggedIn }: SignInProps) => {
    const [t] = useTranslation();

    useEffect(() => {
        const src = "https://assets.loginwithamazon.com/sdk/na/login1.js";

        loadScript(src)
            .then(() => {
                amazon.Login.setClientId(window.amazonAuthClientId);
            })
            .catch(console.error);

        return () => {
            const scriptTag = document.body.querySelector(`script[src="${src}"]`);
            if (scriptTag) document.body.removeChild(scriptTag);
        };
    });

    function afterAuthorize(token) {
        loginAmazon(token).then(updateLoggedIn);
    }

    function signIn() {
        const options = {
            scope: "profile",
            response_type: "code" as any,
            scope_data: {
                profile: { essential: true },
            },
            pkce: true,
        };
        amazon.Login.authorize(options, function (response) {
            if (response.error) {
                console.log("oauth error " + response.error);
                return;
            }

            amazon.Login.retrieveToken(response.code, function (response) {
                afterAuthorize(response.access_token);
            });
        });
        return false;
    }
    return (
        <>
            <a id="amazon-signin" className="btn btn-primary d-flex justify-content-center align-items-center" onClick={() => signIn()}>
                <i className="fab fa-amazon fa-lg pe-1"></i>
                {t('LOGIN.AMAZON')}
            </a>
        </>
    );
};
