import { Location, NavigateFunction } from "react-router-dom";
import { SortName } from "../models/shared/Enums";
import { SearchSort } from "../models/shared/SearchSort";
import { SortDirection } from "../models/shared/SortDirection";

export class UrlSortHelper {
    private static prefix = "sort_";
    private static sortName = this.prefix + "name";
    private static sortDirection = this.prefix + "direction";
    private static parseRegex = new RegExp(`${UrlSortHelper.prefix}(?<name>[a-zA-Z\-_\.]+)`);

    static pushSortToHistory(location: Location, navigate: NavigateFunction, sort: SearchSort) {
        const searchParams = new URLSearchParams(location.search);
        if (sort) {
            searchParams.set(this.sortName, sort.name);
            searchParams.set(this.sortDirection, sort.direction);
        } else {
            searchParams.delete(this.sortName);
            searchParams.delete(this.sortDirection);
        }
        navigate({ search: searchParams.toString() });
    }

    static getSortFromLocation(location: Location, initialSort: SearchSort): SearchSort {
        if (location?.search) {
            const searchParams = new URLSearchParams(location?.search);
            if (searchParams.has(this.sortName) && searchParams.has(this.sortDirection)) {
                return {
                    name: searchParams.get(this.sortName) as SortName,
                    direction: searchParams.get(this.sortDirection) as SortDirection
                };
            }
        }
        return initialSort;
    }
}
