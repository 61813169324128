import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import HttpContext from "./httpContext";

export function RedirectWithStatus({ from, to, status }: { from: string, to: string, status: number }) {
    const params = useParams();
    const httpContext = useContext(HttpContext)
    httpContext.statusCode = status;
    let target = to;
    if (/:[a-zA-Z]+\??/.test(to)) {
        Object.keys(params).forEach((p) => {
            console.log(new RegExp(`:${p}\\??`));
            target = target.replace(new RegExp(`:${p}\\??`), params[p]);
        });
    }
    httpContext.url = target;
    return <Navigate to={target} replace />;
}
