import {SearchSort} from "../../models/shared/SearchSort";
import {TyreDTO} from "../../models/tyres/TyreDTO";
import {TyreSearchParamDTO} from "../../models/tyres/TyreSearchRequestDTO";

export interface TyreSearchState {
    tyres: TyreDTO[];
    filters: any[];
    count: number;
    limit: number;
    offset: number;
    search: TyreSearchParamDTO;
    sort: SearchSort;
    selectedPage: number;
    isMixedTyreSearch: boolean;
    isCompleteWheelSearch: boolean;
    carId: string;
    rimFrontId: string;
    rimRearId: string;
    selectedAmount: number;
    positionId: number;
    reload: boolean;
}

export enum TyreSearchActionType {
    INIT = 'TyreSearchActionType [INIT]',
    UPDATE_RESULTS = 'TyreSearchActionType [UPDATE_RESULTS]',
    CHANGE_PAGE = 'TyreSearchActionType [CHANGE_PAGE]',
    CHANGE_FILTERS = 'TyreSearchActionType [CHANGE_FILTERS]',
    CHANGE_SEARCH_PARAMS = 'TyreSearchActionType [CHANGE_SEARCH_PARAMS]',
    CHANGE_SORT = 'TyreSearchActionType [CHANGE_SORT]'
}

export interface TyreSearchAction {
    type: TyreSearchActionType,
    payload: any;
}

export const tyreSearchReducer = (state: TyreSearchState = {} as any, {type, payload}: TyreSearchAction) => {
    switch (type) {
        case TyreSearchActionType.INIT:
            return {
                ...state,
                ...payload
            }
        case TyreSearchActionType.UPDATE_RESULTS:
            return {
                ...state,
                ...payload,
                reload: false
            }
        case TyreSearchActionType.CHANGE_PAGE:
            return {
                ...state,
                offset: payload.selectedPage * state.limit,
                selectedPage: payload.selectedPage,
                reload: true
            }
        case TyreSearchActionType.CHANGE_FILTERS:
            return {
                ...state,
                offset: 0,
                filters: payload.filters,
                selectedPage: 0,
                reload: true,
            }
        case TyreSearchActionType.CHANGE_SEARCH_PARAMS:
            return {
                ...state,
                offset: 0,
                search: payload.search,
                selectedPage: 0,
                isMixedTyreSearch: payload.isMixedTyreSearch,
                reload: true
            }
        case TyreSearchActionType.CHANGE_SORT:
            return {
                ...state,
                sort: payload.sort,
                reload: true
            }
        default:
            return state;
    }
}
