import { PaymentStatusDTO } from "../../models/checkout/PaymentStatusDTO";
import axiosInstance from "./AxiosInstance";

export function completePayPal(searchParams: any) {
    return axiosInstance.get<PaymentStatusDTO>("/api/payment/paypal/complete", {params: searchParams})
        .then(res => res.data);
}

export function cancelPayPal(searchParams: any) {
    return axiosInstance.get("/api/payment/paypal/cancel", {params: searchParams})
        .then((res) => res.data);
}
