import React from "react";
import {useTranslation} from "react-i18next";

function Service () {
    const [t] = useTranslation();

    return (
        <div className="service-wrapper">
            <div className="container-fluid">
                <h2 className="text-center">{t('HOME.SERVICE.TITLE')}</h2>
                <div className="row justify-content-center">
                    {/*<div className="col-12 col-lg-6 text-center">*/}
                    {/*    <img*/}
                    {/*        id="andre-circle"*/}
                    {/*        src="geschaeftsfuehrung.png"*/}
                    {/*        alt="Geschäftsführung"*/}
                    {/*        title="Geschäftsführung"*/}
                    {/*    />*/}
                    {/*    <p className="text-dark">*/}
                    {/*        {t('HOME.SERVICE.SUB_TEXT')}*/}
                    {/*    </p>*/}
                    {/*    <img*/}
                    {/*        id="signature"*/}
                    {/*        src="unterschriften_geschaeftsfuehrung-weiss.png"*/}
                    {/*        alt="Unterschrift der Geschäftsführung"*/}
                    {/*        title="Unterschrift der Geschäftsführung"*/}
                    {/*    />*/}
                    {/*    <p className="mb-5">*/}
                    {/*        {t('HOME.SERVICE.MANAGEMENT')} Wheelscompany GmbH*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="col-12 col-lg-6">
                        <ul className="d-none d-md-block">
                            <li className="checkmark-box">
                                <div className="text-content">
                                    <h3>{t('HOME.SERVICE.CUSTOMER_SERVICE.TITLE')}</h3>
                                    <p>
                                        {t('HOME.SERVICE.CUSTOMER_SERVICE.TEXT')} {" "}
                                        <a href="mailto:geschaeftsfuehrung@wheelscompany.com">
                                            geschaeftsfuehrung@wheelscompany.com
                                        </a>
                                    </p>
                                </div>
                            </li>
                            <li className="checkmark-box">
                                <div className="text-content">
                                    <h3>{t('HOME.SERVICE.RETURNS.TITLE')}</h3>
                                    <p>
                                        {t('HOME.SERVICE.RETURNS.TEXT')}
                                    </p>
                                </div>
                            </li>
                            <li className="checkmark-box">
                                <div className="text-content">
                                    <h3>{t('HOME.SERVICE.RECLAMATION.TITLE')}</h3>
                                    <p>
                                        {t('HOME.SERVICE.RECLAMATION.TEXT')}
                                    </p>
                                </div>
                            </li>
                            <li className="checkmark-box">
                                <div className="text-content">
                                    <h3>{t('HOME.SERVICE.EXPERT_TEAM.TITLE')}</h3>
                                    <p>
                                        {t('HOME.SERVICE.EXPERT_TEAM.TEXT_1')}{" "}
                                        <a href="tel:+494264776930">
                                            04264 77693 0
                                        </a>
                                        ,<br /> {t('HOME.SERVICE.EXPERT_TEAM.TEXT_2')}{" "}
                                        <a
                                            href="mailto:info@wheelscompany.com"
                                            title="E-Mail: Lieferstatus"
                                        >
                                            info@wheelscompany.com
                                        </a>
                                    </p>
                                </div>
                            </li>
                            <li className="checkmark-box">
                                <div className="text-content">
                                    <h3>{t('HOME.SERVICE.DELIVERY_STATUS.TITLE')}</h3>
                                    <p>
                                        {t('HOME.SERVICE.DELIVERY_STATUS.TEXT_1')}{" "}
                                        <a
                                            href="mailto:status@wheelscompany.com"
                                            title="E-Mail: Lieferstatus"
                                        >
                                            status@wheelscompany.com
                                        </a>{" "}
                                        {t('HOME.SERVICE.DELIVERY_STATUS.TEXT_2')}
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div id="accordion" className="d-md-none">
                            <div
                                className="card"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link">
                                            {t('HOME.SERVICE.CUSTOMER_SERVICE.TITLE')}
                                        </button>
                                    </h5>
                                </div>

                                <div
                                    id="collapseOne"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {t('HOME.SERVICE.CUSTOMER_SERVICE.TEXT')} <br />
                                        <a
                                            href="mailto:geschaeftsfuehrung@wheelscompany.com"
                                            title="E-Mail: Geschäftsführung"
                                        >
                                            geschaeftsfuehrung@wheelscompany.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    id="headingTwo"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed">
                                            {t('HOME.SERVICE.RETURNS.TITLE')}
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseTwo"
                                    className="collapse"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {t('HOME.SERVICE.RETURNS.TEXT')}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    id="headingThree"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed">
                                            {t('HOME.SERVICE.RECLAMATION.TITLE')}
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseThree"
                                    className="collapse"
                                    aria-labelledby="headingThree"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {t('HOME.SERVICE.RECLAMATION.TEXT')}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    id="headingFour"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed">
                                            {t('HOME.SERVICE.EXPERT_TEAM.TITLE')}
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseFour"
                                    className="collapse"
                                    aria-labelledby="headingFour"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {t('HOME.SERVICE.EXPERT_TEAM.TEXT_1')}{" "}
                                        <a href="tel:+494264776930">
                                            04264 77693 0
                                        </a>
                                        ,<br /> {t('HOME.SERVICE.EXPERT_TEAM.TEXT_2')}{" "}
                                        <a
                                            href="mailto:info@wheelscompany.com"
                                            title="E-Mail: Lieferstatus"
                                        >
                                            info@wheelscompany.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    id="headingFive"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                >
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed">
                                            {t('HOME.SERVICE.DELIVERY_STATUS.TITLE')}
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseFive"
                                    className="collapse"
                                    aria-labelledby="headingFive"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {t('HOME.SERVICE.DELIVERY_STATUS.TEXT_1')}{" "}
                                        <a
                                            href="mailto:status@wheelscompany.com"
                                            title="E-Mail: Lieferstatus"
                                        >
                                            status@wheelscompany.com
                                        </a>{" "}
                                        {t('HOME.SERVICE.DELIVERY_STATUS.TEXT_2')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
