import React from "react";
import {useTranslation} from "react-i18next";
import {RimTyreDTO} from "../../../../models/rims/RimTyreDTO";

interface Props {
    tyreList: RimTyreDTO[];
}

function TyreRestrictions({tyreList}: Props) {
    const [t] = useTranslation();

    return (
        <div className="expandable-information">
            <button className="btn btn-collapsible" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTyreRestrictions" aria-expanded="false"
                    aria-controls="collapseTyreRestrictions">
                <div className="d-flex justify-content-between">
                    <div>{t("RIMS.DETAILS.TYRE_RESTRICTIONS.TITLE")}</div>
                    <div><i className="fas fa-chevron-down"/></div>
                </div>
            </button>
            <div className="collapse" id="collapseTyreRestrictions">
                <div className="content">
                    <table className="table table-striped tyre-restrictions-table">
                        <tbody>
                        {tyreList.map((tyre, index) => (
                            <tr className={"tyre-restrictions-tr"}  key={tyre.tyreSizeFront + tyre.tyreSizeRear + '_' + index}>
                                <td className={"tyre-restrictions-td-car"}>
                                    {tyre.tyreSizeFront === tyre.tyreSizeRear ?
                                        <>
                                            <img src="/car_front_rear_wheels.svg"
                                                 className="axis small me-2"
                                                 alt="car_front_rear_wheels.svg"
                                            />
                                            <span className={"no-wrap"}>{tyre.tyreSizeFront}</span>
                                        </>
                                        :
                                        <>
                                            <div>
                                                <img src="/car_front_wheel.svg"
                                                     className="axis small me-2"
                                                     alt="car_front_wheel.svg"
                                                />
                                                <span>{tyre.tyreSizeFront}</span>
                                            </div>
                                            <div>
                                                <img src="/car_rear_wheel.svg"
                                                     className="axis small me-2"
                                                     alt="car_rear_wheel.svg"
                                                />
                                                <span className={"no-wrap"}>{tyre.tyreSizeRear}</span>
                                            </div>
                                        </>
                                    }
                                </td>
                                <td className={"tyre-restrictions-td-text"}>
                                    <table className="table table-striped inner-table mx-2">
                                        <tbody>
                                        {tyre.restrictions?.map((restriction) => (
                                            <tr key={restriction.restrictionId} className={"row"}>
                                                <td className={"inner-table-td-id"}>{restriction.restrictionId}</td>
                                                <td className={"inner-table-td-text"}>{restriction.restrictionText}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    )
}

export default TyreRestrictions;
