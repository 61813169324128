import React from "react";

interface Props {
    close: Function;
    img:string
    alt:string
}

export function GalleryModal(props: Props) {
    const handleClose = () => {
        props.close();
    };

    return (
        <div className="gallery-modal">
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close">
            </button>
            <img className="img-fluid" src={props.img} alt={props.alt}/>
        </div>
    );
}

