import React, {ErrorInfo, PropsWithChildren} from "react";
import {LogLevel, logToServer} from "../redux-store/api/ServerLoggingApi";

export class ErrorBoundary extends React.Component<PropsWithChildren<any>, {hasError: boolean}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logToServer({
            name: error.name,
            message: error.message,
            stack: error.stack,
            logLevel: LogLevel.WARN,
            customParameters: {
                source: 'GLOBAL_ERROR_HANDLER',
                errorInfo: errorInfo
            }
        });
    }

    render() {
       return this.props.children;
    }
}
