import { FilterInterface } from "../../models/shared/FilterInterface";
import { SearchSort } from "../../models/shared/SearchSort";

export interface RimSearchState {
    rims: any[];
    filters: FilterInterface[];
    offset: number;
    limit: number;
    totalCount: number;
    selectedPage: number;
    width: string;
    diameter: string;
    design: string;
    hubBoreHole: string;
    rimOffset: string;
    holeCount: string;
    holeCircle: string;
    brand: string;
    widths: string[];
    diameters: string[];
    designs: string[];
    hubBoreHoles: string[];
    rimOffsets: string[];
    holeCounts: string[];
    holeCircles: string[];
    brands: string[];
    sort: SearchSort;
}

export enum RimSearchActionType {
    INIT = 'RimSearchActionType [INIT]',
    UPDATE_RESULTS = 'RimSearchActionType [UPDATE_RESULTS]',
    CHANGE_PAGE = 'RimSearchActionType [CHANGE_PAGE]',
    CHANGE_FILTERS = 'RimSearchActionType [CHANGE_FILTERS]',
    CHANGE_SEARCH_PARAMS = 'RimSearchActionType [CHANGE_SEARCH_PARAMS]',
    CHANGE_SORT = 'RimSearchActionType [CHANGE_SORT]',
}

export interface RimSearchAction {
    type: RimSearchActionType;
    payload: any;
}

export const rimSearchReducer = (state: RimSearchState = {} as any, {type, payload}: RimSearchAction) => {
    switch (type) {
        case RimSearchActionType.INIT:
            return {
                ...state,
                ...payload
            }
        case RimSearchActionType.UPDATE_RESULTS:
            return {
                ...state,
                ...payload
            }
        case RimSearchActionType.CHANGE_PAGE:
            return {
                ...state,
                offset: payload.selectedPage * state.limit,
                selectedPage: payload.selectedPage
            }
        case RimSearchActionType.CHANGE_FILTERS:
            return {
                ...state,
                offset: 0,
                selectedPage: 0,
                filters: payload.filters
            }
        case RimSearchActionType.CHANGE_SEARCH_PARAMS:
            return {
                ...state,
                offset: 0,
                selectedPage: 0,
                ...payload.search
            }
        case RimSearchActionType.CHANGE_SORT:
            return {
                ...state,
                sort: payload.sort
            }
        default:
            return state;
    }
}
