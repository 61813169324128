import React from "react";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";

interface Props {
    price: number;
    amount: number;
    isMountedWheel: boolean;
    listView?: boolean;
    textAlign?: string;
    priceOnly?: boolean

}

function TyrePrice({price, amount, isMountedWheel, listView = false, textAlign = 'center', priceOnly = false}: Props) {
    const [t] = useTranslation();

    if (listView) {
        return (
            <div className={"d-flex justify-content-end"}>
                <div className={"row justify-content-between"}>
                    <h3 className={`text-${textAlign} text-highlight`}>
                        <NumericFormat value={price * amount}
                                       displayType="text"
                                       decimalSeparator=","
                                       decimalScale={2}
                                       fixedDecimalScale={true}
                                       suffix="€"
                        />
                    </h3>
                    {!priceOnly &&  <div className={`small text-${textAlign}`}>
                        {t("TYRES.SEARCH." + (isMountedWheel ? 'FOR_AMOUNT_WHEELS' : 'FOR_AMOUNT_TYRES'), {amount})}
                        {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}
                    </div>}
                </div>
            </div>);
    }

    return (
        <div className="tyre-price-wrapper">
            <div className="tyre-price">
                <NumericFormat value={price * amount}
                               displayType="text"
                               decimalSeparator=","
                               decimalScale={2}
                               fixedDecimalScale={true}
                               suffix="€"
                />
            </div>
            <div className="small mt-2 d-flex flex-column justify-content-center">
                <span>{t("TYRES.SEARCH." + (isMountedWheel ? 'FOR_AMOUNT_WHEELS' : 'FOR_AMOUNT_TYRES'), {amount})} {t("GLOBAL.INCL_VAT", {vatInPercent: 19})}</span>
                <span>{t("RIMS.DETAILS.FREE_SHIPPING_DE")}</span>
            </div>
        </div>
    );
}

export default TyrePrice;
