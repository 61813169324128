import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import trashSvg from "../../assets/icons/svg/delete.svg";
import wrenchSvg from "../../assets/icons/svg/wrench.svg";
import {getFallbackImage} from "../../helper/Helper";
import {useMediaQuery} from "../../helper/useMediaQuery";
import {CartDTO, CartPositionDTO} from "../../models/cart/CartDTO";
import {Axle, ItemType} from "../../models/shared/Enums";
import CarBuildDateInput from "../../pages/ShoppingCart/components/CarBuildDateInput/CarBuildDateInput";
import CarDescriptionInput from "../../pages/ShoppingCart/components/CarDescriptionInput/CarDescriptionInput";
import {updatePositionQuantity} from "../../redux-store/api/ShoppingCartApi";
import AmountInput from "../AmountInput/AmountInput";
import Image from "../Image/Image";
import TyreEuLabelImage from "../TyreEuLabelImage/TyreEuLabelImage";

interface Props {
    position: CartPositionDTO;
    updateCart?: (cart: CartDTO) => void;
    cartChanged?: Function;
    showInputs: boolean;
    removeButtonHandler?: Function;
    getAdditionalTyreForRimInCart?: Function;
}

function CartPosition({position, updateCart, cartChanged, showInputs, removeButtonHandler, getAdditionalTyreForRimInCart}: Props) {
    const [t] = useTranslation();
    const [quantity, setQuantity] = useState(position.quantity);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    function getAdditionalTyre(rimId: string, certificateId: number, certificateBlockId: number) {
       getAdditionalTyreForRimInCart(rimId, certificateId, certificateBlockId, position.carId, position.quantity, position.id, position.boltCircle);
    }

    function changeQuantity(newQuantity: number) {
        setQuantity(newQuantity);
        updatePositionQuantity(position, newQuantity)
            .then((cart) => {
                updateCart(cart);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <div className="cart-position ps-3 px-sm-4">
            {position?.items?.map((i) => i.itemType)?.includes(ItemType.RIM) && (
                <div className="row">
                    <div className="d-none d-xl-block col-xl-1"/>
                    <div className="col-12 col-xl-11">
                        <div className="py-3 info-message">
                            <i className="fas fa-info-circle info-icon"/>
                            {t("CART.RIM_INCLUDES_SCREWS")}
                        </div>
                    </div>
                </div>
            )}
            <>
                {position.items.map((item, index, arr) => (
                    <div key={item.id}>
                        <div className="row py-3">
                            <div className="d-none d-xl-block col-xl-1"/>
                            <div className="col-4 col-md-2 text-center">
                                <Image
                                        className={`img-fluid bordered ${item.itemType === ItemType.TPMS ? 'flip-image' : ''}`}
                                        fallback={getFallbackImage(item)}
                                        src={(item.itemType === ItemType.MOUNTINGKIT || item.itemType === ItemType.WHEELASSEMBLY) ? wrenchSvg : item.imageLink}
                                        alt={item.description}
                                        fluid={true}
                                        width={'120px'}
                                />
                            </div>
                            <div className="col-8 col-sm-6 col-md-5 col-xl-4">
                                {item.productMetadata &&
                                    <div>
                                        <div className="item-title">
                                            {item.manufacturer}
                                            {item.itemType === 'RIM' && <span> {item.productMetadata?.rimType}</span>}
                                            {item.itemType === 'TYRE' && <span> {item.productMetadata?.tread}</span>}
                                        </div>
                                        {item.itemType === 'RIM' &&
                                            <div>
                                                <div
                                                    className="mb-3">{`${item.productMetadata.width}x${item.productMetadata.size} ET${item.productMetadata.offset}, ${item.productMetadata.boltPattern}`}</div>
                                                <div>{item.productMetadata.colorGroup}</div>
                                            </div>
                                        }
                                        {item.itemType === 'TYRE' &&
                                            <div>
                                                <div
                                                    className="mb-3">{`${item.productMetadata.width}/${item.productMetadata.section}R${item.productMetadata.diameter} ${item.productMetadata.loadIndex}${item.productMetadata.speedIndex}`}</div>
                                                <div>{item.productMetadata.season ? t("TYRES.SEARCH.SEASONS." + item.productMetadata.season) : '&nbsp;'}</div>
                                            </div>
                                        }
                                    </div>
                                }
                                {!item.productMetadata &&
                                    <>
                                        <div className="item-title">{item.manufacturer}</div>
                                        <div
                                            className={`mb-3 ${item.itemType === 'TPMS' || item.itemType === 'WHEELASSEMBLY' ? 'item-title' : ''}`}>
                                            {item.description}
                                        </div>
                                    </>
                                }
                                {+item.productId > 0 && item.productEan &&
                                    <div>{t("CART.ARTICLE_NUMBER")}:&nbsp;{item.productEan}</div>}
                                {(position.axle === "FRONT" || position.axle === "REAR") && (
                                    <div>{t("CART.AXLE." + position.axle)}</div>
                                )}
                                {!showInputs && item.itemType === 'TPMS' &&
                                    <div className="mt-3">
                                        {t("CART.CAR_BUILD_DATE_PLACEHOLDER")}: {item.carBuildDate}
                                    </div>
                                }
                                {item.errorMessage && <div className="text-danger mt-2">{item.errorMessage}</div>}
                                {!item?.errorMessage && item.priceGross && item.priceGross > 0 && (
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="">{t("CART.PIECE_PRICE")}</div>
                                        <strong>
                                            <NumericFormat
                                                value={item.priceGross}
                                                displayType="text"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix="€"
                                            />
                                        </strong>
                                    </div>
                                )}
                                {showInputs && position.positionType === 'RIM' && position.carId && position.axle === Axle.BOTH &&
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-primary w-100 mt-3"
                                            onClick={() => getAdditionalTyre(item.productExternalId, item.productMetadata.certificateId, item.productMetadata.certificateBlockId)}
                                        >
                                            {t("CART.ADD_TYRE")}
                                        </button>
                                    </div>
                                }
                                <div className="d-md-none d-block mt-2">
                                    {item.tyreEULabel && <TyreEuLabelImage tyreEULabel={item.tyreEULabel}/>}
                                </div>
                                {showInputs && item.itemType === 'TPMS' &&
                                    <CarBuildDateInput item={item}/>
                                }
                                {showInputs && position.positionType === 'RIM' && !position.carHsn &&
                                    <CarDescriptionInput position={position}/>
                                }
                                {!showInputs && position.positionType === 'RIM' && !position.carHsn &&
                                    <div className="car-description text-start mt-2">
                                        {t("CART.CAR_DESCRIPTION")}: {position.carDescription}
                                    </div>
                                }
                            </div>
                            <div className="d-none d-md-block col-md-3">
                                {item.tyreEULabel && <TyreEuLabelImage tyreEULabel={item.tyreEULabel}/>}
                            </div>
                            <div className="d-none d-sm-block col-sm-2 text-end">
                                {((position.positionType === "MOUNTED_WHEEL" && index === 0) || position.positionType !== "MOUNTED_WHEEL") &&
                                    <AmountInput
                                        amount={quantity}
                                        handleAmountChange={(amount) => changeQuantity(amount)}
                                        onlyEvenNumbers={false}
                                        readonly={!showInputs}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                ))}
                <div className="row position-sum-row py-3 align-items-center">
                    <div className="d-none d-xl-block col-xl-3"/>
                    <div className="col-12 col-xl-6">
                        {showInputs &&
                            <button className="icon-btn"
                                    onClick={() => removeButtonHandler(position)}
                            >
                                <img src={trashSvg} className="filter-grey me-2"/> {t('CART.REMOVE_POSITION')}
                            </button>
                        }
                    </div>
                    <div className="col-12 col-xl-3 pt-3 pt-sm-0 d-flex justify-content-between align-items-center">
                        <div className="d-sm-none">
                            <AmountInput
                                amount={quantity}
                                handleAmountChange={(amount) => changeQuantity(amount)}
                                onlyEvenNumbers={false}
                                readonly={!showInputs}
                            />
                        </div>
                        <div className="text-end item-price flex-fill">
                            {position?.sumGross && (
                                <NumericFormat
                                    value={position.sumGross}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix="€"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </>

        </div>
    );
}

export default CartPosition;
