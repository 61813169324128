import React from "react";
import {useParams} from "react-router-dom";
import NewPassword from "./NewPassword/NewPassword";
import RequestToken from "./RequestToken/RequestToken";

function PasswordReset() {
    const {token} = useParams<{ token: string }>();
    
    return (
        <div>
            {token ? 
            <NewPassword token={token}/>
            :
            <RequestToken/>}
        </div>
    );
}

export default PasswordReset;
