import React from "react";

function LoadingDetails() {
    return (
        <div>
            <div className="container-fluid">
                <div className="loading-rim-details"/>
            </div>
        </div>
    );
}

export default LoadingDetails;
