import React from "react";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../../../helper/useMediaQuery";

interface Props {
    sumNet: number;
    sumGross: number;
    vat: number;
    shipping: number;
    discount?: number;
    isCartView?: boolean;
}

export default function CartPrice({sumNet, sumGross, vat, shipping, discount, isCartView}: Props) {
    const [t] = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    return (
        <>
            <div className="cart-price">
                {((!isSmallScreen) || (isSmallScreen && !isCartView)) &&
                    <div>
                        <div className="heading">{t('CART.TOTAL_PRICE')}</div>
                        <div className="price-row">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.PRICE_NET")}</div>
                            <NumericFormat
                                value={sumNet}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                        <div className="price-row">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.INCLUDED_VAT")}</div>
                            <NumericFormat
                                value={vat}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                        {shipping !== null && (
                            <div className="price-row">
                                <div>
                                    {t("CHECKOUT.ORDER_SUMMARY.SHIPPING_COSTS")}
                                </div>
                                <NumericFormat
                                    value={shipping}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />
                            </div>
                        )}
                        {discount !== null && (
                            <div className="price-row">
                                <div>
                                    {t("CHECKOUT.ORDER_SUMMARY.DISCOUNT")}
                                </div>
                                <NumericFormat
                                    value={discount * -1}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />
                            </div>
                        )}
                        <hr></hr>
                        <div className="price-row total-price">
                            <div>{t("CHECKOUT.ORDER_SUMMARY.TOTAL_PRICE")}</div>
                            <NumericFormat
                                value={sumGross}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </div>
                    </div>
                }
                {isSmallScreen && isCartView &&
                    <>
                        <div className="panel-mobile no-side-borders py-2 px-3">
                            {shipping !== null &&
                            <div className="d-flex justify-content-between mb-2">
                                <div>{t('CART.SHIPPING_COSTS')}:</div>
                                <div className="fw-bold">
                                    <NumericFormat
                                        value={shipping}
                                        displayType="text"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix="€"/>
                                </div>
                            </div>
                            }
                            {discount !== null &&
                                <div className="d-flex justify-content-between mb-2">
                                    <div>{t('CART.DISCOUNT')}:</div>
                                    <div className="fw-bold">
                                        <NumericFormat
                                            value={discount}
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            suffix="€"
                                            prefix="- "/>
                                    </div>
                                </div>
                            }
                            <div className="d-flex justify-content-between">
                                <div>{t('CART.INCLUDED_TAX')}:</div>
                                <div className="fw-bold">
                                    <NumericFormat
                                        value={vat}
                                        displayType="text"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix="€"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between total-sum pb-4 pt-2 px-3">
                                <div>{t('CART.TOTAL_PRICE')}:</div>
                                <div className="fw-bold">
                                    <NumericFormat
                                        value={sumGross}
                                        displayType="text"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix="€"/>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
        ;
}
