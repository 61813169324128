import classNames from "classnames";
import Tooltip from "rc-tooltip";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../helper/useMediaQuery";

interface ColorObject {
    label: string;
    hex?: string;
    value: string;
    specialColor?: boolean;
}

interface ColorSelectionProps {
    colors?: string[];
    values?: string[];
    multiSelect: boolean;
    containerClasses?: string;
    objectClasses?: string;
    onSelect?: (values: string[]) => void;
}

export default function ColorSelection(props: ColorSelectionProps) {
    const {t} = useTranslation();
    const translationPrefix = "COLOR.";
    const defaultColors: ColorObject[] = [
        {label: "BLACK", hex: "#000000", value: "schwarz"},
        {label: "SILVER", hex: "#C0C0C0", value: "silber"},
        {label: "GREY", hex: "#4F4F4F", value: "grau / anthrazit"},
        {label: "WHITE", hex: "#FFFFFF", value: "weiß"},
        {label: "CHROME", hex: "#DBE4EB", value: "chrom"},
        {label: "GOLD", hex: "#B29146", value: "gold"},
        {label: "BRONZE", hex: "#BF8970", value: "bronze"},
        {label: "RED", hex: "#FF0000", value: "rot"},
        {label: "ORANGE", hex: "#FF8800", value: "orange"},
        {label: "BLUE", hex: "#0000FF", value: "blau"},
        {label: "YELLOW", hex: "#FFFF00", value: "gelb"},
        {label: "PINK", hex: "#FF0084", value: "pink"},
        {label: "GREEN", hex: "#00FF00", value: "grün"},
        {label: "BROWN", hex: "#5B3A29", value: "braun"},
        {label: "COPPER", hex: "#8E402A", value: "kupfer"},
        {label: "MULTI_COLOR", hex: "#FFFFFF00", value: "mehrfarbig", specialColor: true},
    ];
    const defaultGradient = "linear-gradient(225deg, hsla(0, 0%, 83%, .5) 40%, hsla(0, 0%, 100%, 0) 100%) ";
    const availableColors = props.colors
        ? defaultColors.filter((defaultColor) => props.colors.includes(defaultColor.value))
        : defaultColors;

    const [selectedColors, setSelectedColors] = useState<ColorObject[]>(
        props.values ? defaultColors.filter((defaultColor) => props.values.includes(defaultColor.value)) : [],
    );

    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");

    useEffect(() => {
        setSelectedColors(props.values ? defaultColors.filter((defaultColor) => props.values.includes(defaultColor.value)) : []);
    }, [props.values])

    function onColorSelect(selectedColor: ColorObject) {
        if (props.multiSelect) {
            let options: ColorObject[];
            if (selectedColors.some((color) => color.hex === selectedColor.hex)) {
                options = selectedColors.filter((color) => color.hex !== selectedColor.hex);
                setSelectedColors(options);
                props.onSelect(options.map((o) => o.value));
            } else {
                options = [...selectedColors, selectedColor];
                setSelectedColors(options);
                props.onSelect(options.map((o) => o.value));
            }
            return;
        }

        setSelectedColors([selectedColor]);
        props.onSelect([selectedColor.value]);
    }

    const whiteCheckMarks = ["#000000", "#4F4F4F", "#0000FF", "#5B3A29", "#8E402A"];

    function addCheckMark(currentColor: ColorObject) {
        if (selectedColors.some((color) => color.hex === currentColor.hex)) {
            return (
                <div
                    key={"chkmark-" + currentColor.label}
                    className={"d-flex h-100 justify-content-center align-items-center"}
                >
                    <i
                        className={`fas fa-check ${
                            whiteCheckMarks.includes(currentColor.hex) ? "text-white" : "text-black"
                        }`}
                    ></i>
                </div>
            );
        }
        return <></>;
    }

    function getToolTip(translationKey: string, id: string) {
        return <span key={id}>{t(translationPrefix + translationKey)}</span>;
    }

    const borderColors = ["#FFFFFF", "#FFFF00", "#FFFFFF00"];

    function getBorderColor(colorObject: ColorObject) {
        if (selectedColors.some((color) => color.hex === colorObject.hex)) {
            return "selected-color";
        }

        if (borderColors.includes(colorObject.hex)) {
            return "colored-border";
        }

        return "";
    }

    function getSpecialPattern(colorValue: string): string {
        switch (colorValue) {
            case "mehrfarbig":
                return (
                    "linear-gradient(225deg,rgba(255, 0, 0, 1) 0%, " +
                    "rgba(255, 154, 0, 1) 10%, " +
                    "rgba(208, 222, 33, 1) 20%, " +
                    "rgba(79, 220, 74, 1) 30%, " +
                    "rgba(63, 218, 216, 1) 40%," +
                    "rgba(47, 201, 226, 1) 50%, " +
                    "rgba(28, 127, 238, 1) 60%, " +
                    "rgba(95, 21, 242, 1) 70%, " +
                    "rgba(186, 12, 248, 1) 80%, " +
                    "rgba(251, 7, 217, 1) 90%, " +
                    "rgba(255, 0, 0, 1) 100%)"
                );

            default:
                return "#FFFFFF";
        }
    }


    function getColorObjects() {

        if (isSmallScreen) {
            return (availableColors.map((color) => {
                return <div
                    className={`color-object p-1 ${props.objectClasses ?? ""} ${getBorderColor(color)}`}
                    style={{
                        background: color.specialColor
                            ? getSpecialPattern(color.value)
                            : defaultGradient + color.hex,
                    }}
                    onClick={() => {
                        onColorSelect(color);
                    }}
                    key={`${color.hex}-${color.label}-${Math.random()}`}
                >
                    {addCheckMark(color)}
                </div>
            }));
        }

        return (
            <>
                {availableColors.map((color) => {
                    return (
                        <Tooltip
                            overlay={getToolTip(color.label, "tt-" + color.hex)}
                            key={`tt-${color.hex}-${color.label}-${Math.random()}`}
                            placement={"top"}
                        >
                            <div
                                className={`color-object p-1 ${props.objectClasses ?? ""} ${getBorderColor(color)}`}
                                style={{
                                    background: color.specialColor
                                        ? getSpecialPattern(color.value)
                                        : defaultGradient + color.hex,
                                }}
                                onClick={() => {
                                    onColorSelect(color);
                                }}
                                key={`${color.hex}-${color.label}-${Math.random()}`}
                            >
                                {addCheckMark(color)}
                            </div>
                        </Tooltip>
                    );
                })}
            </>
        );
    }

    return <div className={classNames(props.containerClasses, 'color-objects')}>{getColorObjects()}</div>;
}
