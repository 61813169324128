import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import deTranslations from "./assets/i18n/de.json";

const resources = {
    de: {
        translation: deTranslations
    }
};

export function initI18n(cb?: (i18nInstance: any) => void) {
    i18n.use(initReactI18next).init(
        {
            resources,
            lng: "de",
            interpolation: {
                escapeValue: false
            }
        },
        () => cb && cb(i18n)
    );
}
