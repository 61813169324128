import React from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Image from "../../../../../components/Image/Image";
import RatePayInfo from "../../../../../components/RatePayInfo/RatePayInfo";
import RecommendedRetailPrice from "../../../../../components/RecommendedRetailPrice/RecommendedRetailPrice";
import { StockInfo } from "../../../../../components/StockInfo/StockInfo";
import SvgIcon, { SVG_ICONS } from "../../../../../components/SvgIcon/SvgIcon";
import { DeliveryStatus } from "../../../../../models/shared/Enums";
import { RimViewCardProps } from "./RimCard";


export default function GridViewRimCard(props: RimViewCardProps) {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const detailsTable: { key: string, content: string | number }[] = [
        {key: "SIZE", content: props.rim.items[0]?.size},
        {key: "WIDTH", content: props.rim.width},
        {key: "HOLE_COUNT", content: props.rim.holeCount},
        {key: "HOLE_CIRCLE", content: props.rim.holeCircle},
        {key: "OFFSET", content: props.rim.offset},
        {key: "HUB_BORE_HOLE", content: props.rim.hubBoreHole},
    ]

    function getDetailsTable() {
        return <div className="additional-rim-info">
            {detailsTable.map((detail, index) =>
                <div className="rim-info-row" key={"grid-view-detail-table-" + index}>
                    <span>{t('RIMS.DETAILS.TABLE.' + detail.key)}:&nbsp;</span>
                    <span className="rim-info-content fw-bold">{detail.content}</span>
                </div>
            )}
        </div>
    }

    function onClickHandler(evt, url) {
        props.selectRim()
        if (props.isSmallScreen) {
            props.detailsClicked(evt)
            navigate(url);
        }
    }

    return (
        <div className={"rim-card rim-card-grid" + (props.isSelected ? " selected" : "")} id={props.rim.rimId}
             onClick={(evt) => onClickHandler(evt, props.rimDetailUrl)}>
            <div className="rim-card-part">
                <div className={"row justify-content-between pb-0 mb-0"}>
                    {props.rim.winterproofed && (
                        <div className="col">
                            <SvgIcon icon={SVG_ICONS.SNOWFLAKE} color="#4EACD6" size="32px" margin={'0'}/>
                        </div>
                    )}
                    <div className="col fav-icon">
                        <div className={"d-flex justify-content-end"}>
                            <i
                                onClick={(evt) => props.changeFavorite(evt)}
                                className={`fav-icon-grid ${props.isFavorite ? "fas fa-heart" : "far fa-heart"}`}
                                data-tooltip-id={`favorite-${props.rim.rimId}`}
                            />
                            <ReactTooltip id={`favorite-${props.rim.rimId}`} place="bottom">
                                {props.isFavorite ? (
                                    <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                                ) : (
                                    <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                                )}
                            </ReactTooltip>
                        </div>
                    </div>
                </div>

                <Image
                    className="rim-image p-0"
                    src={props.rimImageSrc}
                    fallback="/no_images/felge.svg"
                    alt={props.rim.manufacturer + " " + props.rim.name}
                />
                <div className="description">
                    <h3 className="manufacturer">{props.rim.manufacturer}</h3>
                    <div className="name">{props.rim.name}</div>
                    <div className="color">{props.rim.colourGroup}</div>
                </div>
            </div>
            <div className="rim-card-part d-flex flex-column h-100 p-0 justify-content-end">
                {props.carId && !props.isSmallScreen &&
                    <div className="rim-size-wrapper d-inline-flex row w-100">{props.rimSizesList}</div>
                }
                {!props.carId && getDetailsTable()}
                <div className={"m-0"}>
                    <RecommendedRetailPrice
                        price={props.getPrice()}
                        recommendedRetailPrice={props.getRecommendedRetailPrice()}/>
                    <div className="rim-price">
                        {t("RIMS.SEARCH.STARTING_AT")}&nbsp;
                        <span className="text-highlight font-weight-bold">
                        <NumericFormat
                            value={props.getPrice() || t("RIMS.SEARCH.PRICE_BY_REQUEST")}
                            displayType="text"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix="€"
                        />
                    </span>
                        <div className="caption mt-1 ">
                            {t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: 4})}
                        </div>
                        <StockInfo className={"caption mt-1"} deliveryStatus={props.deliveryStatus}/>
                        <div className={`caption d-${props.isSmallScreen ? 'none' : 'block'}`}>
                            <RatePayInfo/>
                        </div>
                    </div>
                </div>

                {!props.isSmallScreen &&
                    <>
                        <Link className="rim-detail-link mt-1" to={props.rimDetailUrl}
                              onClick={(evt) => props.detailsClicked(evt)}>
                            <button className="btn btn-green w-100">{t("RIMS.SEARCH.DETAILS")}</button>
                        </Link>
                        {props.carId &&
                            <button className="btn btn-light mt-2 w-100" onClick={() => props.selectRim()}>
                                {t("RIMS.SEARCH.SHOW_ON_CAR")}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );

}
