import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {CustomerDataDTO} from "../../../../models/cart/CustomerDataDTO";
import {CountryCodes, countryZipRegex, getCountryList} from "../../../../models/checkout/CountryCodes";
import {CountryOptionType} from "../../../../models/shared/Types";
import {convertEmptyStringsToNull, getFormFieldValidationStyles} from "../../../../helper/formikHelper";
import UserContext from '../../../../context/UserContext';
import {UserContextType} from '../../../../context/UserContextType';
import {updateCartCustomerData} from "../../../../redux-store/api/ShoppingCartApi";
import {getShippingCountries} from "../../../../redux-store/api/ShippingApi";

interface Props {
    customerData: CustomerDataDTO;
    nextStep: Function;
}

function NewCustomer({customerData, nextStep}: Props) {
    const [t] = useTranslation();
    const {loggedIn} = useContext(UserContext) as UserContextType;
    const [countryOptions, setCountryOptions] = useState<CountryOptionType[]>(getCountryList(t));
    const [countryOptionsWithShipping, setCountryOptionsWithShipping] = useState<CountryOptionType[]>([]);
    const initialFormValues = {
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        emailRepeat: "",
        street: "",
        zipCode: "",
        city: "",
        country: CountryCodes.DE,
        phoneNumber: "",
        registerUser: false,
        locale: "de_DE",
        password: "",
        confirmPassword: "",
        differingDeliveryAddress: false,
        deliveryAddressTitle: "",
        deliveryAddressFirstName: "",
        deliveryAddressLastName: "",
        deliveryAddressStreet: "",
        deliveryAddressZipCode: "",
        deliveryAddressCity: "",
        deliveryAddressCountry: "",
        forceDifferingAdress: false
    };
    const validationSchema = (loggedIn) => yup.object().shape({
        firstName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        lastName: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        street: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        zipCode: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")).when(['country'], (country: any, schema) => {
            return country ? schema.matches(countryZipRegex[country], t("FORMS.VALIDATIONS.ZIP_FORMAT")) : schema;
        }),
        city: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        country: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        phoneNumber: yup.string().required(t("FORMS.VALIDATIONS.REQUIRED")),
        email: !loggedIn
            ? yup
                .string()
                .email(t("FORMS.VALIDATIONS.MAIL_PATTERN"))
                .required(t("FORMS.VALIDATIONS.REQUIRED"))
            : yup.string(),
        emailRepeat: !loggedIn
            ? yup
                .string()
                .oneOf(
                    [yup.ref("email"), ""],
                    t("FORMS.VALIDATIONS.MAILS_MUST_MATCH")
                )
                .required(t("FORMS.VALIDATIONS.REQUIRED"))
            : yup.string(),
        password: yup.string().nullable().when("registerUser", {
            is: true,
            then: schema => schema.min(8, t("FORMS.VALIDATIONS.MIN_PASSWORD_LENGTH"))
                .matches(/(?=.*[0-9])/, {
                    message: t("FORMS.VALIDATIONS.NO_VALID_PASSWORD"),
                    excludeEmptyString: true
                })
                .required(t("FORMS.VALIDATIONS.REQUIRED"))
        }),
        confirmPassword: yup.string().nullable().when("registerUser", {
            is: true,
            then: schema => schema
                .oneOf(
                    [yup.ref("password"), ""],
                    t("FORMS.VALIDATIONS.PASSWORDS_MUST_MATCH")
                )
                .required(t("FORMS.VALIDATIONS.REQUIRED"))
        }),
        registerUser: yup.boolean(),
        differingDeliveryAddress: yup.boolean(),
        deliveryAddressFirstName: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            }),
        deliveryAddressLastName: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            }),
        deliveryAddressStreet: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            }),
        deliveryAddressZipCode: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            }),
        deliveryAddressCity: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            }),
        deliveryAddressCountry: yup
            .string()
            .nullable()
            .when("differingDeliveryAddress", {
                is: true,
                then: schema => schema.required(t("FORMS.VALIDATIONS.REQUIRED"))
            })
    });

    const [formValues, setFormValues] = useState<CustomerDataDTO>(initialFormValues);

    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        getAvailableCountriesForShipping();
    }, []);

    useEffect(() => {
        if (customerData) {
            setFormValues({
                title: customerData.title || "",
                firstName: customerData.firstName || "",
                lastName: customerData.lastName || "",
                email: customerData.email || "",
                street: customerData.street || "",
                zipCode: customerData.zipCode || "",
                city: customerData.city || "",
                country: customerData.country || "",
                phoneNumber: customerData.phoneNumber || "",
                registerUser: false,
                differingDeliveryAddress: false
            });
        }
    }, [customerData]);

    const submit = (values: CustomerDataDTO, actions) => {
        if (!values.registerUser) {
            values.password = ''
            values.confirmPassword = ''
        }
        if (!values.differingDeliveryAddress) {
            values.deliveryAddressTitle = ''
            values.deliveryAddressFirstName = ''
            values.deliveryAddressLastName = ''
            values.deliveryAddressStreet = ''
            values.deliveryAddressZipCode = ''
            values.deliveryAddressCity = ''
            values.deliveryAddressCountry = ''
        }
        const data = convertEmptyStringsToNull(values);
        updateCartCustomerData(data)
            .then((res) => {
                actions.setSubmitting(false);
                nextStep();
            })
            .catch((res) => {
                actions.setSubmitting(false)
                if (res.response.status == 412 || res.response.status == 404) {
                    setErrorMessage("FORMS.CUSTOMER_DATA.NOT_DELIVERABLE_TO_COUNTRY");
                }
                actions.resetForm();
            });
    };

    function getAvailableCountriesForShipping() {
        getShippingCountries().then((countries) => {
            let availableCountries = countries.map(c => c.country);
            setCountryOptionsWithShipping(getCountryList(t, availableCountries));
        });
    }

    function getHeader() {
        if (loggedIn) {
            return t("CHECKOUT.CUSTOMER_DATA.EXISTING_USER_ACCOUNT");
        }
        return t("CHECKOUT.CUSTOMER_DATA.NEW_ACCOUNT");
    }

    function getDifferingAddress(props: FormikProps<any>) {
        return (
            <>
                <div className="col-12">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressTitle">{t("FORMS.CUSTOMER_DATA.TITLE")}</label>
                        <Field as="select"
                               className="form-control"
                               type="text"
                               name="deliveryAddressTitle"
                               id="deliveryAddressTitle"
                               placeholder={t("FORMS.CUSTOMER_DATA.TITLE")}
                        >
                            <option value="">{t("FORMS.CUSTOMER_DATA.NO_TITLE")}</option>
                            <option value={t("FORMS.CUSTOMER_DATA.MR")}>{t("FORMS.CUSTOMER_DATA.MR")}</option>
                            <option value={t("FORMS.CUSTOMER_DATA.MS")}>{t("FORMS.CUSTOMER_DATA.MS")}</option>
                        </Field>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressFirstName">{t("FORMS.CUSTOMER_DATA.FIRST_NAME")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressFirstName")}
                            type="text"
                            name="deliveryAddressFirstName"
                            id="deliveryAddressFirstName"
                            placeholder={t("FORMS.CUSTOMER_DATA.FIRST_NAME")}
                        />
                        <ErrorMessage name="deliveryAddressFirstName"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressLastName">{t("FORMS.CUSTOMER_DATA.LAST_NAME")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressLastName")}
                            type="text"
                            name="deliveryAddressLastName"
                            id="deliveryAddressLastName"
                            placeholder={t("FORMS.CUSTOMER_DATA.LAST_NAME")}
                        />
                        <ErrorMessage name="deliveryAddressLastName"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressStreet">{t("FORMS.CUSTOMER_DATA.STREET")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressStreet")}
                            type="text"
                            name="deliveryAddressStreet"
                            id="deliveryAddressStreet"
                            placeholder={t("FORMS.CUSTOMER_DATA.STREET")}
                        />
                        <ErrorMessage name="deliveryAddressStreet"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
                <div className="col-12 col-sm-4">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressZipCode">{t("FORMS.CUSTOMER_DATA.ZIP_CODE")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressZipCode")}
                            type="text"
                            name="deliveryAddressZipCode"
                            id="deliveryAddressZipCode"
                            placeholder={t("FORMS.CUSTOMER_DATA.ZIP_CODE")}
                        />
                        <ErrorMessage name="deliveryAddressZipCode"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
                <div className="col-12 col-sm-8">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressCity">{t("FORMS.CUSTOMER_DATA.CITY")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressCity")}
                            type="text"
                            name="deliveryAddressCity"
                            id="deliveryAddressCity"
                            placeholder={t("FORMS.CUSTOMER_DATA.CITY")}
                        />
                        <ErrorMessage name="deliveryAddressCity"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="deliveryAddressCountry">{t("FORMS.CUSTOMER_DATA.COUNTRY")} *</label>
                        <Field
                            name="deliveryAddressCountry"
                            id="deliveryAddressCountry"
                        >
                            {({
                                  form: {values}
                              }) => (
                                <Typeahead options={countryOptionsWithShipping}
                                           id="country-autocomplete"
                                           inputProps={{
                                               autoComplete: "nope",
                                               required: true,
                                               name: "deliveryAddressCountry",
                                               className: getFormFieldValidationStyles(props.errors, props.touched, "deliveryAddressCountry")
                                           }}
                                           paginate={false}
                                           placeholder={t("FORMS.CUSTOMER_DATA.COUNTRY")}
                                           emptyLabel={t("FORMS.CUSTOMER_DATA.NO_MATCHED_COUNTRIES")}
                                           onChange={(e: CountryOptionType[]) => {
                                               props.setFieldValue("deliveryAddressCountry", e[0]?.code);
                                               setErrorMessage("");
                                           }}
                                           onBlur={props.handleBlur}
                                           selected={values.deliveryAddressCountry ? [{
                                               code: values.deliveryAddressCountry,
                                               label: t("ENUMS.COUNTRY_CODES." + values.deliveryAddressCountry)
                                           }] : []}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="deliveryAddressCountry"
                                      render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>
                </div>
            </>
        );
    }

    function getMailForm(props: FormikProps<any>) {
        if (loggedIn) {
            return "";
        }
        return (
            <>
                <div className="col-12 col-sm-6">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="email">{t("FORMS.CUSTOMER_DATA.MAIL")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "email")}
                            type="email"
                            name="email"
                            id="email"
                            placeholder={t("FORMS.CUSTOMER_DATA.MAIL")}
                        />
                        <ErrorMessage name="email" render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>

                </div>
                <div className="col-12 col-sm-6">
                    <div className="form-group pb-2">
                        <label className="form-label-small-bold"
                               htmlFor="emailRepeat">{t("FORMS.CUSTOMER_DATA.MAIL_CONFIRM")} *</label>
                        <Field
                            className={getFormFieldValidationStyles(props.errors, props.touched, "emailRepeat")}
                            type="email"
                            name="emailRepeat"
                            id="emailRepeat"
                            placeholder={t("FORMS.CUSTOMER_DATA.MAIL_CONFIRM")}
                        />
                        <ErrorMessage name="emailRepeat" render={msg => <div className="invalid-feedback">{msg}</div>}/>
                    </div>

                </div>
            </>
        );
    }

    function getRegisterForm(props: FormikProps<any>) {
        if (loggedIn) {
            return "";
        }
        return (
            <>
                <div className="col-12 my-2">
                    <label
                        className="custom-checkbox"
                        htmlFor="registerUser"
                    >
                        <Field
                            className="me-1"
                            type="checkbox"
                            name="registerUser"
                            id="registerUser"
                        />
                        <span className="checkmark"/>
                        {t("FORMS.CUSTOMER_DATA.CREATE_USER_ACCOUNT")}
                    </label>
                </div>
                {props.values.registerUser ? (
                    <>
                        <div className="col-12 col-sm-6">
                            <div className="form-group pb-2">
                                <label className="form-label-small-bold"
                                       htmlFor="password">{t("FORMS.CUSTOMER_DATA.PASSWORD")} *</label>
                                <Field
                                    className={getFormFieldValidationStyles(props.errors, props.touched, "password")}
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder={t("FORMS.CUSTOMER_DATA.PASSWORD")}
                                />
                                <ErrorMessage name="password"
                                              render={msg => <div className="invalid-feedback">{msg}</div>}/>
                            </div>

                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group pb-2">
                                <label className="form-label-small-bold"
                                       htmlFor="confirmPassword">{t("FORMS.CUSTOMER_DATA.PASSWORD_CONFIRM")} *</label>
                                <Field
                                    className={getFormFieldValidationStyles(props.errors, props.touched, "confirmPassword")}
                                    type="password"
                                    placeholder={t("FORMS.CUSTOMER_DATA.PASSWORD_CONFIRM")}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                />
                                <ErrorMessage name="confirmPassword"
                                              render={msg => <div className="invalid-feedback">{msg}</div>}/>
                            </div>

                        </div>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    }

    return (
        <>
            <div className="heading">
                {getHeader()}
            </div>
            <div>
                <Formik
                    initialValues={formValues}
                    validationSchema={validationSchema(loggedIn)}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {(props: FormikProps<any>) => (
                        <Form>
                            <div className="checkout-form">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="title">{t("FORMS.CUSTOMER_DATA.TITLE")}</label>
                                            <Field as="select"
                                                   className="form-control"
                                                   type="text"
                                                   name="title"
                                                   id="title"
                                                   placeholder={t("FORMS.CUSTOMER_DATA.TITLE")}
                                            >
                                                <option value="">{t("FORMS.CUSTOMER_DATA.NO_TITLE")}</option>
                                                <option
                                                    value={t("FORMS.CUSTOMER_DATA.MR")}>{t("FORMS.CUSTOMER_DATA.MR")}</option>
                                                <option
                                                    value={t("FORMS.CUSTOMER_DATA.MS")}>{t("FORMS.CUSTOMER_DATA.MS")}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="firstName">{t("FORMS.CUSTOMER_DATA.FIRST_NAME")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "firstName")}
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder={t("FORMS.CUSTOMER_DATA.FIRST_NAME")}
                                            />
                                            <ErrorMessage name="firstName"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="lastName">{t("FORMS.CUSTOMER_DATA.LAST_NAME")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "lastName")}
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                placeholder={t("FORMS.CUSTOMER_DATA.LAST_NAME")}
                                            />
                                            <ErrorMessage name="lastName"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="street">{t("FORMS.CUSTOMER_DATA.STREET")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "street")}
                                                type="text"
                                                name="street"
                                                id="street"
                                                placeholder={t("FORMS.CUSTOMER_DATA.STREET")}
                                            />
                                            <ErrorMessage name="street"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="zipCode">{t("FORMS.CUSTOMER_DATA.ZIP_CODE")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "zipCode")}
                                                type="text"
                                                name="zipCode"
                                                id="zipCode"
                                                placeholder={t("FORMS.CUSTOMER_DATA.ZIP_CODE")}
                                            />
                                            <ErrorMessage name="zipCode"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="city">{t("FORMS.CUSTOMER_DATA.CITY")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "city")}
                                                type="text"
                                                name="city"
                                                id="city"
                                                placeholder={t("FORMS.CUSTOMER_DATA.CITY")}
                                            />
                                            <ErrorMessage name="city"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="country">{t("FORMS.CUSTOMER_DATA.COUNTRY")} *</label>
                                            <Field
                                                name="country"
                                                id="country"
                                            >
                                                {({
                                                      form: {values}
                                                  }) => (
                                                    <Typeahead options={countryOptions}
                                                               id="country-autocomplete"
                                                               inputProps={{
                                                                   autoComplete: "nope",
                                                                   required: true,
                                                                   name: "country",
                                                                   className: getFormFieldValidationStyles(props.errors, props.touched, "country")
                                                               }}
                                                               paginate={false}
                                                               placeholder={t("FORMS.CUSTOMER_DATA.COUNTRY")}
                                                               emptyLabel={t("FORMS.CUSTOMER_DATA.NO_MATCHED_COUNTRIES")}
                                                               onChange={(e: CountryOptionType[]) => {
                                                                   props.setFieldValue("country", e[0]?.code);
                                                                   if (countryOptionsWithShipping.findIndex(option => option.code === e[0]?.code) == -1) {
                                                                       props.setFieldValue("differingDeliveryAddress", true)
                                                                       props.setFieldValue("forceDifferingAdress", true);
                                                                       setErrorMessage("CHECKOUT.CUSTOMER_DATA.PLEASE_SELECT_DIFFERENT_COUNTRY");
                                                                   }
                                                                   else {
                                                                       props.setFieldValue("differingDeliveryAddress", false)
                                                                       props.setFieldValue("forceDifferingAdress", false);
                                                                       setErrorMessage("");
                                                                   }
                                                               }}
                                                               onBlur={props.handleBlur}
                                                               selected={values.country ? [{
                                                                   code: values.country,
                                                                   label: t("ENUMS.COUNTRY_CODES." + values.country)
                                                               }] : []}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        {props.values.forceDifferingAdress && <div className={"small text-danger"}>{t("CHECKOUT.CUSTOMER_DATA.PLEASE_SELECT_DIFFERENT_COUNTRY")}</div>}
                                    </div>
                                    {getMailForm(props)}
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group pb-2">
                                            <label className="form-label-small-bold"
                                                   htmlFor="phoneNumber">{t("FORMS.CUSTOMER_DATA.PHONE")} *</label>
                                            <Field
                                                className={getFormFieldValidationStyles(props.errors, props.touched, "phoneNumber")}
                                                type="tel"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                placeholder={t("FORMS.CUSTOMER_DATA.PHONE")}
                                            />
                                            <ErrorMessage name="phoneNumber"
                                                          render={msg => <div
                                                              className="invalid-feedback">{msg}</div>}/>
                                        </div>
                                    </div>
                                    {getRegisterForm(props)}
                                    <div className="col-12 my-2">
                                        <label className="custom-checkbox">
                                            <Field
                                                className="me-1"
                                                type="checkbox"
                                                name="differingDeliveryAddress"
                                            />
                                            <span className="checkmark"/>
                                            {t("FORMS.CUSTOMER_DATA.DIFFERING_DELIVERY_ADDRESS")}
                                        </label>
                                    </div>
                                    {props.values.differingDeliveryAddress
                                        ? getDifferingAddress(props)
                                        : ""}

                                    <div className="col-12 mt-5">
                                        <button
                                            type="submit"
                                            className="btn btn-green w-100"
                                            disabled={Object.keys(props.errors).length > 0}
                                        >
                                            {t("CHECKOUT.NEXT")}
                                        </button>
                                </div>
                            </div>
                        </div>
                        </Form>
                        )}
                </Formik>
            </div>
        </>
    );
}

export default NewCustomer;
