import { StrapiMediaContainer, StrapiMediaListContainer } from "./StrapiMediaContainer";

export interface SharedBlockRichText {
    __component: SharedBlockType.RICH_TEXT;
    body?: string;
}

export interface SharedBlockMedia {
    __component: SharedBlockType.MEDIA;
    file?: StrapiMediaContainer;
}

export interface SharedBlockSlider {
    __component: SharedBlockType.SLIDER;
    files?: StrapiMediaListContainer;
}

export interface SharedBlockInformationBox {
    __component: SharedBlockType.INFORMATION_BOX;
    title?: string;
    content?: string;
}

export interface SharedBlockCallToAction {
    __component: SharedBlockType.CALL_TO_ACTION;
    title: string;
    content: string;
    actionLabel: string;
    actionTarget: string;
}

export interface SharedBlockSearchResult {
    __component: SharedBlockType.SEARCH_RESULT;
    nodeId?: string;
    carId?: number;
}

export interface SharedBlockContactForm {
    __component: SharedBlockType.CONTACT_FORM;
    title: string;
    formTitle: string;
    mailSubject: string;
    description?: string;
}

export type SharedBlockEntry =
    | SharedBlockRichText
    | SharedBlockMedia
    | SharedBlockSlider
    | SharedBlockInformationBox
    | SharedBlockCallToAction
    | SharedBlockSearchResult
    | SharedBlockContactForm;

export enum SharedBlockType {
    RICH_TEXT = 'shared.rich-text',
    MEDIA = 'shared.media',
    INFORMATION_BOX = 'shared.information-box',
    CALL_TO_ACTION = 'shared.call-to-action',
    SEARCH_RESULT = 'shared.search-result',
    CONTACT_FORM = 'shared.contact-form',
    SLIDER = 'shared.slider',
}
