import { inspect } from "util";
import { CartItemDTO } from "../models/cart/CartDTO";
import { RimDTO } from "../models/rims/RimSearchResultDTO";
import { DeliveryStatus, StocksColor } from "../models/shared/Enums";
import { OrderItemDTO } from "../models/order/OrderItemDTO";
import { RimItemDTO } from "../models/rims/RimDetailDTO";
import moment from 'moment';

export function getFallbackImage(item: CartItemDTO | OrderItemDTO) {
    switch (item.itemType) {
        case "RIM":
            return "/no_images/felge.svg";
        case "TYRE":
            return "/no_images/reifen.svg";
        case "TPMS":
            return "/no_images/rdks.svg"
        default:
            return "/no_images/reifen.svg";
    }
}

export function getStockColorFromQuantity(quantity: number): StocksColor {
    if (quantity > 8) {
        return StocksColor.GREEN;
    } else if (quantity > 1) {
        return StocksColor.ORANGE;
    } else {
        return StocksColor.RED;
    }
}

export function getStockColorFromDeliveryStatus(status: DeliveryStatus): StocksColor {
    switch (status) {
        case DeliveryStatus.IN_STOCK:
            return StocksColor.GREEN;
        case DeliveryStatus.VENDOR_STOCK:
            return StocksColor.ORANGE;
        case DeliveryStatus.NO_STOCK:
            return StocksColor.RED;
        default:
            return null;
    }
}

export function getReactSelectStyles(zIndex = 9999) {
    return {
        control: (styles, { isFocused }) => ({
            ...styles,
            boxShadow: "none",
            "&:hover": {
                borderColor: "var(--primary)"
            },
            borderColor: isFocused ? "var(--primary)" : "#CBCBCB"
        }),
        option: (styles, {isDisabled, isSelected, isFocused}) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                color: 'var(--dark)',
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? 'var(--primary)'
                        : isFocused
                            ? 'var(--light)'
                            : null
            };
        },
        menu: base => ({...base, zIndex})
    };
}

export function objectEquals<T>(x: T, y: T): boolean {
    if (x === y) {
        return true; // if both x and y are null or undefined and exactly the same
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
        return false; // if they are not strictly equal, they both need to be Objects
    } else if (x.constructor !== y.constructor) {
        // they must have the exact same prototype chain, the closest we can do is
        // test their constructor.
        return false;
    } else {
        for (const p in x) {
            if (!x.hasOwnProperty(p)) {
                continue; // other properties were tested using x.constructor === y.constructor
            }
            if (!y.hasOwnProperty(p)) {
                return false; // allows to compare x[ p ] and y[ p ] when set to undefined
            }
            if (x[p] === y[p]) {
                continue; // if they have the same strict value or identity then they are equal
            }
            if (typeof (x[p]) !== 'object') {
                return false; // Numbers, Strings, Functions, Booleans must be strictly equal
            }
            if (!objectEquals(x[p], y[p])) {
                return false;
            }
        }
        for (const p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                return false;
            }
        }
        return true;
    }
}

export function equalsIgnoreOrder(a: any[], b: any[]) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

export function urlParamsToObject(urlParams: URLSearchParams) {
    const entries = urlParams.entries();
    const result = {}
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}

export function cleanupFiltersForRequest(filters) {
    if (filters === undefined) {
        return;
    }
    const cleanedFilter = filters.filter((filter) => filter.selectedValues?.length > 0);
    return cleanedFilter;
}

export function findSelectedRim(rims: RimDTO[], selected: RimItemDTO) {
    for (let rim of rims) {
        if (rim.rimId === selected.rimId) {
            return rim
        } else {
            let rimIds = rim.items.map(i => i.rimId);
            if (rimIds.includes(selected.rimId)) {
                return rim;
            }
        }
    }
    return null;
}

export const flattenObj = (ob) => {
    if (ob) {
        let result = {};
        for (const i in ob) {
            if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
                const temp = flattenObj(ob[i]);
                for (const j in temp) {
                    result[i + '.' + j] = temp[j] + '';
                }
            } else {
                result[i] = ob[i] + '';
            }
        }
        return result;
    } else {
        return undefined;
    }
};

export function classList(classes) {
    return Object
        .entries(classes)
        .filter(entry => entry[1])
        .map(entry => entry[0])
        .join(' ');
}

export function showWinterImage() {
    const now = moment();
    // Uffpasse, beim ändern, Monate beginnen bei 0!
    const winterImageStart = moment().month(10).date(1);
    const winterImageEnd = moment().month(1).date(15);
    return now.isBetween(winterImageStart, winterImageEnd)
}

export function isSummerSeason() {
    const now = moment();
    // Uffpasse, beim ändern, Monate beginnen bei 0!
    const summerSeasonStart = moment().month(1).date(15);
    const summerSeasonEnd = moment().month(7).date(14);
    return now.isBetween(summerSeasonStart, summerSeasonEnd)
}

export function isTouchDevice() {
    return (navigator.maxTouchPoints > 0);
}
