import { getIn } from "formik";

export function convertEmptyStringsToNull(obj: any) {
    for (const item in obj) {
            if (obj[item] === "") {
                obj[item] = null;
            }
    }
  return obj;
}

export function getFormFieldValidationStyles(errors, touched, fieldName) {
    if (getIn(touched, fieldName) && getIn(errors, fieldName)) {
        return "form-control is-invalid";
    } else {
        return "form-control";
    }
}

export function transformEmptyToNull<T>(value: T, originalValue: T) {
    if (!value) {
        return undefined;
    }
    return originalValue;
}
