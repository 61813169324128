import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { StringParam, useQueryParam } from "use-query-params";
import Input from "../../../../components/Input/Input";
import SvgIcon, { SVG_ICONS } from "../../../../components/SvgIcon/SvgIcon";
import { UrlSearchParamsHelper } from "../../../../helper/UrlSearchParamsHelper";
import { RimTyreAssignmentDTO } from "../../../../models/rims/RimTyreAssignmentDTO";
import { RimTyreAssignmentRequest } from "../../../../models/rims/RimTyreAssignmentRequest";
import { TyreSearchParamDTO } from "../../../../models/tyres/TyreSearchRequestDTO";
import { getRimTyreAssignment } from "../../../../redux-store/api/RimApi";
import { getTyreSizes } from "../../../../redux-store/api/TyreApi";
import { TyreSizeSelect } from "./TyreSizeSelect";

type Props = {
    searchParams: TyreSearchParamDTO;
    searchParamsCallback: (searchParams: TyreSearchParamDTO) => void;
    isCompleteWheelSearch: boolean;
};

function TyreSearchParams({ searchParams, searchParamsCallback, isCompleteWheelSearch }: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUpdatingSizes, setIsUpdatingSizes] = useState<boolean>(false);
    const [widths, setWidths] = useState<string[]>([]);
    const [heights, setHeights] = useState<string[]>([]);
    const [diameters, setDiameters] = useState<string[]>([]);
    const [search, setSearch] = useState<TyreSearchParamDTO>(searchParams);
    const [rimTyreAssignments, setRimTyreAssignments] = useState<RimTyreAssignmentDTO[]>([]);
    const { carId, rimFrontId, rimRearId } = useParams<{ carId: string; rimFrontId: string; rimRearId: string }>();
    const [certificateId, setCertificateId] = useQueryParam("certificateId", StringParam);
    const [certificateBlockId, setCertificateBlockId] = useQueryParam("certificateBlockId", StringParam);
    const [selectedTyreSize, setSelectedTyreSize] = useState<RimTyreAssignmentDTO>();
    const [t] = useTranslation();

    useEffect(() => {
        if (isCompleteWheelSearch) {
            loadRimTyreAssignments();
        }
    }, [search, isCompleteWheelSearch]);

    useEffect(() => {
        if (!isCompleteWheelSearch) {
            loadSizes();
        }
    }, [isCompleteWheelSearch]);

    function onSearch() {
        UrlSearchParamsHelper.pushSearchToHistory(location, navigate, search);
        searchParamsCallback(search);
    }

    function loadRimTyreAssignments() {
        setIsUpdatingSizes(true);
        const request: RimTyreAssignmentRequest = {
            carId,
            certificateBlockId: +certificateBlockId,
            certificateId: +certificateId,
            rimFrontId,
            rimRearId: rimRearId || rimFrontId,
        };
        getRimTyreAssignment(request)
            .then((res) => {
                setRimTyreAssignments(res);
                let size;
                if (search.matchcodeTerm) {
                    size = res.find((s) => s.front.matchcode === search.matchcodeTerm);
                } else if (search.frontMatchcode && search.rearMatchcode) {
                    size = res.find(
                        (s) => s.front.matchcode === search.frontMatchcode && s.rear.matchcode === search.rearMatchcode,
                    );
                }
                setSelectedTyreSize(size);
            })
            .finally(() => setIsUpdatingSizes(false));
    }

    function onChangeTyreSize(e: string[], size) {
        switch (size) {
            case "WIDTH":
                setSearch((search) => ({
                    ...search,
                    width: e?.length > 0 ? e[0] : null,
                }));
                break;
            case "HEIGHT":
                setSearch((search) => ({
                    ...search,
                    height: e?.length > 0 ? e[0] : null,
                }));
                break;
            case "DIAMETER":
                setSearch((search) => ({
                    ...search,
                    diameter: e?.length > 0 ? e[0] : null,
                }));
                break;
            default:
                break;
        }
    }

    function loadSizes() {
        setIsUpdatingSizes(true);
        getTyreSizes()
            .then((res) => {
                setHeights(res.heightList);
                setDiameters(res.diameterList);
                setWidths(res.widthList);
            })
            .finally(() => setIsUpdatingSizes(false));
    }

    function onChangeSeason(e) {
        e.persist();
        let season = e.currentTarget.id;
        let newSeasons = [].concat(search.seasons);
        if (newSeasons.includes(season)) {
            newSeasons.splice(search.seasons.indexOf(season), 1);
        } else {
            newSeasons.push(season);
        }
        setSearch((search) => ({
            ...search,
            seasons: newSeasons,
        }));
    }

    function onChangeTyreSizeCompleteWheel(size: RimTyreAssignmentDTO) {
        if (size.front.matchcode === size.rear.matchcode) {
            setSearch((search) => ({
                ...search,
                matchcodeTerm: size.front.matchcode,
                frontMatchcode: null,
                rearMatchcode: null,
            }));
        } else {
            setSearch((search) => ({
                ...search,
                frontMatchcode: size.front.matchcode,
                rearMatchcode: size.rear.matchcode,
                matchcodeTerm: null,
            }));
        }
        setSelectedTyreSize(size);
    }

    return (
        <>
            {!isUpdatingSizes && (
                <div className="tyre-search-params">
                    <FormLabel>{t("TYRES.SEARCH.SEASON")}</FormLabel>
                    <div className="radio-button-wrapper">
                        <input
                            type="checkbox"
                            className="radio-button"
                            checked={search.seasons?.includes("SUMMER")}
                            onChange={(e) => onChangeSeason(e)}
                            name="season"
                            id="SUMMER"
                        />
                        <label className="radio-button-label me-1" htmlFor="SUMMER" data-tooltip-id="tooltip-summer">
                            <SvgIcon
                                icon={SVG_ICONS.SUN}
                                color={search.seasons?.includes("SUMMER") ? "#ffffff" : "#e9a200"}
                                size={"1.5rem"}
                            />
                            <ReactTooltip id="tooltip-summer">
                                <span>{t("TYRES.SEARCH.SEASONS.SUMMER")}</span>
                            </ReactTooltip>
                        </label>
                        <input
                            type="checkbox"
                            className="radio-button"
                            checked={search.seasons?.includes("WINTER")}
                            onChange={(e) => onChangeSeason(e)}
                            name="season"
                            id="WINTER"
                        />
                        <label className="radio-button-label mx-1" htmlFor="WINTER" data-tooltip-id="tooltip-winter">
                            <SvgIcon
                                icon={SVG_ICONS.SNOWFLAKE}
                                color={search.seasons?.includes("WINTER") ? "#ffffff" : "#e9a200"}
                                size={"1.5rem"}
                            />
                            <ReactTooltip id="tooltip-winter">
                                <span>{t("TYRES.SEARCH.SEASONS.WINTER")}</span>
                            </ReactTooltip>
                        </label>
                        <input
                            type="checkbox"
                            className="radio-button"
                            checked={search.seasons?.includes("ALL_SEASON")}
                            onChange={(e) => onChangeSeason(e)}
                            name="season"
                            id="ALL_SEASON"
                        />
                        <label
                            className="radio-button-label ms-1"
                            htmlFor="ALL_SEASON"
                            data-tooltip-id="tooltip-all_season"
                        >
                            <SvgIcon
                                icon={SVG_ICONS.ALL_SEASON}
                                color={search.seasons?.includes("ALL_SEASON") ? "#ffffff" : "#e9a200"}
                                size={"1.5rem"}
                            />
                            <ReactTooltip id="tooltip-all_season">
                                <span>{t("TYRES.SEARCH.SEASONS.ALL_SEASON")}</span>
                            </ReactTooltip>
                        </label>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12">
                            <FormLabel>{t("TYRES.SEARCH.TYRE_SIZE")}</FormLabel>
                        </div>
                        {isCompleteWheelSearch && selectedTyreSize && rimTyreAssignments?.length > 0 && (
                            <div className="col-12">
                                <TyreSizeSelect
                                    name="matchcode"
                                    options={rimTyreAssignments}
                                    value={selectedTyreSize}
                                    onChange={(e) => onChangeTyreSizeCompleteWheel(e.value)}
                                    required={true}
                                />
                                {selectedTyreSize.abe && (
                                    <div className="tyre-info-green">
                                        <i className="fa fa-check me-2" />
                                        {t("RIMS.RIM_TYRE_SIZE_DIALOG.ABE")}
                                    </div>
                                )}
                                {selectedTyreSize.snowChain && (
                                    <div className="tyre-info-green">
                                        <i className="fa fa-check me-2" />
                                        {t("RIMS.RIM_TYRE_SIZE_DIALOG.SNOW_CHAIN")}
                                    </div>
                                )}
                            </div>
                        )}
                        {!isCompleteWheelSearch && (
                            <>
                                <div className="col-4 col-lg-12 col-xl-4 pe-xl-1">
                                    <Input
                                        type="SINGLE_SELECT_DROPDOWN"
                                        labelClass="d-none d-lg-block d-xl-none"
                                        label={t("TYRES.SEARCH.WIDTH")}
                                        name="width"
                                        options={[null].concat(widths)}
                                        value={search.width}
                                        hideClear={true}
                                        onChange={(e) => onChangeTyreSize(e, "WIDTH")}
                                    />
                                </div>
                                <div className="col-4 col-lg-12 col-xl-4 px-xl-1">
                                    <Input
                                        type="SINGLE_SELECT_DROPDOWN"
                                        labelClass="d-none d-lg-block d-xl-none"
                                        label={t("TYRES.SEARCH.SECTION")}
                                        name="height"
                                        options={[null].concat(heights)}
                                        hideClear={true}
                                        value={search.height}
                                        onChange={(e) => onChangeTyreSize(e, "HEIGHT")}
                                    />
                                </div>
                                <div className="col-4 col-lg-12 col-xl-4 ps-xl-1">
                                    <Input
                                        type="SINGLE_SELECT_DROPDOWN"
                                        labelClass="d-none d-lg-block d-xl-none"
                                        label={t("TYRES.SEARCH.DIAMETER")}
                                        name="diameter"
                                        options={[null].concat(diameters)}
                                        hideClear={true}
                                        value={search.diameter}
                                        onChange={(e) => onChangeTyreSize(e, "DIAMETER")}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <button className="btn btn-primary mt-2 w-100" onClick={() => onSearch()}>
                            {t("TYRES.SEARCH.APPLY")}
                        </button>
                    </div>
                </div>
            )}
            {isUpdatingSizes && <div className="tyre-search-params loading" />}
        </>
    );
}

export default TyreSearchParams;
